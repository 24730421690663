<div *ngIf="showAbmeldungMessage" class="inner-wrap formbackground">
  <div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
      <h3>Newsletter abgemeldet</h3>
      <div class="divider-wrap" data-alignment="default">
        <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%"
          data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
      </div>
      <p></p>
    </div>
  </div>
  <p>Newsletter wurde erfolgreich abgemeldet!.</p>
  <!-- <dxi-item> -->
    <div class="nectar-cta " data-color="default" data-using-bg="false" data-display="block"
      data-style="underline" data-alignment="left" data-text-color="custom">
      <h5 style="color: #000000; padding-bottom:10px;"> <span class="text"> </span>
        <span class="link_wrap">
        <a [routerLink]="['/login']" class="link_text" style="border-color: #000000;" >zur Anmeldeseite</a>
      </span>
      </h5>
    </div>
  <!-- </dxi-item> -->
</div>
<div *ngIf="showErrorMessage" class="inner-wrap formbackground">
  <div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
      <h3>Newsletter</h3>
      <div class="divider-wrap" data-alignment="default">
        <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%"
          data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
      </div>
      <p></p>
    </div>
  </div>
  <p>Bei der Abmeldung ist ein Fehler aufgetreten:</p>
  <P>{{errorMessage}}</P>
  <!-- <dxi-item> -->
    <div class="nectar-cta " data-color="default" data-using-bg="false" data-display="block"
      data-style="underline" data-alignment="left" data-text-color="custom">
      <h5 style="color: #000000; padding-bottom:10px;"> <span class="text"> </span>
        <span class="link_wrap">
        <a [routerLink]="['/login']" class="link_text" style="border-color: #000000;" >zur Anmeldeseite</a>
      </span>
      </h5>
    </div>
  <!-- </dxi-item> -->
</div>
