// import { GlobaleDatenService } from 'app/services/globaledaten.service';
// import { enuFilterBergwachten } from './../globals/enumerationen';
import { Injectable } from '@angular/core';
import * as funktionen from '../globals/functions';
import * as moment from 'moment';


export class Einstellungen {
  sucheStorageKey: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  public _sucheSekId: string = null;
  // sektionFilterAlle: enuSektionFilterAlle;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  protected _syncSekId = true;

  constructor(public einstellungen: EinstellungenService = null) {
    // this.sektionFilterAlle = enuSektionFilterAlle.AlleErlaubtAusgewaehlt;
    this.sucheStorageKey = ( this as any).constructor.name;

    // this.validateBergwacht = this.validateBergwacht.bind(this);
  }

  get syncSekId(): boolean {
    return this._syncSekId;
  }

  get sucheSekId(): string {
    return this._sucheSekId;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  set sucheSekId(sek_id) {
    if (this._sucheSekId !== sek_id) {
      this._sucheSekId = sek_id;
      if (this.syncSekId) {
        this.einstellungen.SetDefaultSektion(sek_id);
        localStorage.setItem('sucheSekId', JSON.stringify(sek_id));
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  SetSyncSekId(sek_id: string) {
    if (this.syncSekId) { this.sucheSekId = sek_id; }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  SetDefault(resetSek_id: boolean) {
    if (resetSek_id) {
      this.sucheSekId = null;
    }
  }


  GetParam(): string {
    let params = '';
    if (this.sucheSekId && this.sucheSekId.length > 0) {
      if (params.length > 0) { params = params + '&'; }
      params = params + 'sek_id=' + this.sucheSekId;
    }

    return params;
  }



  SetGlobaleDaten() {

  }
  // GetFilterBergwachtStandard(): enuFilterBergwachten {
  //   return enuFilterBergwachten.Bergwacht;
  // }
}

export class EinstellungenItem extends Einstellungen {
  id: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  SetDefault(resetSek_id: boolean) {
    super.SetDefault(resetSek_id);
    this.id = '';
  }

  GetParam(): string {
    let params = super.GetParam();
    if (!funktionen.isEmptyString(this.id)) {
      if (params.length > 0) { params = params + '&'; }
      params = params + 'id=' + this.id;
    }

    return params;
  }
}

export class EinstellungenKalender extends Einstellungen {
  sucheTyp: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  SetDefault(resetSek_id: boolean) {
    super.SetDefault(resetSek_id);
    this.sucheTyp = 0;
  }

  GetParam(): string {
    let params = super.GetParam();
    if (this.sucheTyp !== 0) {
      if (params.length > 0) { params = params + '&'; }
      params = params + 'typ=' + this.sucheTyp;
    }

    return params;
  }
}

@Injectable()
export class EinstellungenService {
  private isInSetDefaultSektion = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _list: Einstellungen[] = new Array();

  public kalender: EinstellungenKalender;
  constructor(
    // public gdService: GlobaleDatenService,
  ) {
    this.kalender = new EinstellungenKalender(this);
    this._list.push(this.kalender);
    this.SetDefault();
  }

  SetDefault() {
    this._list.forEach(einstellung => {
        einstellung.SetDefault(true);
      });
  }

  SetGlobaleDaten() {
    this._list.forEach(einstellung => {
      // if (einstellung.syncSekId) einstellung.sucheSekId = sek_id;
      einstellung.SetGlobaleDaten();
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public SetDefaultSektion(sek_id: string) {
    if (this.isInSetDefaultSektion) { return; }

    try {
      this.isInSetDefaultSektion = true;

      this._list.forEach(einstellung => {
        // if (einstellung.syncSekId) einstellung.sucheSekId = sek_id;
        einstellung.SetSyncSekId(sek_id);
      });
    } finally {
      this.isInSetDefaultSektion = false;
    }
  }


}
