import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalVariable } from '../globals/globals';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-version-version',
  templateUrl: './version.component.html',
  styles: []
})
export class VersionComponent implements OnInit {
  daten: any;

  constructor(
    private authService: AuthService,
  ) {

    this.daten = new Array();
    this.daten['version'] = GlobalVariable['version'];
    this.daten['versionsdatum'] = GlobalVariable['versionsdatum'];
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    // super.ngOnInit();
    this.authService.getUrl('/portal/reloadcache').then((data) => {
    }).catch((error) => {
    });
  }


}