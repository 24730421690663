import { GlobaleDatenService } from '../services/globaledaten.serice';
import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { enuKunde } from '../app-config.model';
import { AppConfig } from '../app.config';

@Component({
  selector: './app-sprachauswahl',
  templateUrl: './sprachauswahl.component.html',
  styleUrls: ['./sprachauswahl.component.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SprachauswahlPage {
  sprachen: Array<sprache>;
  selectedLanguage: string;

  @Output() onReloadData = new EventEmitter<boolean>();
  loadingVisible = false;

  enuKunde = enuKunde;

  sprachauswahl = AppConfig.kunde_settings.sprachauswahl;

  constructor(
    public route: ActivatedRoute,
    public gdService: GlobaleDatenService,
    public translate: TranslateService,
  ) {
    this.onClickSprache = this.onClickSprache.bind(this);
    this.onClickSprache2 = this.onClickSprache2.bind(this);
    this.sprachen = this.getLanguages();
    this.selectedLanguage = localStorage.getItem('locale');
  }

  async onClickSprache(langToSet: string) {
    // this.loadingVisible = true;
    setTimeout(() => {
      this.onClickSprache2(langToSet);
    }, 50);
  }
    async onClickSprache2(langToSet: string) {
    let sp = localStorage.getItem('locale');
    if(sp !== langToSet) {
      localStorage.setItem('locale', langToSet);
      this.translate.resetLang(langToSet);
      await this.translate.use(langToSet).toPromise().finally(() => {
        // this.loadingVisible = false;
      });
  
      switch(langToSet) {
        case 'de':
          var de = document.getElementById("de");
          if(de) de.classList.remove('other-button-class');
          var it = document.getElementById("it");
          if(it) it.classList.add('other-button-class');
          var en = document.getElementById("en");
          if(en) en.classList.add('other-button-class');
          var ld = document.getElementById("ld");
          if(ld) ld.classList.add('other-button-class');
          break;
        case 'it':
          var de = document.getElementById("de");
          if(de) de.classList.add('other-button-class');
          var it = document.getElementById("it");
          if(it) it.classList.remove('other-button-class');
          var en = document.getElementById("en");
          if(en) en.classList.add('other-button-class');
          var ld = document.getElementById("ld");
          if(ld) ld.classList.add('other-button-class');
          break;
        case 'en':
          var de = document.getElementById("de");
          if(de) de.classList.add('other-button-class');
          var it = document.getElementById("it");
          if(it) it.classList.add('other-button-class');
          var en = document.getElementById("en");
          if(en) en.classList.remove('other-button-class');
          var ld = document.getElementById("ld");
          if(ld) ld.classList.add('other-button-class');
          break;
      case 'ld':
        var de = document.getElementById("de");
        if(de) de.classList.add('other-button-class');
        var it = document.getElementById("it");
        if(it) it.classList.add('other-button-class');
        var en = document.getElementById("en");
        if(en) en.classList.add('other-button-class');
        var ld = document.getElementById("ld");
        if(ld) ld.classList.remove('other-button-class');
        break;
      }
       this.reloadData();
    }
    this.loadingVisible = false;
  }

  reloadData(){
    this.onReloadData.emit(true);
  }

  getLanguages(){
    let sprachen = new Array();
    sprachen.push(new sprache('Deutsch', 'de'))
    sprachen.push(new sprache('Italiano', 'it'))
    sprachen.push(new sprache('English', 'en'))
    sprachen.push(new sprache('Ladinisch ', 'ld'))
    return sprachen;
  }

  onLanguageSelected(event){
    this.onClickSprache(this.selectedLanguage);
    switch(this.selectedLanguage){
      case 'de':
        this.gdService.onInitializedDe(event);
        break;
      case 'it':
        this.gdService.onInitializedIt(event);
        break;
      case 'en':
        this.gdService.onInitializedEn(event);
        break;
      case 'ld':
        this.gdService.onInitializedLd(event);
        break;
    }
    this.reloadData();
  }

  getPadding() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '0px !important';
      case enuKunde.KFS: 
        return '10px !important';
      case enuKunde.Standard: 
      case enuKunde.Template: 
        return '20px !important';
    }

  }

}


export class sprache {
  text: string;
  value: string;
  constructor(text: string, value: string) { 
    this.text = text;
    this.value = value;
  }
}


