
import {TranslateLoader} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable , forkJoin} from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class CustomLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
  ) {
  }
  getTranslation(langCountry: string): Observable<any> {

    return this.http.get('/assets/i18n/' + langCountry + '.json');

  }
}
