import { AuthService } from './../services/auth.service';
import { EventEmitter, Injectable } from '@angular/core';
// import 'rxjs/Rx';
import { Dictionary } from 'typescript-collections';
import { enuSektionstyp } from './../globals/enumerationen';
// import { JCB } from './datatable';
import * as dt from './datatable';
import { Mitglied } from '../mitglied/mitglied.model';
import * as funktionen from '../globals/functions';
import * as moment from 'moment';
import { AppConfig } from './../app.config';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { enuEingabeMitgliedsnummer, enuKunde } from '../app-config.model';

export class FilterItem {
  id: string;
  name: string;
}

export class FilterItemGroup {
  id: string;
  name: string;
  group: string;
}

export class FilterItemSektion extends FilterItem {
  typ: enuSektionstyp;
  sektionsnr: number;
  mindatum: Date;
  status: boolean;
  onlineanmeldung: boolean;
}

export class FilterItemVereinsdaten extends FilterItem {
  nation: string;
  ort: string;
  plz: string;
  strasse: string;
  hausnr: string;
  telefon: string;
  email: string;
  farbe1: string;
  farbe2: string;
  farbe3: string;
  mitgliedsnummergemeinsam: boolean;
  climbofficelink: string; // nur bei AVS

  constructor(data:any) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.nation = data.nation;
    this.ort = data.ort;
    this.plz = data.plz;
    this.strasse = data.strasse;
    this.hausnr = data.hausnr;
    this.telefon = data.telefon;
    this.email = data.email;
    this.farbe1 = data.farbe1;
    this.farbe2 = data.farbe2;
    this.farbe3 = data.farbe3;
    this.mitgliedsnummergemeinsam = data.mitgliedsnummergemeinsam;
    this.climbofficelink = data.climbofficelink;
    
  }
}

@Injectable()
export class GlobaleDatenService {
  sektionenFilter: Array<FilterItemSektion>;
  sektionenFilterAlle: Array<FilterItemSektion>;
  sektionenFilterAktiv: Array<FilterItemSektion>;
  sektionenFilterAktivAlle: Array<FilterItemSektion>;
  private isLoadedSektionenFilter: boolean;
  public sektionenDictionary: Dictionary<string, string>;
  public sektionenArray: Array<FilterItemSektion>;
  public vereinsdaten: FilterItemVereinsdaten;
  private sektionenArrayAktiv: Array<FilterItemSektion>;
  private isLoadedSektionen: boolean;
  private isLoadedVerein: boolean;
  private nationen: Array<any>;
  private geburtsorte: Array<any>;
  private orte: Array<any>;
  private kategorien: Array<any>;
  private sprachen: Array<any>;
  private mitgliederdokumenttypen: Array<any>;
  private anreden: Array<string>;
  private titel: Array<string>;
  public istMitglied: boolean;
  public mitglied_id: string;
  public mitglied: Mitglied;
  public user: string;
  public password: string;
  public benutzer_id: string;

  public anrede: string;
  public vorname: string;
  public firmenname: string;
  public nachname: string;
  public newsletter: boolean;

  private isLoadedDaten = false;
  private sektionen: dt.DataTable;
  public rechte: Array<boolean>;

  it_id = '';
  it_name = 'Italien';
  sek_avs_id = 'af154fb4-eb24-4c4c-9a18-903c097ae632';

  meldungServerGesperrt = 'Wir ersuchen um dein Verständnis! Wegen Wartungsarbeiten ist dieser Webseite-Service kurzzeitig nicht verfügbar.';
  dauerMeldungServerGesperrt = 4000;
  isMobileDevice: boolean;

  eingabeMitgliedsnummer = enuEingabeMitgliedsnummer.MitSektion;
  mitnewsletter = AppConfig.kunde_settings.newsletter;

  officeFarben: Array<any> = new Array;

  vereinsdatenAktualisiert = new EventEmitter<boolean>();

  constructor(private authservice: AuthService,
    public translate: TranslateService,
    private router: Router,
    ) {
    this.mitglied = new Mitglied(this.getNewsletter());
    this.Reset();
    this.getSektionenArray().then(
      (data) => {
          console.log('=', this.sektionenArray);
      }
    );

    this.isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile/i.test(navigator.userAgent);

    window.onstorage = (e) => {
      if (e.key && e.key.includes('access_token') && e.newValue === null) {
        window.location.replace(window.location.origin + '/login?returnUrl=' + window.location.origin + window.location.pathname);
      }
      if (e.key && e.key.includes('currentUser') && e.newValue !== null && window.location.pathname.includes('/login') && !this.authservice.CheckExpDate()) {
        this.router.navigate(['/profil']);
        this.authservice.currentUser = e.newValue;
      }
    };
  }

  Reset() {
    this.sektionenDictionary = new Dictionary<string, string>();
    this.sektionenArray = new Array();
    this.sektionenArrayAktiv = new Array();
    this.isLoadedSektionen = false;
    this.sektionenFilter = new Array();
    this.sektionenFilterAktiv = new Array();
    this.isLoadedSektionenFilter = false;
    this.nationen = new Array();
    this.anreden = new Array();
    this.titel = new Array();
    this.geburtsorte = new Array();
  }

  get IsLoadedDaten(): boolean {
    return this.isLoadedDaten;
  }

  getVereinsdaten(): Promise<FilterItemVereinsdaten> {
    return new Promise((resolve, reject) => {
      if (this.isLoadedVerein && this.vereinsdaten) {
          this.setColors();
          resolve(this.vereinsdaten);
        } else {
          // this.vereinsdaten = new FilterItemVereinsdaten();
          let data = JSON.parse(localStorage.getItem('vereinsdaten'));
          this.setVereinsdaten(data);
          this.authservice.getUrl('/portal/vereinsdaten')
          .then((data) => {
            this.setVereinsdaten(data);

            localStorage.setItem('vereinsdaten', JSON.stringify(this.vereinsdaten));
            this.vereinsdatenAktualisiert.emit(true);

            resolve(this.vereinsdaten);
          });
        }
    });
  }

  setVereinsdaten(data) {
    if(data) {
      this.vereinsdaten = new FilterItemVereinsdaten(data);
      this.setColors();
      if(this.vereinsdaten.mitgliedsnummergemeinsam) {
        this.eingabeMitgliedsnummer = enuEingabeMitgliedsnummer.OhneSektion;
      } else {
        this.eingabeMitgliedsnummer = enuEingabeMitgliedsnummer.MitSektion;
      }
    }
  }

  SetDaten(res) {
    // console.log('SetDaten', res);
    this.sektionen = new dt.DataTable(res['sektionen']);
    this.rechte = res['rechte'];
    this.isLoadedDaten = true;
    console.log('SetDaten sektionen', this.sektionen);
    // console.log('SetDaten rechte', this.rechte);
  }

  filterList(sektionen: Array<FilterItemSektion>, sektionstyp: number): Array<FilterItemSektion> {
    const sf = new Array();
    for ( const it of sektionen) {
      if (it.id === '' || (sektionstyp === 0 || it.typ === sektionstyp)) {
        sf.push(it);
      }
    }
    return sf;
  }

  getSektionenArray(sektionstyp: number = 0, aktiv: boolean = false): Promise<Array<FilterItemSektion>> {
    return new Promise((resolve, reject) => {
      if (this.isLoadedSektionen && this.sektionenArray.length > 0) {
        if (sektionstyp === 0) {
          resolve(this.sektionenArray);
        } else {
          resolve(this.filterList(this.sektionenArray, sektionstyp));
        }
      } else {
        this.authservice.getUrl('/portal/organisationen')
        .then((data) => {
          this.sektionenDictionary.clear();
          this.sektionenArray.splice(0);
          this.sektionenArrayAktiv.splice(0);

          // eslint-disable-next-line guard-for-in
          for (const ind in data) {
          //   // console.log(JSON.stringify(ind) + ' - ' + JSON.stringify(data[ind]));
          //   ent.push( data[ind]);
            this.sektionenDictionary.setValue(data[ind]['id'], data[ind]['name']);
            this.sektionenArray.push( data[ind]);
            if (!data[ind]['archiviertam']) { this.sektionenArrayAktiv.push( data[ind]); }
          }

          if (sektionstyp === 0) {
            if (aktiv) {
              resolve(this.filterList(this.sektionenArrayAktiv, sektionstyp));
            } else {
              resolve(this.sektionenArray);
            }
          } else {
            if (aktiv) {
              resolve(this.filterList(this.sektionenArrayAktiv, sektionstyp));
            } else {
              resolve(this.filterList(this.sektionenArray, sektionstyp));
            }
          }
        })
        .catch((error) => {
          console.log('Fehler ' + JSON.stringify(error));
          reject(error);
        });
      }
    });
  }

  getSektionenDictionary(): Promise<Dictionary<string, string>> {
    return new Promise((resolve, reject) => {
      if (this.isLoadedSektionen && this.sektionenDictionary.size() > 0) {
        resolve(this.sektionenDictionary);
      } else {
        this.authservice.getUrl('/portal/organisationen')
        .then((data) => {
          this.sektionenDictionary.clear();
          this.sektionenArray.splice(0);
          // eslint-disable-next-line guard-for-in
          for (const ind in data) {
          //   // console.log(JSON.stringify(ind) + ' - ' + JSON.stringify(data[ind]));
          //   ent.push( data[ind]);
            this.sektionenDictionary.setValue(data[ind]['id'], data[ind]['name']);
            this.sektionenArray.push( data[ind]);
          }

          resolve(this.sektionenDictionary);
        })
        .catch((error) => {
          console.log('Fehler ' + JSON.stringify(error));
          reject(error);
        });
      }
    });

  }

  SektionRow(id: string): any {
    const rows = this.sektionen.search('id', id);
    if (rows.length > 0) {
      return rows[0];
    }
    return null;
  }

  Sektion(id: string): string {
    return this.sektionenDictionary.getValue(id);
  }

  getNationen(): Promise<Array<FilterItem>> {
    return new Promise((resolve, reject) => {
      if (this.nationen.length > 0) {
        resolve(this.nationen);
      } else {
        this.authservice.getUrl('/portal/nationen')
        .then((data) => {
          if (this.nationen && this.nationen.length > 0) {
            this.nationen.splice(0, this.nationen.length);
          } else {
            this.nationen = new Array();
          }
          let arr = new Array();
          for  (const org of data as Iterable<any>) {
            arr.push(org);
            if(org.name === this.it_name) {
              this.it_id = org.id;
            }
          }
          arr = arr.sort((a, b) => {
            let a1: string;
            let b1: string;
            a1 = a.name;
            b1 = b.name;
            return a1.localeCompare(b1);
          });
          for  (const org of arr) {
            this.nationen.push(org);
          }
          resolve(this.nationen);
        })
        .catch((error) => {
          console.log('Fehler ' + JSON.stringify(error));
          reject(error);
        });
      }
    });
  }

  getAnreden(): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      if (this.anreden.length > 0) {
        resolve(this.anreden);
      } else {
        this.authservice.getUrl('/daten/anreden')
        .then((data) => {
          let ent: Array<string>;
          ent = new Array();
          // eslint-disable-next-line guard-for-in
          for (const ind in data) {
            ent.push( data[ind]['name']);
          }
          this.anreden = ent;

          resolve(this.anreden);
        })
        .catch((error) => {
          console.log('Fehler ' + JSON.stringify(error));
          reject(error);
        });
      }
    });
  }

  getTitel(): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      if (this.titel.length > 0) {
        resolve(this.titel);
      } else {
        this.authservice.getUrl('/daten/titel')
        .then((data) => {
          let ent: Array<string>;
          ent = new Array();
          // eslint-disable-next-line guard-for-in
          for (const ind in data) {
            ent.push( data[ind]['name']);
          }
          this.titel = ent;

          resolve(this.titel);
        })
        .catch((error) => {
          console.log('Fehler ' + JSON.stringify(error));
          reject(error);
        });
      }
    });
  }

  getOrte(id): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      this.authservice.getUrl('/portal/orte/' + id)
      .then((data) => {
        if (this.orte && this.orte.length > 0) {
          this.orte.splice(0, this.orte.length);
        } else {
          this.orte = new Array();
        }
        let arr = new Array();
        for  (const org of data as Iterable<any>) {
          arr.push(org);
        }
        arr = arr.sort((a, b) => {
          let a1: string;
          let b1: string;
          a1 = a.name;
          b1 = b.name;
          return a1.localeCompare(b1);
        });
        for  (const org of arr) {
          this.orte.push(org);
        }

        resolve(this.orte);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        reject(error);
      });
    });
  }

  getGeburtsorte(): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      this.authservice.getUrl('/portal/geburtsorte')
      .then((data) => {
        if (this.geburtsorte && this.geburtsorte.length > 0) {
          this.geburtsorte.splice(0, this.geburtsorte.length);
        } else {
          this.geburtsorte = new Array();
        }
        let arr = new Array();
        for  (const org of data as Iterable<any>) {
          arr.push(org);
        }
        arr = arr.sort((a, b) => {
          let a1: string;
          let b1: string;
          a1 = a.name;
          b1 = b.name;
          return a1.localeCompare(b1);
        });
        for  (const org of arr) {
          this.geburtsorte.push(org);
        }

        resolve(this.geburtsorte);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        reject(error);
      });
    });
  }

  getKategorien(): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      this.authservice.getUrl('/portal/kategorien')
      .then((data) => {
        if (data) {
          if (this.kategorien && this.kategorien.length > 0) {
            this.kategorien.splice(0, this.kategorien.length);
          } else {
            this.kategorien = new Array();
          }
          let arr = new Array();
          for  (const org of data as Iterable<any>) {
            arr.push(org);
          }
          arr = arr.sort((a, b) => {
            let a1: string;
            let b1: string;
            a1 = a.name;
            b1 = b.name;
            return a1.localeCompare(b1);
          });
          for  (const org of arr) {
            this.kategorien.push(org);
          }
        }

        resolve(this.kategorien);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        reject(error);
      });
    });
  }

  getSprachen(): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      this.authservice.getUrl('/portal/sprachen')
      .then((data) => {
        if (data) {
          if (this.sprachen && this.sprachen.length > 0) {
            this.sprachen.splice(0, this.sprachen.length);
          } else {
            this.sprachen = new Array();
          }
          let arr = new Array();
          for  (const org of data as Iterable<any>) {
            arr.push(org);
          }
          arr = arr.sort((a, b) => {
            let a1: string;
            let b1: string;
            a1 = a.name;
            b1 = b.name;
            return a1.localeCompare(b1);
          });
          for  (const org of arr) {
            this.sprachen.push(org);
          }
        }

        resolve(this.sprachen);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        reject(error);
      });
    });
  }
  getMitgliederDokumentTypen(): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      this.authservice.getUrl('/portal/mitgliederdokumenttypen')
      .then((data) => {
        if (data) {
          if (this.mitgliederdokumenttypen && this.mitgliederdokumenttypen.length > 0) {
            this.mitgliederdokumenttypen.splice(0, this.mitgliederdokumenttypen.length);
          } else {
            this.mitgliederdokumenttypen = new Array();
          }
          let arr = new Array();
          for  (const org of data as Iterable<any>) {
            arr.push(org);
          }
          arr = arr.sort((a, b) => {
            let a1: string;
            let b1: string;
            a1 = a.name;
            b1 = b.name;
            return a1.localeCompare(b1);
          });
          for  (const org of arr) {
            if(org.onlineausgabe) {
              this.mitgliederdokumenttypen.push(org);
            }
          }
        }

        resolve(this.mitgliederdokumenttypen);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        reject(error);
      });
    });
  }

  getStrassen(plz: string, ort): Promise<Array<string>> {
    // console.log('GlobaleDatenService getSektionenFilter');
    return new Promise((resolve, reject) => {
      // console.log('GlobaleDatenService getSektionenFilter ' + this.isLoadedSektionenFilter + " - " + this.sektionenFilter.length);
      this.authservice.put('/daten/strassen', { plz, ort})
      .then((data) => {
        let ent: Array<string>;
        ent = new Array();
        // eslint-disable-next-line guard-for-in
        for (const ind in data) {
          // console.log(JSON.stringify(ind) + ' - ' + JSON.stringify(data[ind]));
          ent.push( data[ind]['name']);
        }

        resolve(ent);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        reject(error);
      });
    });
  }

  getProvinzen(): any {
    const provinzen: string[] = [
      'Agrigent', 'Alessandria', 'Ancona', 'Aosta (Aostatal)', 'Arezzo', 'Ascoli Piceno', 'Asti', 'Avellino', 'Bari', 'Barletta - Andria - Trani', 'Belluno', 'Benevento', 'Bergamo', 'Biella', 'Bologna', 'Bozen', 'Brescia', 'Brindisi', 'Cagliari', 'Caltanissetta', 'Campobasso', 'Caserta', 'Catania', 'Catanzaro', 'Chieti', 'Como', 'Cosenza', 'Cremona', 'Crotone', 'Cuneo', 'Enna', 'Fermo', 'Ferrara', 'Florenz', 'Foggia', 'Forlì - Cesena', 'Frosinone', 'Genua', 'Gorizia', 'Grosseto', 'Imperia', 'Isernia', 'La Spezia', 'L’Aquila', 'Latina', 'Lecce', 'Lecco', 'Livorno', 'Lodi', 'Lucca', 'Macerata', 'Mailand', 'Mantua', 'Massa - Carrara', 'Matera', 'Messina', 'Modena', 'Monza und Brianza', 'Neapel', 'Novara', 'Nuoro', 'Oristano', 'Padua', 'Palermo', 'Parma', 'Pavia', 'Perugia', 'Pesaro und Urbino', 'Pescara', 'Piacenza', 'Pisa', 'Pistoia', 'Pordenone', 'Potenza', 'Prato', 'Ragusa', 'Ravenna', 'Reggio Calabria', 'Reggio Emilia', 'Rieti', 'Rimini', 'Rom', 'Rovigo', 'Salerno', 'Sassari', 'Savona', 'Siena', 'Syrakus', 'Sondrio', 'Sud Sardegna', 'Tarent', 'Teramo', 'Terni', 'Trapani', 'Treviso', 'Trient', 'Triest', 'Turin', 'Udine', 'Varese', 'Venedig', 'Verbano - Cusio - Ossola', 'Vercelli', 'Verona', 'Vibo Valentia', 'Vicenza', 'Viterbo'
      ];
    return provinzen;
  }


//  async loadMitglied(email, password) {
//   await this.authservice.getUrl('/portal/benutzerwebsite?benutzername=' + email + '&passwort=' + password).then((data)  => {
//     this.mitglied = data as Mitglied;
//   });
//  }

  // async loadOfficeFarben(){
  //   this.officeFarben = new Array();
  //   await this.authservice.getUrl('/portal/officefarben').then(async (data: Array<Object>) => {
  //     for(let item of data){
  //       this.officeFarben.push(new Object({name: item['schluessel'], value: item['wert']}));
  //     }
  //   });
  // }


  loadMitglied(): Promise<Mitglied> {
    if (this.authservice.CheckExpDate()) {

      this.authservice.currentUser = null;

      localStorage.removeItem('mgd_id');
      localStorage.removeItem('mgd_vorname');
      localStorage.removeItem('benutzer_id');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('expdate');
      localStorage.removeItem('expires_in');
    }
    return new Promise((resolve, reject) => {
      this.mitglied_id = localStorage.getItem('mgd_id');
      this.benutzer_id = localStorage.getItem('benutzer_id');
      if (this.mitglied_id && (!this.mitglied || this.mitglied_id !== this.mitglied.id)) {
        this.authservice.get('/portal/mitglied/' + this.mitglied_id).then((data) => {
          this.mitglied = data as Mitglied;
          this.mitglied.sek_name = this.Sektion(this.mitglied.sek_id);
          if (this.mitglied.reihenfolge == null && this.mitglied.zeitung == null) {
            this.mitglied.zeitung = true;
          }
          this.mitglied.sek_name = this.Sektion(this.mitglied.sek_id);
          resolve(this.mitglied);
        }).catch( (error) => {
          resolve(error);
        });
      } else {
        resolve(this.mitglied);
      }
    });
  }

  setCookies() {
    const sekunden = localStorage.getItem('expires_in');
    const d = moment(new Date());
    d.add(sekunden, 'seconds');
    const expires = d.toString();
    // const domain= '.alpversued.online/'; //inkl. Subdomains
    const domain = AppConfig.settings.cookiedomain; //  ''; // inkl. Subdomains
    const path = '/';
    const secure = 'secure';
    this.setCookie('expiredate', expires , parseInt(sekunden, 10), path, domain, secure);
    this.setCookie('loggedin', 'true' , parseInt(sekunden, 10), path, domain, secure);
    this.setCookie('nachname', this.nachname , parseInt(sekunden, 10), path, domain, secure);
    this.setCookie('vorname', this.vorname , parseInt(sekunden, 10), path, domain, secure);
    this.setCookie('anrede', this.anrede === 'Herrn' ? 'Herr' : this.anrede , parseInt(sekunden, 10), path, domain, secure);
    this.setCookie('mitgliedsnr', this.mitglied.mitgliedsnr , parseInt(sekunden, 10), path, domain, secure);
  }

  deleteCookies() {
    const domain = AppConfig.settings.cookiedomain; //  ''; // inkl. Subdomains
    this.eraseCookie('expiredate', domain);
    this.eraseCookie('loggedin', domain);
    this.eraseCookie('nachname', domain);
    this.eraseCookie('vorname', domain);
    this.eraseCookie('anrede', domain);
    this.eraseCookie('mitgliedsnr', domain);
  }

  setCookie(cname, cvalue, sekunden, path, domain, secure) {
    const d = moment(new Date());
    d.add(sekunden, 'seconds');
    const expires = 'expires=' + d.toString();
    let coo = cname + '=' + cvalue + ';' ;
    coo += expires;
    if (path) { coo += ';path=' + path; }
    if (domain) { coo += ';domain=' + domain; }
    if (secure) { coo += ';secure=' + secure; }
    document.cookie = coo;
  }

  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  eraseCookie(name, domain) {
    let coo = name + '=; Max-Age=-99999999;';
    if (domain) { coo += ';domain=' + domain; }

    document.cookie = coo;
  }

  onKeyDown(e) {
    console.log('onKeyDown', e.event.keyCode, e.event);
    const event = e.event;
    const keyCode = event.keyCode;

    // The next commented line is for debugging purposes:
    const arr = [46, 8, 9, 27, 13, 110, 190, 40, 37, 39, 38, 34, 33, 36, 35];
    const index = arr.findIndex(x => x === keyCode);
    // Allow: Backspace, Delete, Tab, Escape, Enter and '.'
    if ((!isNaN(parseInt(event.key, 10))) || // 0-9
      (keyCode >= 65 && keyCode <= 90) || // a-z
      event.key === '.' ||
      event.key === '-' ||
      event.key === '_' ||
      event.key === '@' ||
      // Allow: Ctrl+A
      (keyCode === 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (keyCode === 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (keyCode === 88 && event.ctrlKey === true) ||
      // ||
      index !== -1

      // // Allow: home, end, left, right
      // (keyCode >= 35 && keyCode <= 39)
      ) {
      // Let it happen, don't do anything
         return;
    }

    event.preventDefault();
    // Ensure that it is a letter and stop the keypress otherwise
    if (keyCode < 65 || keyCode > 90) {
      event.preventDefault();
    }
  }

  istKunde(kunde: enuKunde): boolean {
    // console.log('istKunde', AppConfig.settings.kunde, kunde,AppConfig.settings.kunde === kunde);
    return AppConfig.settings.kunde === kunde;
  }

  mmgdnummerEingabe(eingabeMitgliedsnummer: enuEingabeMitgliedsnummer): boolean {
    // console.log('mmgdnummerEingabe', this.eingabeMitgliedsnummer, eingabeMitgliedsnummer,this.eingabeMitgliedsnummer === eingabeMitgliedsnummer);
    return this.eingabeMitgliedsnummer === eingabeMitgliedsnummer;
  }

  getNewsletter(): boolean {
    let newsletter = false;
    if (this.mitnewsletter) {
      newsletter = true;
    }
    return newsletter;
  }

  onInitializedDe(e) {
    if (localStorage.getItem('locale') !== 'de') {
      e.component.option('elementAttr', {class: 'other-button-class'});
    }
  }
  
  onInitializedIt(e) {
    if (localStorage.getItem('locale') !== 'it') {
      e.component.option('elementAttr', {class: 'other-button-class'});
    }
  }

  onInitializedEn(e) {
    if (localStorage.getItem('locale') !== 'en') {
      e.component.option('elementAttr', {class: 'other-button-class'});
    }
  }

  onInitializedLd(e) {
    if (localStorage.getItem('locale') !== 'ld') {
      e.component.option('elementAttr', {class: 'other-button-class'});
    }
  }

  setColors() {
    setTimeout(() => {
      let primary = funktionen.argbToHex(this.vereinsdaten.farbe1)
      let secondary = funktionen.argbToHex(this.vereinsdaten.farbe2)
      let tertiary = funktionen.argbToHex(this.vereinsdaten.farbe3)
      document.documentElement.style.setProperty('--primary', primary);
      document.documentElement.style.setProperty('--primary--opacity--0_3', funktionen.addOpacitytoHex(primary, 0.3));
      document.documentElement.style.setProperty('--primary--opacity--0_5', funktionen.addOpacitytoHex(primary, 0.5));
      document.documentElement.style.setProperty('--secondary', secondary);
      document.documentElement.style.setProperty('--tertiary', tertiary);
    }, 0);
  }


  // SIAPay Post Form
  openWindowWithPostRequest(url, daten) {
    var winName='_self';
    var winURL=url;
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", winURL);
    form.setAttribute("target",winName);  
    for (var i in daten) {
    if (daten.hasOwnProperty(i)) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = daten[i];
        form.appendChild(input);
    }
    }              
    document.body.appendChild(form);                       
    window.open('', '_self');
    form.target = winName;
    form.submit();                 
    document.body.removeChild(form);           
  }
}
