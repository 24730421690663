// import { Dictionary } from 'typescript-collections';
import { AuthService } from './../services/auth.service';
import { Injectable, EventEmitter } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Einstellungen, EinstellungenItem, EinstellungenService } from './../services/einstellungen.service';
import * as funktionen from './../globals/functions';
import { enuRowState, BaseItem } from './base.model';
// import { Rechte, SpaltenCrud } from './../globals/rechte';
import { DataUrl } from './../services/dataurls';
// import { enuTabelle } from './../globals/enumerationen';
import ThreadedLock from 'threaded-lock';
import * as moment from 'moment';

export enum BaseEventType {
  AfterContentInit,
  AfterDsReload,
  ClickSuche,
  ClickSucheKontenplan,
  Custom,
  Fehler,
  Fehlermeldung,
  HideLoadPanel,
  ListeGeladen,
  NaviRechteLoaded,
  NaviSelected,
  ResetFilter,
  RowAdded,
  RowDeleted,
  RowGeladen,
  RowNew,
  RowNewGeladen,
  RowSelected,
  RowUpdated,
  SelectFirst,
  SelectId,
  SetFilter,
  ShowLoadPanel,
  SucheGeladen,
  TeilnehmerNeuExtern,
  onNewClick,
  ClearSelection,
}

export class BaseEventArgs {
  public Type: BaseEventType;
  public Args: any;

  constructor(type: BaseEventType, args: any) {
    this.Type = type;
    this.Args = args;
  }
}

@Injectable()
export abstract class BaseService {
  public loadingVisible = false;
  private loadingVisibleCounter = 0;

  _navIndex: number;
  datenListe: Array<BaseItem>;
  private isloaded: boolean;
  protected baseitem: BaseItem;
  public onEvent = new EventEmitter<BaseEventArgs>(false);
  einstellung: Einstellungen;
  public rechte: Array<boolean>;
  protected getDataUrl: string;
  // protected serviceList: Dictionary<number, NavServiceItem>;

  public datagrid: any;  // DataGrid

  public parentService: BaseService;  // Für LoadPanel

  constructor(
    protected authservice: AuthService,
    // protected einstellungen: EinstellungenService,
    public dataurl: DataUrl,
    // einstellung: Einstellungen,
    // public sp: SpaltenCrud,
  ) {
    // this.serviceList = new Dictionary<number, NavServiceItem>();
    this.parentService = null;
    this.getDataUrl = dataurl.url;
    this.navIndex = 1;
    this.datenListe = new Array();
    this.baseitem = new BaseItem();
    this.isloaded = false;
    // this.einstellung = einstellung;
    this.rechte = new Array();
  }

  get currentUser(): string {
    return this.authservice.currentUser;
  }
  get menu(): string {
    return '';
  }

  get navIndex(): number {
    return this._navIndex;
  }
  set navIndex(navIndex: number) {
    if (this._navIndex !== navIndex) {
      // console.log((<any>this).constructor.name + 'set navindex', this._navIndex, navIndex);
      this._navIndex = navIndex;
      this.doEvent(BaseEventType.NaviSelected, navIndex);
    }
  }
  get IsNew(): boolean {
    if (!this.baseitem) { return false; }
    return this.baseitem.state === enuRowState.new;
  }
  get IsEmpty(): boolean {
    if (!this.baseitem) { return false; }
    return this.baseitem.state === enuRowState.empty;
  }
  get item(): BaseItem {
    return this.baseitem;
  }

  get Einstellung(): Einstellungen {
    return this.einstellung;
  }

  createItem(): BaseItem {
    // console.log((<any>this).constructor.name, 'createItem');
    return new BaseItem();
  }

  createListeItem(): BaseItem {
    // console.log((<any>this).constructor.name, 'createListeItem');
    return new BaseItem();
  }

  createNewItem() {
    // console.log((<any>this).constructor.name, 'createNewItem');
    this.baseitem = this.createItem();
    this.baseitem.state = enuRowState.new;
    this.baseitem.id = UUID.UUID();
    // console.log('createNewItem', this.baseitem);
  }

  clearItem() {
    // console.log((<any>this).constructor.name, 'clearItem');
    this.baseitem.state = enuRowState.empty;
    const bi = this.createItem();
    bi.state = enuRowState.empty;
    this.baseitem = bi;
  }

  HasChangesItem(): boolean {
    return this.item.HasChanges();
    // return false;
  }

  // HasChanges(key: number): boolean {
  //   if (this.serviceList.containsKey(key)) {
  //     return this.serviceList.getValue(key).service.HasChangesItem();
  //   } else {
  //     console.log('Service not found', key);
  //     return false;
  //   }
  // }

  // HasChildChanges(): boolean {
  //   for (const srv of this.serviceList.values()) {
  //     if (srv.service.HasChangesItem()) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // ResetChanges(key: number) {
  //   if (this.serviceList.containsKey(key)) {
  //     this.serviceList.getValue(key).service.item.ResetChanges();
  //   }
  // }

  // SaveChanges(key: number): Promise<boolean> {
  //   return new Promise((resolve, reject) => {
  //     if (this.serviceList.containsKey(key)) {
  //       const serviceItem = this.serviceList.getValue(key);
  //       let spalten: string[];
  //       if (serviceItem.service.IsNew) {
  //         if (serviceItem.rechtNeu instanceof Array) {
  //           spalten = this.sp.GetSpalten( serviceItem.rechtNeu[0], serviceItem.tabelle);
  //         } else {
  //           spalten = this.sp.GetSpalten( serviceItem.rechtNeu, serviceItem.tabelle);
  //         }
  //       } else {
  //         if (serviceItem.rechtSpeichern instanceof Array) {
  //           spalten = this.sp.GetSpalten( serviceItem.rechtSpeichern[0], serviceItem.tabelle);
  //         } else {
  //           spalten = this.sp.GetSpalten( serviceItem.rechtSpeichern, serviceItem.tabelle);
  //         }
  //       }
  //       serviceItem.service.putDataSP(spalten)
  //       .then( (ret) => {
  //         resolve(true);
  //       }).catch( (error) => {
  //         reject(error);
  //       });
  //     } else {
  //       reject(false);
  //     }
  //   });
  // }

  GetBenutzername(): string {
    if (!funktionen.isEmptyString(this.authservice.currentUser)) {
      return this.authservice.currentUser;
    }
    return '';
  }

  // AddService(key: number, serviceItem: NavServiceItem) {
  //   this.serviceList.setValue(key, serviceItem);
  // }

  getData(reload: boolean = false): Promise<boolean | BaseItem[]> {
    return new Promise((resolve, reject) => {
      // console.log('Service getData: ' + this.isloaded + " - " + this.datenListe.length + " - " + reload);
      if (this.isloaded && this.datenListe.length > 0 && reload === false) {
        resolve(this.datenListe);
        return;
      }
      this.ShowLoadPanel();
      if (this.datagrid)  {
        if (this.datagrid.instance)  {
          // console.log('datagrid beginUpdate', this.datagrid);
          this.datagrid.instance.beginUpdate();
        } else {
          // console.log('datagrid instance',  null);
        }
      } else {
        // console.log('datagrid', null);
      }

      let params = this.einstellung.GetParam();
      if (params.length > 0) { params = '?' + params; }
      // console.log((<any>this).constructor.name + ' getData ' + reload, this.getDataUrl, params);

      if (this.datenListe.length > 0) { this.datenListe.splice(0, this.datenListe.length); }

      this.authservice.get(this.getDataUrl + params)
      .then((data) => {
        // console.log('getData: ' + JSON.stringify(data));
        if (this.datenListe.length > 0) { this.datenListe.splice(0, this.datenListe.length); }

        // eslint-disable-next-line guard-for-in
        for (const ind in data) {
          // console.log(JSON.stringify(ind) + ' - ' + JSON.stringify(data[ind]));
          const item = this.createListeItem();
          item.SetData(data[ind]);
          this.datenListe.push( item);
          // console.log('after push');
        }

        this.isloaded = true;
        if (this.datagrid && this.datagrid.instance) {
          // console.log('datagrid endUpdate')
          this.datagrid.instance.endUpdate();
        }
        this.HideLoadPanel();
        resolve(true);
        return;
      })
      .catch((error) => {
        if (this.datagrid && this.datagrid.instance) {
          // console.log('datagrid endUpdate');
          this.datagrid.instance.endUpdate();
        }
        this.HideLoadPanel();
        // console.log((<any>this).constructor.name + ' Fehler getData ', error);
        reject(error);
      });
    });
  }

  getDataId(id: string): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      // console.log((<any>this).constructor.name + ' getDataId ', this.getDataUrl, id);
      this.authservice.get(this.getDataUrl + '/' + id)
      .then((data: any[]) => {
        // console.log('getDataId ' + JSON.stringify(data));
        this.baseitem = this.createItem();
        this.baseitem.SetData(data);
        this.HideLoadPanel();
        resolve(this.baseitem);
      })
      .catch((error) => {
        console.log('Fehler ', error);
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  public getSendDataFromObject(parameter: any): string {
    let s: string;
    s = '';
    // eslint-disable-next-line guard-for-in
    for ( const sp in parameter ) {
      if (s.length > 0) { s = s + '&'; }
      if (parameter[sp] instanceof Date) {
        const m = moment(parameter[sp]);
        s = s + sp + '=' + m.format();
      } else {
        s = s + sp + '=' + parameter[sp];
      }
    }
    if (s.length > 0) { s = '?' + s; }
    return s;
  }

  getNewData(parameter: any): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      // console.log((<any>this).constructor.name + ' getDataId ', this.getDataUrl, id);
      this.authservice.get(this.getDataUrl + '/new' + this.getSendDataFromObject(parameter))
      .then((data: any[]) => {
        console.log('getNewData ', data);
        this.createNewItem();
        this.baseitem.SetData(data);
        this.baseitem.state = enuRowState.new;
        this.HideLoadPanel();
        this.doEvent(BaseEventType.RowGeladen, null);
        resolve(this.baseitem);
      })
      .catch((error) => {
        console.log('Fehler ', error.statusText);
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  getNewDataUrl(url: string, parameter: any): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      // console.log((<any>this).constructor.name + ' getDataId ', this.getDataUrl, id);
      this.authservice.get(url + this.getSendDataFromObject(parameter))
      .then((data: any[]) => {
        console.log('getNewData ', data);
        this.createNewItem();
        this.baseitem.SetData(data);
        this.baseitem.state = enuRowState.new;
        this.HideLoadPanel();
        this.doEvent(BaseEventType.RowGeladen, null);
        resolve(this.baseitem);
      })
      .catch((error) => {
        console.log('Fehler ', error.statusText);
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  GetBlob(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      this.authservice.getBlob(url)
      .then((data: Blob) => {
        this.HideLoadPanel();
        resolve(data);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  GetDataUrl(url: string, no_access_token = false): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      this.authservice.get(url, no_access_token)
      .then((data: any[]) => {
        this.HideLoadPanel();
        resolve(data);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  getNaviRechte(id: string): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      this.authservice.get('/rechte/navi/' + id)
      .then((data) => {
        // eslint-disable-next-line guard-for-in
        for (const ind in data) {
          // console.log(JSON.stringify(ind) + " " + data[ind]);
          this.rechte[ind] = data[ind];
        }
        this.HideLoadPanel();
        resolve(data);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  private getSendData(spalten: string[]): any {
    const data = new Object;
    for (const sp of spalten) {
      if (this.baseitem[sp] instanceof Date) {
        const m = moment(this.baseitem[sp]);
        data[sp] = m.format();
      } else if (this.baseitem[sp] instanceof Array) { // Damit Objekt Serialisierbar ist
        const arr = new Array();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.baseitem[sp].length; i++) {
          if ((this.baseitem[sp][i] as BaseItem).GetItemAsArray !== undefined) {
            arr.push((this.baseitem[sp][i] as BaseItem).GetItemAsArray());
          } else if (typeof this.baseitem[sp][i] === 'string') {
            arr.push(this.baseitem[sp][i]);
          } else {
            const d1 = new Object();
            for (const el in this.baseitem[sp][i]) {
              if (this.baseitem[sp][i][el] instanceof Date) {
                const m = moment(this.baseitem[sp][i][el]);
                d1[el] = m.format();
              } else {
                d1[el] = this.baseitem[sp][i][el];
              }
            }
            arr.push(d1);
          }
        }
        data[sp] = arr;
      } else {
        data[sp] = this.baseitem[sp];
      }
    }
    return data;
  }
  putDataSP(spalten: string[]): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      let url = '';

      if (funktionen.isEmptyString(this.baseitem.id)) {
        url = this.getDataUrl;
      } else {
        url = this.getDataUrl + '/' + this.baseitem.id;
      }

      // console.log('putDataSP ', this.baseitem);
      spalten.push('id');

      const senddata = this.getSendData(spalten);
      // console.log('put ', senddata);
      this.authservice.put(url, senddata)
      .then((res) => {
        // console.log('put ' + JSON.stringify(res) + ' ' + this.baseitem.id);
        const state = this.baseitem.state;
        this.baseitem.state = enuRowState.unchanged;
        this.getDataId(this.baseitem.id)
        .then ((data) => {
          this.putToDatenList(this.baseitem);
          if (state === enuRowState.new) {
            this.doEvent(BaseEventType.RowAdded, this.item.id);
          } else {
            this.doEvent(BaseEventType.RowUpdated, this.item.id);
          }
          this.HideLoadPanel();
          resolve(res);
        })
        .catch((error) => {
          console.log('Fehler ', error);
          this.HideLoadPanel();
          reject(error);
        });

      })
      .catch((error) => {
        console.log('Fehler ', error);
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  private setSendDataFromObject(data: any): any {
    for ( const sp in data ) {
      if (data[sp] instanceof Date) {
        const m = moment(data[sp]);
        data[sp] = m.format();
        // weis nicht ob es benötigt wird
      // } else if (data[sp] instanceof Array) { // Damit Objekt Serialisierbar ist
      //   const arr = new Array();
      //   for (let i = 0; i < data[sp].length; i++) {
      //     if ((data[sp][i] as BaseItem).GetItemAsArray !== undefined) {
      //       arr.push((data[sp][i] as BaseItem).GetItemAsArray());
      //     } else if (typeof data[sp][i] === 'string') {
      //       arr.push(data[sp][i]);
      //     } else {
      //       const d1 = new Object;
      //       for (const el in data[sp][i]) {
      //         d1[el] = data[sp][i][el];
      //       }
      //       arr.push(d1);
      //     }
      //   }
      //   data[sp] = arr;
      } else {
        data[sp] = data[sp];
      }
    }
    return data;
  }



async putDataUrl(url: string, senddata: any, showLoadPanel = true, no_access_token = false): Promise<any>  {
  return new Promise((resolve, reject) => {
      if (showLoadPanel) this.ShowLoadPanel();

      this.setSendDataFromObject(senddata);
      // console.log('put ', senddata);
      this.authservice.put(url, senddata, no_access_token)
      .then((res: unknown) => {
        // console.log('put ' + JSON.stringify(res) + ' ' + this.baseitem.id);
        if (showLoadPanel) this.HideLoadPanel();
        resolve(res);
      })
      .catch((error: any) => {
        console.log('Fehler ', error);
        if (showLoadPanel) this.HideLoadPanel();
        reject(error);
      });
    });
  };


  public putToDatenList(ent: BaseItem) {
    for (const e of this.datenListe) {
      if (e.id === ent.id) {
        e.SetDataFromItem(ent);
        return;
      }
    }
    const el = this.createListeItem();
    el.SetDataFromItem(ent);
    this.datenListe.push(el);
  }

  deleteDataUrl(url: string): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();

      // console.log('put ', senddata);
      this.authservice.delete(url)
      .then((res) => {
        // console.log('put ' + JSON.stringify(res) + ' ' + this.baseitem.id);
        this.HideLoadPanel();
        resolve(res);
      })
      .catch((error) => {
        console.log('Fehler ', error);
        this.HideLoadPanel();
        reject(error);
      });
    });
  }

  public deleteData() {
    return new Promise((resolve, reject) => {
      this.ShowLoadPanel();
      const url = this.getDataUrl + '/' + this.baseitem.id;
      this.authservice.delete(url)
      .then((res) => {
      //   console.log('delete ' + JSON.stringify(res));
        this.deleteFromDatenList(this.baseitem.id);
        this.baseitem = this.createItem();

        // this.rowDeleted.emit();
        this.HideLoadPanel();
        resolve(res);
      })
      .catch((error) => {
        console.log('Fehler ' + JSON.stringify(error));
        this.HideLoadPanel();
        reject(error);
      });
    });
  }
  private deleteFromDatenList(id: string) {
    for (let i = 0; i < this.datenListe.length; i++) {
      // console.log(JSON.stringify(ind) + ' - ' + JSON.stringify(data[ind]));
      // ent.push( data[ind]);
      const e = this.datenListe[i];
      if (e.id === id) {
        // console.log(e.id + ' - ' + ent.id + ' - ' + JSON.stringify(ent) + ' - ' + JSON.stringify(ent.name));
        this.datenListe.splice(i, 1);
        return;
      }
    }
  }

  onNewClick(params: any) {
    this.doEvent(BaseEventType.RowNew, params);
  }

  onSaveClick(params: any): boolean {
    return true;
  }

  onPrintClick(params: any) {
  }

  async IncVisiblecounter() {
    // console.log('IncVisiblecounter Start');
    const tl = new ThreadedLock('loadpanel-lock', 100);
    await tl.lock();

    this.loadingVisibleCounter = this.loadingVisibleCounter + 1;
    if (this.loadingVisibleCounter > 0 && !this.loadingVisible) {
      this.loadingVisible = true;
    }
    // console.log((<any>this).constructor.name + ' IncVisiblecounter ', this.loadingVisibleCounter, this.loadingVisible);

    tl.unlock();
    // console.log('IncVisiblecounter Stop');
  }

  async DecVisiblecounter() {
    // console.log('DecVisiblecounter Start');
    let check = false;
    const tl = new ThreadedLock('loadpanel-lock', 100);
    await tl.lock();

    this.loadingVisibleCounter = this.loadingVisibleCounter - 1;
    if (this.loadingVisibleCounter === 0 && this.loadingVisible) {
      this.loadingVisible = false;
      check = true;
    }
    // console.log((<any>this).constructor.name + ' DecVisiblecounter ', this.loadingVisibleCounter, this.loadingVisible);

    tl.unlock();
    if (check) {
      setTimeout(() => {
        this.SetVisibleFalse();
      }, 300);
    }
    // console.log('DecVisiblecounter Stop');
  }

  async SetVisibleFalse() {
    const t1 = new ThreadedLock('loadpanel-lock', 100);
    await t1.lock();
    // console.log((<any>this).constructor.name + ' SetVisibleFalse ', this.loadingVisibleCounter, this.loadingVisible);
    if (this.loadingVisibleCounter === 0 && this.loadingVisible) {
      this.loadingVisible = false;
      // console.log((<any>this).constructor.name + ' SetVisibleFalse ', this.loadingVisibleCounter, this.loadingVisible);
    }
    t1.unlock();
  }

  ShowLoadPanel() {
    // console.log((<any>this).constructor.name + ' ShowLoadPanel ', this.parentService);
    if (this.parentService) {
      this.parentService.ShowLoadPanel();
    } else {
      // console.log((<any>this).constructor.name + ' ShowLoadPanel ');
      this.IncVisiblecounter();
    }
  }

  HideLoadPanel() {
    // console.log((<any>this).constructor.name + ' HideLoadPanel ', this.parentService);
    if (this.parentService) {
      this.parentService.HideLoadPanel();
    } else {
      this.DecVisiblecounter();
      // console.log((<any>this).constructor.name + ' HideLoadPanel ');
    }
  }

  // CheckMenuItem(item: any, rechte: Array<boolean>, recht: Rechte, rechtRegion: Rechte): any {
  //   let ret: boolean;
  //   ret = null;
  //   if (item.recht instanceof Array) {
  //     for (const r of item.recht) {
  //       if (r === recht) {
  //         if (rechte[rechtRegion]) {
  //           item.disabled = false;
  //           ret = true;
  //         } else {
  //           item.disabled = true;
  //           ret = false;
  //         }
  //       }
  //     }
  //   } else if (item.recht === recht) {
  //     if (rechte[rechtRegion]) {
  //       item.disabled = false;
  //       ret = true;
  //     } else {
  //       item.disabled = true;
  //       ret = false;
  //     }
  //   }
  //   return ret;
  // }

  // HatRecht(rechte: Rechte | Rechte[]): boolean {
  //   if (rechte instanceof Array) {
  //     for (const rn of rechte) {
  //       if (this.rechte[rn]) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   } else {
  //     if (this.rechte[rechte]) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  // HatRechtItem(rechte: Rechte | Rechte[]): boolean {
  //   if (!this.item) {
  //     return false;
  //   }
  //   if (rechte instanceof Array) {
  //     for (const rn of rechte) {
  //       if (this.item.rechte[rn]) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   } else {
  //     if (this.item.rechte[rechte]) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  public doEvent(_type: BaseEventType, _args: any): void {
    if (!Object.values(BaseEventType).includes(_type)) {
      throw Error('BaseService eventEnum.subscribe: e.Type ' + _type + ' missing.');
    }

    this.onEvent.emit(new BaseEventArgs(_type, _args));
  }
}

// Liste Daten von Id Laden
@Injectable()
export abstract class BaseServiceItem extends BaseService {
  public _id: string;
  public parent_id: string;

  constructor(
    public authservice: AuthService,
    // public einstellungen: EinstellungenService,
    public dataurl: DataUrl,
    // einstellung: EinstellungenItem,
    // public sp: SpaltenCrud,
  ) {
    super(authservice, dataurl);
  }

  // get Einstellung(): EinstellungenItem {
  //   return <EinstellungenItem> this.einstellung;
  // }

  get Id(): string {
    return this._id;
  }
  set Id(id) {
    this._id = id;
    // this.Einstellung.id = this._id;
    this.getData(true).then( (data) => {
      this.doEvent(BaseEventType.ListeGeladen, null);
    }).catch((error) => {
      console.log((this as any).constructor.name + ' Set Id Fehler ', error);
      this.doEvent(BaseEventType.Fehlermeldung, 'Ungültige Rechte');
    });

  }

}

// Daten von Id Laden
@Injectable()
export abstract class BaseServiceItemId extends BaseServiceItem {
  constructor(
    public authservice: AuthService,
    public einstellungen: EinstellungenService,
    public dataurl: DataUrl,
    einstellung: EinstellungenItem,
    // public sp: SpaltenCrud,
  ) {
    super(authservice, dataurl);
  }

  get Einstellung(): EinstellungenItem {
    return this.einstellung as EinstellungenItem;
  }

  get Id(): string {
    return this._id;
  }
  set Id(id) {
    this._id = id;
    this.Einstellung.id = this._id;
    this.getDataId(this._id).then( (data) => {
      // console.log((<any>this).constructor.name + ' getDataId promise ');
      this.doEvent(BaseEventType.ListeGeladen, null);
    }).catch((error) => {
      if (error && error.status === 400 && error.statusText === 'Zeile nicht vorhanden.') {
        this.clearItem();
      }
      console.log((this as any).constructor.name + ' Set Id Fehler ', error);
    });

  }

}
