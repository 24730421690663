import { enuAnrede, enuRegistrierungAls, enuZahlungsweise } from './../globals/enumerationen';
import { Register } from '../register/register.model';
import { Kontakt } from '../register/kontakt.model';

export class Profil {
  public id: string;
  public anrede: enuAnrede;
  public vorname: string;
  public nachname: string;
  public verheirateterName: string;
  public email: string;
  public land: string;
  public nationalitaet: string;
  public plz: string;
  public ort: string;
  public strasse: string;
  public weiler: string;
  public hausnr: string;
  public provinz: string;
  public telefon: string;
  public geburtsdatum: Date;
  public geburtsort: string;
  public steuernummer: string;
  public titel: string;
  public mitgliedsnummer: number;
  public erzberechtigte: Kontakt[];
  public sektion: string;
  public beitrag: number;
  public beitragskategorie: any;
  public bankbezeichnung: string;
  public bankbic: string;
  public bankiban: string;
  public banknachname: string;
  public bankvorname: string;
  public bankstrasse: string;
  public bankplz: string;
  public bankort: string;
  public banknation: string;
  public offenermitgliedsbeitrag: number;
  public kursteilnahme: boolean;
  public passwort: string;
  public newsletter: boolean;

  constructor(
  ) {
    // super();
    // this.nationalitaet = 'Italien';
  }


}






