import { Kontakt } from './kontakt.model';

export class Register extends Kontakt{

  public benutzername: string;
  public passwort: string;
  public passwort2: string;
  public newsletter: boolean;

  constructor(
    newsletter: boolean
  ) {
    super();
    this.newsletter = newsletter;
    this.steuergeprueft = true;
  }




}


