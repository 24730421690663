import { ProtectedGuard } from './services/protected.guard';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { LoginComponent } from './login/login.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AuthService } from './services/auth.service';
import { EinstellungenService } from './services/einstellungen.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DxListModule, DxDataGridModule, DxTextBoxModule, DxButtonModule, DxFileUploaderModule, DxLoadPanelModule, 
  DxValidatorModule, DxSelectBoxModule, DxCheckBoxModule, DxTabsModule, DxTextAreaModule, DxPopupModule, DxScrollViewModule, 
  DxSwitchModule, DxTemplateModule, DxFormModule, DxValidationGroupModule, DxBoxModule, DxResponsiveBoxModule, DxMenuModule, DxToastModule} from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfig } from './app.config';
import { RegisterComponent } from './register/register.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { BeitraegeService } from './services/beitraege.service';
import { SteuernummerService } from './services/steuernummer.service';
import { GlobaleDatenService } from './services/globaledaten.serice';
import { ResetPassword2Component } from './resetpassword/resetpassword2.component';
import { Register2Component } from './register/register2.component';
import { DataUrlProfil } from './profil/profil.service';
import { DataUrlMitglied } from './mitglied/mitglied.service';
import { AppRouteChangeService } from './services/app-route-change.service';
import { NewsletterAbmeldenComponent } from './newsletter/newsletterabmelden.component';
import { VersionComponent } from './version/version.component';
import { LoginModule } from './login/login.module';
import { Loginoauth2Module } from './loginoauth2/loginoauth2.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { locale } from 'devextreme/localization';
import { CustomLoader } from './services/custom-loader';
import * as Sentry from "@sentry/angular-ivy";
import { SprachauswahlModule } from './translate/sprachauswahl.module';
import { ButtonModule } from './components/button/button.component';
import { Loginoauth2Component } from './loginoauth2/loginoauth2.component';
import config from 'devextreme/core/config';
 
export const licenseKey = 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjdkMjU4Y2ExLWFmYTUtNDJlZC1iMGMyLTBkOWEyN2FjYzBmMyIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjMyCn0=.qgWHCQuwgC1Tiu+yVIhCKiMzAcQmxm8aSpocptdRXPi08PQB8OOqhPLAoGZ5uThEBS7EVYnILAMxT7GzYbKvPSoK6eRPSBwDtP8as0YX5BTkUq9dk4K2wUJ456BgpSKDyVgTBQ==';
config({ licenseKey: licenseKey });

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let langToSet = null;

      let sprachenUebersetzung = [
        'de', 'it', 'en'
      ];
    
      const url = new URL(window.location.href);
      const qlocale = url.searchParams.get('locale');
      if(qlocale) {
        if(sprachenUebersetzung.find( (x) => x ===qlocale) != null) {
          langToSet = qlocale;
        } else {
          langToSet = 'de';
        }
        localStorage.setItem('locale', langToSet);
      } else if(localStorage.getItem('locale')) {
        langToSet = localStorage.getItem('locale');
      }

      if (!langToSet) {
        // const browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/de|it|en/) ? browserLang : 'de');
        // langToSet = browserLang.match(/de|it|en/) ? browserLang : 'de';
        localStorage.setItem('locale', 'de');
        langToSet = 'de';
      }
      locale(langToSet);
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
        console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    Loginoauth2Component,
    RegisterComponent,
    Register2Component,
    ResetPasswordComponent,
    ResetPassword2Component,
    NewsletterComponent,
    NewsletterAbmeldenComponent,
    VersionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DxListModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxPopupModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxLoadPanelModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTextAreaModule,
    DxSwitchModule,
    DxScrollViewModule,
    DxTemplateModule,
    DxTabsModule,
    DxFormModule,
    FormsModule,
    ReactiveFormsModule,
    DxValidationGroupModule,
    DxBoxModule,
    DxResponsiveBoxModule,
    DxMenuModule,
    DxValidationGroupModule,
    LoginModule,
    Loginoauth2Module,
    SprachauswahlModule,
    ButtonModule,
    DxToastModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
          provide: TranslateLoader,
          useClass: CustomLoader,
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    AuthService,
    ProtectedGuard,
    EinstellungenService,
    BeitraegeService,
    SteuernummerService,
    AppConfig,
    { provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true },
    { provide: LOCALE_ID, useValue: 'de-de' },
    { provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector], multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    GlobaleDatenService,
    DataUrlMitglied,
    DataUrlProfil,
    AppRouteChangeService,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
