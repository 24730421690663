import { DxLoadPanelModule, DxButtonModule, DxValidatorModule, DxFormModule, DxTabsModule, DxValidationGroupModule, DxLoadIndicatorModule, DxSelectBoxModule } from 'devextreme-angular';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SprachauswahlPage } from './sprachauswahl.page';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DxLoadPanelModule,
    DxButtonModule,
    DxValidatorModule,
    CommonModule,
    DxFormModule,
    DxValidationGroupModule,
    DxTabsModule,
    DxSelectBoxModule,
    DxLoadIndicatorModule,
    TranslateModule
  ],
  declarations: [
    SprachauswahlPage,
  ],
  providers: [
  ],
  exports: [
    SprachauswahlPage,
  ],

})
export class SprachauswahlModule { }
