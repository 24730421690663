import { DxLoadPanelModule, DxPopupModule, DxCheckBoxModule, DxButtonModule, DxScrollViewModule, DxValidatorModule, DxTextBoxModule, DxFormModule, DxBoxModule, DxValidationGroupModule } from 'devextreme-angular';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// eslint-disable-next-line max-len
import { TranslateModule } from '@ngx-translate/core';
import { SprachauswahlModule } from '../translate/sprachauswahl.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DxLoadPanelModule,
    DxPopupModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxScrollViewModule,
    DxValidatorModule,
    // DeviceDetectorService,
    CommonModule,
    DxTextBoxModule,
    DxFormModule,
    DxBoxModule,
    DxValidationGroupModule,
    TranslateModule,
    SprachauswahlModule
  ],
  declarations: [],
})
export class Loginoauth2Module { }

