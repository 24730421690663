import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import packageInfo from '../package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    environment: 'production',
    dsn: "https://b190a8a78dc7463f8744f0652b018868@o1286928.ingest.sentry.io/4504847759376397",
    
    // Set your release version, such as "getsentry@1.0.0"
    release: `${packageInfo.name}-${packageInfo.version}`,
    
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
