import { enuRegistrierungAls, enuSektionstyp, enuZahlungsweise, enuMitgliedstatus, enuFamilientyp, enuZahlungsart, enuZahlungsweg } from './../globals/enumerationen';
import { Register } from '../register/register.model';

export class Kategorie {
  id: string;
  name: string;
  kuerzel: string;
  beitrag: number;
}


export class Mitglied extends Register{
  public reihenfolge: number;

  public telMobil: string;
  public telGeschaeftlich: string;
  public telPrivat: string;
  public fax: string;
  public email2: string;
  public internet: string;

  public sek_id: string;
  public sek_name: string;
  public sek_typ: enuSektionstyp;
  public beitrag: number;
  public beitragskategorie: Kategorie;

  public zahlungsweise: enuZahlungsweise;
  public registrierungAls: enuRegistrierungAls;

  public status: enuMitgliedstatus;

  public bankbezeichnung: string;
  public bic: string;
  public iban: string;
  public nachnamekontoinhaber: string;
  public vornamekontoinhaber: string;
  public strassekontoinhaber: string;
  public plzkontoinhaber: string;
  public ortkontoinhaber: string;
  public nationkontoinhaber: string;

  public offenerbetrag: number;
  public offenerbetragzahlen: boolean;
  public offenerbetragtext: string;
  public kursteilnahme: boolean;

  public eintrittgv: string;
  public eintrittsek: string;
  public fam_id: string;
  public famtyp: enuFamilientyp;
  public mitglied: boolean;

  public datenschutz: boolean;
  public zeitung: boolean;
  public zeitungresolved: boolean;

  public zahlungsweg: enuZahlungsweg;
  public zahlung_sek_id: string;

  public imgSrc: string;
  public familie: any;
  constructor(
    newsletter: boolean
  ) {
    super(newsletter);
    this.kursteilnahme = false;
    this.mitglied = false;
    this.beitragskategorie = new Kategorie();
  }


  }






