<div class="inner-wrap" style="background-color:#f4f4f4; padding:20px;">
    <div class="main">
        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper">
              <h3>Version</h3>
              <div class="divider-wrap" data-alignment="default">
                  <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%" data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
              </div>
              <p style="padding-bottom:0px;">Versionsdaten</p>
              <br>
            </div>
        </div>
  
        <div id="form-container">
          <form id="version">
            <dx-form #formVersion [(formData)]="daten" [colCount]="2" [labelLocation]="'top'" >

              <dxi-item [dataField]="'version'" [colSpan]="1" [disabled]="true">
                <dxo-label text="Version"></dxo-label>
              </dxi-item>
              <dxi-item [dataField]="'versionsdatum'" [colSpan]="1" [disabled]="true">
                <dxo-label text="Versionsdatum"></dxo-label>
              </dxi-item>
            </dx-form>
          </form>
      </div>
    </div>
  </div>
  
  