import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, AfterContentInit, Input, NgModule } from '@angular/core';
import { enuKunde } from 'src/app/app-config.model';
import { BaseWrapperFunctionsComponent } from 'src/app/base/base-wrapper-functions.component';
import { GlobaleDatenService } from 'src/app/services/globaledaten.serice';


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends BaseWrapperFunctionsComponent   {
  enuKunde = enuKunde;
  @Input() buttonData: any;

  constructor(
    public gdService: GlobaleDatenService,
  ) {
    super();
  }

}

@NgModule({
  imports: [  CommonModule ],
  declarations: [ ButtonComponent ],
  exports: [ ButtonComponent ]
})
export class ButtonModule { }