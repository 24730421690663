import { TranslateService } from "@ngx-translate/core";


export enum enuGeschlecht {
  Weiblich = 1,
  Maennlich = 2,
  Institution = 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuGeschlecht {
  export function GetName(e: enuGeschlecht): string {
    switch (e) {
      case enuGeschlecht.Weiblich:
        return 'Weiblich';
      case enuGeschlecht.Maennlich:
        return 'Männlich';
      case enuGeschlecht.Institution:
        return 'Institution';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuGeschlecht, translate: TranslateService): string {
    switch (e) {
      case enuGeschlecht.Weiblich:
        return translate.instant('KURS.WEIBLICH');
      case enuGeschlecht.Maennlich:
        return translate.instant('KURS.MAENNLICH');
      case enuGeschlecht.Institution:
        return translate.instant('KURS.INSTITUTION');
      default:
        return translate.instant('KURS.UNGUELTIGERWERT');
    }
  }
}

export enum enuMitgliedstyp {
  Hauptkontakt = 1,
  Familienmitglied = 2,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuMitgliedstyp {
  export function GetName(e: enuMitgliedstyp): string {
    switch (e) {
      case enuMitgliedstyp.Hauptkontakt:
        return 'Hauptkontakt';
      case enuMitgliedstyp.Familienmitglied:
        return 'Familienmitglied';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuRegistrierungAls {
  Mitglied = 1,
  Nichtmitglied = 2,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuRegistrierungAls {
  export function GetName(e: enuRegistrierungAls): string {
    switch (e) {
      case enuRegistrierungAls.Mitglied:
        return 'Mitglied';
      case enuRegistrierungAls.Nichtmitglied:
        return 'Nichtmitglied';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuRegistrierungAls, translate: TranslateService): string {
    switch (e) {
      case enuRegistrierungAls.Mitglied:
        return translate.instant('REGISTRIERUNGALS.MITGLIED');
      case enuRegistrierungAls.Nichtmitglied:
        return translate.instant('REGISTRIERUNGALS.NICHTMITGLIED');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuMitgliedsPruefung {
  Ok = 1,
  NichtVorhanden = 2,
  NichtAktiv = 3,
  NichtGeprueft = 4,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuMitgliedsPruefung {
  export function GetName(e: enuMitgliedsPruefung): string {
    switch (e) {
      case enuMitgliedsPruefung.Ok:
        return 'Mitgliedschaft geprüft!';
      case enuMitgliedsPruefung.NichtVorhanden:
        return 'Mitgliedschaft existiert nicht!';
      case enuMitgliedsPruefung.NichtAktiv:
        return 'Mitgliedschaft ist nicht aktiv!';
      case enuMitgliedsPruefung.NichtGeprueft:
        return 'Mitgliedschaft nicht überprüft';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuMitgliedsPruefung, translate: TranslateService): string {
    switch (e) {
      case enuMitgliedsPruefung.Ok:
        return translate.instant('REGISTER.MITGLIEDSCHAFTGEPRUEFT');
      case enuMitgliedsPruefung.NichtVorhanden:
        return translate.instant('REGISTER.MITGLIEDSCHAFTEXISTIERTNICHT');
      case enuMitgliedsPruefung.NichtAktiv:
        return translate.instant('REGISTER.MITGLIEDSCHAFTNICHTAKTIV');
      case enuMitgliedsPruefung.NichtGeprueft:
        return translate.instant('REGISTER.MITGLIEDSCHAFTNICHTUEBERPRUEFT');
      default:
        return translate.instant('REGISTER.UNGUELTIGERWERT');
    }
  }
}

export enum enuSteuernummerPruefung {
  Korrekt = 1,
  FormalInkorrekt = 2,
  DatenFalsch = 3,
  DatenFehler = 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuSteuernummerPruefung {
  export function GetName(e: enuSteuernummerPruefung): string {
    switch (e) {
      case enuSteuernummerPruefung.Korrekt:
        return 'Steuernummer ist korrekt';
      case enuSteuernummerPruefung.FormalInkorrekt:
        return 'Steuernummer ist nicht korrekt';
      case enuSteuernummerPruefung.DatenFalsch:
        return 'Steuernummer stimmt nicht mit Daten überein';
      case enuSteuernummerPruefung.DatenFehler:
        return 'Fehler in den Daten';
      default:
        return 'Ungültiger Wert';
    }
  }
  export function GetNameErz(e: enuSteuernummerPruefung): string {
    switch (e) {
      case enuSteuernummerPruefung.Korrekt:
        return 'Steuernummer des Erziehungsberechtigten ist korrekt';
      case enuSteuernummerPruefung.FormalInkorrekt:
        return 'Steuernummer des Erziehungsberechtigten ist nicht korrekt';
      case enuSteuernummerPruefung.DatenFalsch:
        return 'Steuernummer des Erziehungsberechtigten stimmt nicht mit Daten überein';
      case enuSteuernummerPruefung.DatenFehler:
        return 'Fehler in den Daten beim des Erziehungsberechtigten';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuSteuernummerPruefung, translate: TranslateService): string {
    switch (e) {
      case enuSteuernummerPruefung.Korrekt:
        return translate.instant('REGISTER.STEUERNUMMERISTKORREKT');
      case enuSteuernummerPruefung.FormalInkorrekt:
        return translate.instant('REGISTER.STEUERNUMMERISTNICHTKORREKT');
      case enuSteuernummerPruefung.DatenFalsch:
        return translate.instant('REGISTER.STEUERNUMMERSTIMMTNICHTUEBEREIN');
      case enuSteuernummerPruefung.DatenFehler:
        return translate.instant('REGISTER.FEHLERINDENDATEN');
      default:
        return translate.instant('REGISTER.UNGUELTIGERWERT');
    }
  }
  export function GetNameTranslatedErz(e: enuSteuernummerPruefung, translate: TranslateService): string {
    switch (e) {
      case enuSteuernummerPruefung.Korrekt:
        return translate.instant('REGISTER.ERZSTEUERNUMMERKORREKT');
      case enuSteuernummerPruefung.FormalInkorrekt:
        return translate.instant('REGISTER.ERZSTEUERNUMMERNICHTKORREKT');
      case enuSteuernummerPruefung.DatenFalsch:
        return translate.instant('REGISTER.ERZSTEUERNUMMESTIMMTNICHT');
      case enuSteuernummerPruefung.DatenFehler:
        return translate.instant('REGISTER.FEHLERDATENERZIEHUNGSBERECHTIGTEN');
      default:
        return translate.instant('REGISTER.UNGUELTIGERWERT');
    }
  }
}


export enum enuAnrede {
  Frau = 1,
  Herr = 2,
  Keine = 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuAnrede {
  export function GetName(e: enuAnrede): string {
    switch (e) {
      case enuAnrede.Frau:
        return 'Frau';
      case enuAnrede.Herr:
        return 'Herr';
      case enuAnrede.Keine:
        return 'Keine';
      default:
        return 'Ungültiger Wert';
    }

    
  }
  export function GetNameOffice(e: enuAnrede): string {
    switch (e) {
      case enuAnrede.Frau:
        return 'Frau';
      case enuAnrede.Herr:
        return 'Herrn';
      case enuAnrede.Keine:
        return 'Keine';
      default:
        return 'Ungültiger Wert';
    }
  }
  export function GetNameTranslated(e: enuAnrede, translate: TranslateService): string {
    switch (e) {
      case enuAnrede.Frau:
        return translate.instant('ANREDE.FRAU');
      case enuAnrede.Herr:
        return translate.instant('ANREDE.HERR');
      case enuAnrede.Keine:
        return 'Keine';
      default:
        return 'Ungültiger Wert';
    }
  }


  export function GetGeschlecht(e: enuAnrede): enuGeschlecht {
    switch (e) {
      case enuAnrede.Frau:
        return enuGeschlecht.Weiblich;
      case enuAnrede.Herr:
        return enuGeschlecht.Maennlich;
      case enuAnrede.Keine:
        return null;
      default:
        return null;
    }
  }
}

export enum enuProfilMenu {
  PersoenlicheDaten = 1,
  Familie = 2,
  Passwortaendern = 3,
  Mitgliedsbeitrag = 4,
  Kurse = 5,
  Newsletter = 6,
  Benutzerprofiloeschen = 7,
  Abmelden = 8,
  Mitgliedsdaten = 9,
  Funktionen = 10,
  Taetigkeiten = 11,
  Ehrungen = 12,
  Weiterbildung = 13,
  Qualifikationen = 14,
  Mitgliedskarte = 15,
  Bergeerleben = 16,
  meinkletterprofil = 17,
  MeineZahlungsarten = 18,
  Dokumente = 19,
}

export namespace enuProfilMenu {
  export function GetName(e: enuProfilMenu): string {
    switch (e) {
      case enuProfilMenu.PersoenlicheDaten:
        return 'Persönliche Daten';
      case enuProfilMenu.Familie:
        return 'Familie';
      case enuProfilMenu.Passwortaendern:
        return 'Passwort ändern';
      case enuProfilMenu.Mitgliedsbeitrag:
        return 'Mitgliedsbeitrag';
      case enuProfilMenu.Kurse:
        return 'Kurse';
      case enuProfilMenu.Newsletter:
        return 'Newsletter';
      case enuProfilMenu.Benutzerprofiloeschen:
        return 'Benutzerprofi löschen';
      case enuProfilMenu.Abmelden:
        return 'Abmelden';
      case enuProfilMenu.Mitgliedsdaten:
        return 'Mitgliedsdaten';
      case enuProfilMenu.Funktionen:
        return 'Funktionen';
      case enuProfilMenu.Taetigkeiten:
        return 'Tätigkeiten';
      case enuProfilMenu.Ehrungen:
        return 'Ehrungen';
      case enuProfilMenu.Weiterbildung:
        return 'Weiterbildung';
      case enuProfilMenu.Qualifikationen:
        return 'Qualifikationen';
      case enuProfilMenu.Mitgliedskarte:
        return 'Mitgliedskarte';
      case enuProfilMenu.Bergeerleben:
        return 'Bergeerleben';
      case enuProfilMenu.meinkletterprofil:
        return 'mein.kletterprofil';
      case enuProfilMenu.MeineZahlungsarten:
        return 'Meine Zahlungsarten';
      case enuProfilMenu.Dokumente:
        return 'Dokumente';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuWeiterbildungStatus {
  Gueltig = 1,
  NichtGueltig = 2,
  UngueltigerZeitraum = 3,
}

export enum enuZahlungsweise {
  Zahlschein = 1,
  EUZahlschein = 2,
  Lastschrift = 3,
  Kreditkarte = 4,
  Bar=5,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuZahlungsweise {
  export function GetName(e: enuZahlungsweise): string {
    switch (e) {
      case enuZahlungsweise.Zahlschein:
        return 'Zahlschein';
      case enuZahlungsweise.EUZahlschein:
        return 'EU Zahlschein';
      case enuZahlungsweise.Lastschrift:
        return 'Lastschrift';
      case enuZahlungsweise.Kreditkarte:
        return 'Kreditkarte';
      case enuZahlungsweise.Bar:
        return 'Bar';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuZahlungsweise, translate: TranslateService): string {
    switch (e) {
      case enuZahlungsweise.Zahlschein:
        return translate.instant('ENUZAHLUNGSWEISE.ZAHLSCHEIN');
      case enuZahlungsweise.EUZahlschein:
        return translate.instant('ENUZAHLUNGSWEISE.EUZAHLSCHEIN');
      case enuZahlungsweise.Lastschrift:
        return translate.instant('ENUZAHLUNGSWEISE.LASTSCHRIFT');
      case enuZahlungsweise.Kreditkarte:
        return translate.instant('ENUZAHLUNGSWEISE.KREDITKARTE');
      case enuZahlungsweise.Bar:
        return translate.instant('ENUZAHLUNGSWEISE.BAR');
      default:
        return 'Ungültiger Wert';
    }
  }

}

export enum enuKursTyp {
  Kurs = 1,
  Taetigkeit = 2,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursTyp {
  export function GetName(e: enuKursTyp): string {
    switch (e) {
      case enuKursTyp.Taetigkeit:
        return 'Tätigkeit';
      case enuKursTyp.Kurs:
        return 'Kurs';
      default:
        return 'Ungültiger Wert';
    }
  }
  export function GetItemName(e: enuKursTyp): string {
    switch (e) {
      case enuKursTyp.Taetigkeit:
        return 'taetigkeiten';
      case enuKursTyp.Kurs:
        return 'kurse';
      default:
        return 'Ungültiger Wert';
    }
  }
  
}

export enum enuKursTerminstatus {
  Freigegeben	=  1,
  Abgeschlossen= 2,
  Abgesagt = 3,
  InPlanung	= 4,
  Ausgebucht= 5
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursTerminstatus {
  export function GetName(e: enuKursTerminstatus): string {
    switch (e) {
      case enuKursTerminstatus.Freigegeben:
        return 'freigegeben';
      case enuKursTerminstatus.Abgeschlossen:
        return 'abgeschlossen';
      case enuKursTerminstatus.Abgesagt:
        return 'abgesagt';
      case enuKursTerminstatus.InPlanung:
        return 'in Planung';
      case enuKursTerminstatus.Ausgebucht:
        return 'ausgebucht';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuKursTerminstatus, translate: TranslateService): string {
    switch (e) {
      case enuKursTerminstatus.Freigegeben:
        return translate.instant('KURS.FREIGEGEBEN');
      case enuKursTerminstatus.Abgeschlossen:
        return translate.instant('KURS.ABGESCHLOSSEN');
      case enuKursTerminstatus.Abgesagt:
        return translate.instant('KURS.ABGESAGT');
      case enuKursTerminstatus.InPlanung:
        return translate.instant('KURS.INPLANUNG');
      case enuKursTerminstatus.Ausgebucht:
        return translate.instant('KURS.AUSGEBUCHT');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursStatus {
  Aktiviert	=  1,
  Archiviert = 2,

}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursStatus {
  export function GetName(e: enuKursStatus): string {
    switch (e) {
      case enuKursStatus.Aktiviert:
        return 'aktiviert';
      case enuKursStatus.Archiviert:
        return 'archiviert';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursAusgabeInternet {
  NichtAusgeben= 1,
  Ausgeben= 2,
  AnmeldungEigeneMitglieder= 3,
  AnmeldungAlle	= 4,
  AnmeldungEigeneMitgliederInklOrtsstellen	= 5,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursAusgabeInternet {
  export function GetName(e: enuKursAusgabeInternet): string {
    switch (e) {
      case enuKursAusgabeInternet.NichtAusgeben:
        return 'nicht ausgeben';
      case enuKursAusgabeInternet.Ausgeben:
        return 'ausgeben';
      case enuKursAusgabeInternet.AnmeldungEigeneMitglieder:
        return 'eigene Mitglieder';
      case enuKursAusgabeInternet.AnmeldungAlle:
        return 'alle Mitglieder';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursDokumente {
  Dokument = 1,
  Foto = 2,
  Fotogalerie= 3
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursDokumente {
  export function GetName(e: enuKursDokumente): string {
    switch (e) {
      case enuKursDokumente.Dokument:
        return 'Dokument';
      case enuKursDokumente.Foto:
        return 'Foto';
      case enuKursDokumente.Fotogalerie:
        return 'Fotogalerie';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursTeilnehmerkreis {
  Nichtmitglieder= 1,
  Mitglieder= 2,
  Mitarbeiter= 3
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursTeilnehmerkreis {
  export function GetName(e: enuKursTeilnehmerkreis): string {
    switch (e) {
      case enuKursTeilnehmerkreis.Nichtmitglieder:
        return 'Nicht Mitglieder';
      case enuKursTeilnehmerkreis.Mitglieder:
        return 'Mitglieder';
      case enuKursTeilnehmerkreis.Mitarbeiter:
        return 'Mitarbeiter';
      default:
        return 'Ungültiger Wert';
    }
  }
}
export enum enuKursteilnehmertyp {
  Anmeldung= 1,
  Ruecktritt= 2,
  Warteliste= 3,
  Ersatzliste1= 4,
  Ersatzliste2= 5,
  // AnmeldungVorbehalt= 6,
  // Vormerkung= 7,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursteilnehmertyp {
  export function GetName(e: enuKursteilnehmertyp): string {
    switch (e) {
      case enuKursteilnehmertyp.Anmeldung:
        return 'Anmeldung';
      case enuKursteilnehmertyp.Ruecktritt:
        return 'Rücktritt';
      case enuKursteilnehmertyp.Warteliste:
        return 'Warteliste';
      case enuKursteilnehmertyp.Ersatzliste1:
        return 'Ersatzliste 1';
      case enuKursteilnehmertyp.Ersatzliste2:
        return 'Ersatzliste 2';
      // case enuKursteilnehmertyp.AnmeldungVorbehalt:
      //   return 'Anmeldung Vorbehalt';
      // case enuKursteilnehmertyp.Vormerkung:
      //   return 'Vormerkung';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuKursteilnehmertyp, translate: TranslateService): string {
    switch (e) {
      case enuKursteilnehmertyp.Anmeldung:
        return translate.instant('PROFIL.ANMELDUNG');
      case enuKursteilnehmertyp.Ruecktritt:
        return translate.instant('PROFIL.RUECKTRITT');
      case enuKursteilnehmertyp.Warteliste:
        return translate.instant('PROFIL.WARTELISTE');
      case enuKursteilnehmertyp.Ersatzliste1:
        return translate.instant('PROFIL.ERSATZLISTE1');
      case enuKursteilnehmertyp.Ersatzliste2:
        return translate.instant('PROFIL.ERSATZLISTE2');
      default:
        return 'Ungültiger Wert';
    }
  }


}

export enum enuKursVeranstaltungstyp {
  Mitarbeiter= 1,
  Mitglieder= 2,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursVeranstaltungstyp {
  export function GetName(e: enuKursVeranstaltungstyp): string {
    switch (e) {
      case enuKursVeranstaltungstyp.Mitarbeiter:
        return 'B Mitarbeiter';
      case enuKursVeranstaltungstyp.Mitglieder:
        return 'A Mitglieder';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursAnmeldetyp {
  Mitglieder= 1,
  Nichtmitglieder= 2,
  MitgliederUndNichtmitglieder= 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursAnmeldetyp {
  export function GetName(e: enuKursAnmeldetyp): string {
    switch (e) {
      case enuKursAnmeldetyp.Mitglieder:
        return 'Mitglieder';
      case enuKursAnmeldetyp.Nichtmitglieder:
        return 'Nichtmitglieder';
      case enuKursAnmeldetyp.MitgliederUndNichtmitglieder:
        return 'Mitglieder und Nichtmitglieder';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuZustaendigkeitentyp {
  Organisator	= 1,
  Leitung	= 2,
  Team	= 3,
  Unterkunft= 4,
  Ansprechpartner= 5,
  Fahrt	= 6,
  Anmeldung	= 7,
  Bearbeiter= 8
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuZustaendigkeitentyp {
  export function GetName(e: enuZustaendigkeitentyp): string {
    switch (e) {
      case enuZustaendigkeitentyp.Organisator:
        return 'Organisator';
      case enuZustaendigkeitentyp.Leitung:
        return 'Leitung';
      case enuZustaendigkeitentyp.Team:
        return 'Team';
      case enuZustaendigkeitentyp.Unterkunft:
        return 'Unterkunft';
      case enuZustaendigkeitentyp.Ansprechpartner:
        return 'Ansprechpartner';
      case enuZustaendigkeitentyp.Fahrt:
        return 'Fahrt';
      case enuZustaendigkeitentyp.Anmeldung:
        return 'Anmeldung';
      case enuZustaendigkeitentyp.Bearbeiter:
        return 'Bearbeiter';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuSektionstyp {
  Dachverband = 1,
  Sektion = 2,
  Ortsstelle = 3,
  Gremium = 4,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuSektionstyp {
  export function GetName(e: enuSektionstyp): string {
    switch (e) {
      case enuSektionstyp.Dachverband:
        return 'Dachverband';
      case enuSektionstyp.Sektion:
        return 'Sektion';
      case enuSektionstyp.Ortsstelle:
        return 'Ortsstelle';
      case enuSektionstyp.Gremium:
        return 'Gremium';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursgebuehrentyp {
  Teilnehmergebuehr = 1,
  Leistungen = 2,
  Ermaessigungen = 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursgebuehrentyp {
  export function GetName(e: enuKursgebuehrentyp): string {
    switch (e) {
      case enuKursgebuehrentyp.Teilnehmergebuehr:
        return 'Teilnehmergebühr';
      case enuKursgebuehrentyp.Leistungen:
        return 'Leistungen';
      case enuKursgebuehrentyp.Ermaessigungen:
        return 'Ermässigungen';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuMitgliedstatus {
  Aktiv = 1,
  Archiviert = 2,
  Neu = 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuMitgliedstatus {
  export function GetName(e: enuMitgliedstatus): string {
    switch (e) {
      case enuMitgliedstatus.Aktiv:
        return 'Aktiv';
      case enuMitgliedstatus.Archiviert:
        return 'Archiviert';
      case enuMitgliedstatus.Neu:
        return 'Neu';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuMitgliedstatus, translate: TranslateService): string {
    switch (e) {
      case enuMitgliedstatus.Aktiv:
        return translate.instant('PROFIL.AKTIV');
      case enuMitgliedstatus.Archiviert:
        return translate.instant('PROFIL.ARCHIVIERT');
      case enuMitgliedstatus.Neu:
        return translate.instant('PROFIL.NEU');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuFunktionenWbPunkteVergabe {
  Einzeln = 1,
  Jaehrlich = 2,
  Einmalig = 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuFunktionenWbPunkteVergabe {
  export function GetName(e: enuFunktionenWbPunkteVergabe): string {
    switch (e) {
      case enuFunktionenWbPunkteVergabe.Einzeln:
        return 'Einzeln';
      case enuFunktionenWbPunkteVergabe.Jaehrlich:
        return 'Jährlich';
      case enuFunktionenWbPunkteVergabe.Einmalig:
        return 'Einmalig';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuWeiterbildungZahlung {
  Sektion = 1,
  Teilnehmer = 2
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuWeiterbildungZahlung {
  export function GetName(e: enuWeiterbildungZahlung): string {
    switch (e) {
      case enuWeiterbildungZahlung.Teilnehmer:
        return 'Teilnehmer';
      case enuWeiterbildungZahlung.Sektion:
        return 'Zahlung durch Sektion und LL';
      default:
        return 'Ungültiger Wert';
    }
  }

  export function GetNameTranslated(e: enuWeiterbildungZahlung, translate: TranslateService): string {
    switch (e) {
      case enuWeiterbildungZahlung.Teilnehmer:
        return translate.instant('KURS.TEILNEHMER');
      case enuWeiterbildungZahlung.Sektion:
        return translate.instant('KURS.ZAHLUNGSEKTIONUNDLL');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursAnmeldungbestaetigung {
  Ja = 0,
  Nein = 1
}

export enum enuKurseZustaendigkeitentyp {
  Organisator = 1,
  Leitung = 2,
  Team = 3,
  Unterkunft = 4,
  Ansprechpartner = 5,
  Fahrt = 6,
  Anmeldung = 7,
  Bearbeiter = 8
}

export enum enuDatenfreigabe {
  Keine = 0,
  AdressePrivat = 1,
  TelefonPrivat = 2,
  EMailPrivat = 4,
  AdresseGeschaeftlich = 8,
  TelefonGeschaeftlich = 16,
  EMailGeschaeftlich = 32,
  Mobil = 64,
  Fax = 128,
  Internet = 256,
  Foto = 512,
}

export enum enuConfirmReturncode {
  ja = 1,
  nein = 2,
  abbrechen = 3,
}

export enum enuKursZahlungsart {
  Bar = 1,
  Kreditkarte = 2,
  Zahlschein = 4,
  Keine = 0,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursZahlungsart {
  export function GetName(e: enuKursZahlungsart): string {
    switch (e) {
      case enuKursZahlungsart.Bar:
        return 'Bar';
      case enuKursZahlungsart.Kreditkarte:
        return 'Kreditkarte';
      case enuKursZahlungsart.Zahlschein:
        return 'Zahlschein';
      case enuKursZahlungsart.Keine:
        return 'Keine';
      default:
        return '';
    }
  }

  export function GetNameTranslated(e: enuKursZahlungsart, translate: TranslateService): string {
    switch (e) {
      case enuKursZahlungsart.Bar:
        return translate.instant('ENUZAHLUNGSWEISE.BAR');
      case enuKursZahlungsart.Kreditkarte:
        return translate.instant('ENUZAHLUNGSWEISE.KREDITKARTE');
      case enuKursZahlungsart.Zahlschein:
        return translate.instant('ENUZAHLUNGSWEISE.ZAHLSCHEIN');
      case enuKursZahlungsart.Keine:
        return translate.instant('ENUZAHLUNGSWEISE.KEINE');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursteilnehmerZahlungsart {
  Bar = 1,
  Kreditkarte = 2,
  Zahlschein = 3,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuKursteilnehmerZahlungsart {
  export function GetName(e: enuKursteilnehmerZahlungsart): string {
    switch (e) {
      case enuKursteilnehmerZahlungsart.Bar:
        return 'Bar';
      case enuKursteilnehmerZahlungsart.Kreditkarte:
        return 'Kreditkarte';
      case enuKursteilnehmerZahlungsart.Zahlschein:
        return 'Zahlschein';
      default:
        return '';
    }
  }

  export function GetTeilnehmerZahlung(e: enuKursteilnehmerZahlungsart): enuKursZahlungsart {
    switch (e) {
      case enuKursteilnehmerZahlungsart.Bar:
        return enuKursZahlungsart.Bar;
      case enuKursteilnehmerZahlungsart.Kreditkarte:
        return enuKursZahlungsart.Kreditkarte;
      case enuKursteilnehmerZahlungsart.Zahlschein:
        return enuKursZahlungsart.Zahlschein;
      default:
        return null;

    }
  }
}

export enum enuZahlungsart {
  BarZahlung = 1,
  BankZahlung = 2,
  Freccia = 3,
  UeberweisungLL = 4,
  Dauerauftrag = 5,
  BankZahlungLL = 6,
  Abbucher = 7,
  UeberweisungSek = 8,
  EPaymentSek = 9,
  EPaymentLL = 10,
}

export enum enuZahlungsweg {
  Abbucher = 1,
  Freccia = 2,
  AbbucherReferenz = 3,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuZahlungsweg {
  export function GetName(e: enuZahlungsweg): string {
    switch (e) {
      case enuZahlungsweg.Abbucher:
        return 'Lastschrift';
      case enuZahlungsweg.Freccia:
        return 'Zahlschein';
      case enuZahlungsweg.AbbucherReferenz:
        return 'Lastschrift';
      default:
        return '';
    }
  }

  export function GetNameTranslated(e: enuZahlungsweg, translate: TranslateService): string {
    switch (e) {
      case enuZahlungsweg.Abbucher:
        return translate.instant('PROFIL.LASTSCHRIFT');
      case enuZahlungsweg.Freccia:
        return translate.instant('PROFIL.ZAHLSCHEIN');
      case enuZahlungsweg.AbbucherReferenz:
        return translate.instant('PROFIL.LASTSCHRIFT');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuZahlungswegVorgang {
  Mitglied = 1,
  Familie = 2,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuZahlungswegVorgang {
  export function GetName(e: enuZahlungswegVorgang): string {
    switch (e) {
      case enuZahlungswegVorgang.Mitglied:
        return 'Eigene Lastschrift erstellen';
      case enuZahlungswegVorgang.Familie:
        return 'Lastschrift der Familie erstellen';
      default:
        return '';
    }
  }

  export function GetNameTranslated(e: enuZahlungswegVorgang, translate: TranslateService): string {
    switch (e) {
      case enuZahlungswegVorgang.Mitglied:
        return translate.instant('PROFIL.LASTSCHRIFTERSTELLEN');
      case enuZahlungswegVorgang.Familie:
        return translate.instant('PROFIL.LASTSCHRIFTFAMILIE');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuSektionsBankkontotyp {
  Beitragskonto = 1,
  Kurskonto = 2,
  Allgemeineskonto = 3,
}

export enum enuSektionsBankkontoart {
  Bankkonto = 1,
  Sparbuch = 2,
  Bar = 3,
  Stripe = 4,
  SIApay = 5,
}

export enum enuZahlungsgrund {
  Mitgliedsbeitrag = 1,
  Spende = 2,
  Kursbeitrag = 3,
  Storno = 4,
  Verleih = 5,
  Benutzerdefiniert1 = 6,
  Benutzerdefiniert2 = 7,
  Benutzerdefiniert3 = 8,
  Benutzerdefiniert4 = 9,
  Benutzerdefiniert5 = 10,
  Verkauf = 11,
}

export enum enuEPaymentArt {
  Stripe = 1,
  SIApay = 2,
}

export enum enuEPaymentTyp {
  Mitgliedsbeitrag = 1,
  Veranstaltung = 2,
}


export enum enuEPaymentStatus {
  InBearbeitung = 1,
  Bezahlt = 2,
  Annulliert = 3,
  Fehler = 4,
}

export enum enuEPaymentUrlType {
  Success = 1,
  Cancel = 2,
}

export enum enuControl {
  Textbox = 1,
  Checkbox = 2,
  Radiobutton = 3,
  Combobox = 4,
}

export enum enuFamilientyp {
  Partner = 1,
  Erziehungsberechtigter = 2,
  Kind = 3,
  HaushaltLebend = 4,
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace enuFamilientyp {
  export function GetName(e: enuFamilientyp): string {
    switch (e) {
      case enuFamilientyp.Partner:
        return 'Partner';
      case enuFamilientyp.Erziehungsberechtigter:
        return 'Erziehungsberechtigter';
      case enuFamilientyp.Kind:
        return 'Kind';
      case enuFamilientyp.HaushaltLebend:
        return 'HaushaltLebend';
      default:
        return '';
    }
  }

  export function GetNameTranslated(e: enuFamilientyp, translate: TranslateService): string {
    switch (e) {
      case enuFamilientyp.Partner:
        return translate.instant('PROFIL.PARTNER');
      case enuFamilientyp.Erziehungsberechtigter:
        return translate.instant('PROFIL.ERZIEHUNGSBERECHTIGTER');
      case enuFamilientyp.Kind:
        return translate.instant('PROFIL.KIND');
      case enuFamilientyp.HaushaltLebend:
        return translate.instant('PROFIL.HAUSHALTLEBEND');
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuGeschlechtSteuernummer {
  Maennlich = 0,
  Weiblich = 1
}

export enum enuDSGVOVertragTyp {
  Benutzer = 1,
  Website = 2,
  Newsletter = 3,
}

export enum enuFilter {
  Sektion = 1,
  Name = 2,
  Von = 4,
  Bis = 8,
  Zielgruppe = 16,
  Aktivitaet = 32,
  Alter = 64,

  Default = 127,
}

export enum enuVeranstaltungstyp {
  Nichtmitglieder= 1,
  Mitglieder= 2,
  Mitarbeiter= 3,
  Alle= 4,
}

export namespace enuVeranstaltungstyp {
  export function GetName(e: enuVeranstaltungstyp): string {
    switch (e) {
      case enuVeranstaltungstyp.Alle:
        return '[Alle]';
      case enuVeranstaltungstyp.Nichtmitglieder:
        return 'Nicht Mitglieder';
      case enuVeranstaltungstyp.Mitglieder:
        return 'Mitglieder';
      case enuVeranstaltungstyp.Mitarbeiter:
        return 'Mitarbeiter';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKursstatus {
  Aktiviert = 1,
  Archiviert = 2,
}

export enum enuKursablaeufetyp {
  Vorbesprechung= 1,
  InfoAbend= 2,
  Abfahrt= 3,
  Rueckkehr= 4,
  Intern= 5,
  Sonstiges= 6,
  Anmeldeschluss= 7,
  Treffpunkt= 8,
  Ankunft= 9,
}

export namespace enuKursablaeufetyp {
  export function GetName(e: enuKursablaeufetyp): string {
    switch (e) {
      case enuKursablaeufetyp.Vorbesprechung:
        return 'Vorbesprechung';
      case enuKursablaeufetyp.InfoAbend:
        return 'Info Abend';
      case enuKursablaeufetyp.Abfahrt:
        return 'Abfahrt';
      case enuKursablaeufetyp.Rueckkehr:
        return 'Rückkehr';
      case enuKursablaeufetyp.Intern:
        return 'Intern';
      case enuKursablaeufetyp.Sonstiges:
        return 'Sonstiges';
      case enuKursablaeufetyp.Anmeldeschluss:
        return 'Anmeldeschluss';
      case enuKursablaeufetyp.Treffpunkt:
        return 'Treffpunkt';
      case enuKursablaeufetyp.Ankunft:
        return 'Ankunft';
      default:
        return 'Ungültiger Wert';
    }
  }
}

export enum enuKurseDokumenttyp {
  Dokument = 1,
  Foto = 2,
  Fotogalerie = 3,
}



export class EnumValues {
  static getNamesAndValues(e: any) {
    return this.getNames(e).map(_name => { return { name: _name, value: e[_name] as number }; });
  }

  static getTextAndValuesFromList(e: any, values: any[]) {
    const arr = new Array;
    for (const _k of values) {
      arr.push({text: e.GetName(_k), value: _k});
    }
    return arr;
  }

  // Ich denke, dass diese Funktion die Funktion getTextAndValuesFromList ersetzten sollte (Mark).
  static getTextAndValuesFromListSorted(e: any, values: any[]) {
    const arr = new Array;
    for (const _k of this.getValuesSorted(e)) {
      if (values.indexOf(_k) !== -1) {
        arr.push({text: e.GetName(_k), value: _k});
      }
    }
    return arr;
  }

  static getTextAndValues(e: any) {
    // return this.getValues(e).map(_k => { return { text: 'asd', value: _k as number }; });
    const arr = new Array;
    for (const _k of this.getValues(e)) {
      arr.push({text: e.GetName(_k), value: _k});
    }
    return arr;
  }

  static getTextAndValuesTranslated(e: any, translate: TranslateService) {
    // return this.getValues(e).map(_k => { return { text: 'asd', value: _k as number }; });
    const arr = new Array;
    for (const _k of this.getValues(e)) {
      arr.push({text: e.GetNameTranslated(_k, translate), value: _k});
    }
    return arr;
  }
  static getTextAndValuesDruckTranslated(e: any, translate: TranslateService) {
    // return this.getValues(e).map(_k => { return { text: 'asd', value: _k as number }; });
    const arr = new Array;
    for (const _k of this.getValues(e)) {
      arr.push({text: e.GetNameDruckTranslated(_k, translate), value: _k});
    }
    return arr;
  }

  static getValuesSorted(e: any) {
    if (e.hasOwnProperty('GetSortedList')) {
      return e.GetSortedList();
    }

    return this.getValues(e);
  }

  static getTextAndValuesSorted(e: any) {
    // return this.getValues(e).map(_k => { return { text: 'asd', value: _k as number }; });
    if (e.hasOwnProperty('GetSortedList')) {
      return this.getTextAndValuesFromList(e, e.GetSortedList());
    }
    const arr = new Array;
    for (const _k of this.getValues(e)) {
      arr.push({text: e.GetName(_k), value: _k});
    }
    return arr;
  }

  static getEnumTextAndValues(e: any) {
    return this.getNames(e).map(_name => { return { text: _name, value: e[_name] as number }; });
  }

  static getNames(e: any) {
    return this.getObjectValues(e).filter(v => typeof v === 'string') as string[];
  }

  static getValues(e: any) {
    return this.getObjectValues(e).filter(v => typeof v === 'number') as number[];
  }

  private static getObjectValues(e: any): (number | string)[] {
    return Object.keys(e).map(k => e[k]);
  }
}

