<div *ngIf='!showRegisterMessage' class="inner-wrap" style="background-color:#f4f4f4; padding:20px;">
  <div class="main">
      <div class="wpb_text_column wpb_content_element ">
        <div class="wpb_wrapper">
            <h3>Newsletter</h3>
            <div class="divider-wrap" data-alignment="default">
                <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%" data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
            </div>
            <!-- <p style="padding-bottom:0px;">Bleib informiert, mit dem Alpenverein Newsletter</p> -->
            <p style="padding-bottom:0px;">{{'NEWSLETTER.BLEIBINFORMIERT' |translate}}</p>
            <br>
          </div>
      </div>

      <div id="form-container">
        <form id="newlstter">
          <dx-form #formNewsletter  [(formData)]="daten" [colCount]="5" [labelLocation]="'top'" [showValidationSummary]="true"  validationGroup="newsletterValidator">
            <dxi-item [dataField]="'email'" type="required" [colSpan]="3" >
              <dxo-label [text]="'E-Mail Adresse'"></dxo-label>
                <dxi-validation-rule  type="required" message="E-Mail Adresse eingeben"></dxi-validation-rule>
                <dxi-validation-rule type="email" message="Die E-Mail Adresse ist ungültig"></dxi-validation-rule>
                <!-- <dxi-validation-rule type="async" message="Die E-Mmail Adresse ist bereits registriert" [validationCallback]="asyncEmailValidation"></dxi-validation-rule> -->
            </dxi-item>
            <dxi-item itemType="empty" [colSpan]="2" ></dxi-item>
            <dxi-item [dataField]="'anrede'" type="required"
              editorType="dxSelectBox" valueExpr="value" displayExpr="text"
              [editorOptions]="{dataSource: anrede, valueExpr: 'value', displayExpr: 'text', placeholder: 'wählen...'}">
              <dxo-label text="Anrede"></dxo-label>
              <!-- <dxi-validation-rule type="required" message="Anrede auswählen"></dxi-validation-rule> -->
            </dxi-item>
            <dxi-item [dataField]="'vorname'" type="required" [colSpan]="2">
              <dxo-label text="Vorname"></dxo-label>
              <dxi-validation-rule type="required" message="Vorname eingeben"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="50" message="Vorname darf maximal 50 Zeichen lang sein"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [dataField]="'nachname'" type="required" [colSpan]="2">
              <dxo-label text="Nachname"></dxo-label>
              <dxi-validation-rule type="required" message="Nachname eingeben"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="50" message="Nachname darf maximal 50 Zeichen lang sein"></dxi-validation-rule>
            </dxi-item>
            <!-- Datenschutz -->
              <dxi-item [colSpan]="5" horizontalAlignment="right" caption="Datenschutz"  editorType="dxCheckBox" type="required" [editorOptions]="{text: 'Ich stimme den Datenschutzbestimmungen zu',value: false}">
                <dxi-validation-rule  type="compare" [comparisonTarget]="datenschutzComparison" message="Du mußt die Datenschutzbestimmungen akzeptieren"></dxi-validation-rule>
              </dxi-item>
              <dxi-item [colSpan]="2" itemType="simple" >
                  <a href="javascript: void(0);" (click)="openDSVG()">Datenschutzbestimmungen</a><span class="dx-field-item-required-mark">&nbsp;*</span>
              </dxi-item>

            <!-- anmelden Button -->
            <dxi-item itemType="simple" [colSpan]="3">
              <div style="text-align: right;">
                  <dx-button text="Newsletter anmelden" (onClick)="okLogin($event)" [template]="'buttonTemplate'" validationGroup="newsletterValidator">
                      <div *dxTemplate="let buttonData of 'buttonTemplate'" class="nectar-cta loaded" data-color="accent-color" data-using-bg="false" data-style="arrow-animation" data-display="block" data-alignment="right" data-text-color="custom" style="border:none; padding:0; padding-top:8px;">
                          <h5 style="color: #ffffff;">
                              <span class="link_wrap" style="padding-top: 10px; padding-right: 15px; padding-bottom: 10px; padding-left: 10px;">
                          <span class="link_text" >
                          <span class="text">{{buttonData.text}}</span>
                              <svg class="next-arrow" width="20px" height="25px" viewBox="0 0 50 80" xml:space="preserve">
                          <polyline stroke="#ffffff" stroke-width="9" fill="none" stroke-linecap="round" stroke-linejoin="round" points="0, 0 45, 40 0, 80"></polyline>
                          </svg>
                              <span class="line" style="background-color: #ffffff;"></span>
                              </span>
                              </span>
                          </h5>
                      </div>
                  </dx-button>
              </div>
          </dxi-item>
          

          </dx-form>
        </form>
    </div>
  </div>
</div>

<div *ngIf="showRegisterMessage" class="inner-wrap formbackground">
  <div class="wpb_text_column wpb_content_element ">
      <div class="wpb_wrapper">
          <h3>Newsletterbenutzer angelegt</h3>
          <div class="divider-wrap" data-alignment="default">
              <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%" data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
          </div>
          <p></p>
      </div>
  </div>
  <p>Newsletterbenutzer wurde erfolgreich angelegt</p>
</div>

