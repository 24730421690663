import { AppConfig } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from './../services/auth.service';
// import { EnumValues } from './../globals/enumerationen';
// import { enuGridLayouts } from './../globals/globalsservice';
// import { GlobaleDatenService } from './../services/globaledaten.service';
import { alert } from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as funktionen from '../globals/functions';
import validationEngine from 'devextreme/ui/validation_engine';


@Component({
  selector: 'app-resetpassword2-component',
  templateUrl: 'resetpassword2.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetPassword2Component implements OnInit {
  myForm: UntypedFormGroup;
  public username: string;
  public kennwortneu: string;
  public kennwortneu2: string;
  kennwortPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\s\d])(?=.*[a-zA-Z]).{8,}$/;

  public loadingVisible = false;

  access_token: string;
  refresh_token: string;

  backgroundImage = './../../assets/img/bergkette2.png';
  public myAppConfig = AppConfig;

  resetButtonOptions: any;
  cancelButtonOptions: any;
  passwordMode: string;
  passwordMode2: string;
  passwordfieldOptions: any;
  passwordfieldOptions2: any;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    // public gdService: GlobaleDatenService,
    public translate: TranslateService
  ) {


    if (AppConfig.settings.testversion) {
      this.backgroundImage = './../../assets/img/bergkette2_testsystem.png';
    } else {
      this.backgroundImage = './../../assets/img/bergkette2.png';
    }

    this.passwordMode = 'password';
    this.passwordMode2 = 'password';

    this.passwordfieldOptions = this.passwordOptions();
    this.passwordfieldOptions2 = this.passwordOptions2();

  }

  get backgroundImageUrl() {
    if (this.backgroundImage) {
      return `url("${this.backgroundImage}")`;
    }

    return null;
  }

  passwordOptions() {
    return {
      mode: this.passwordMode,
      buttons: [{
          name: 'password',
          location: 'after',
          options: {
              type: 'default',
              height: '37px',
              elementAttr: {
                class: 'password',
              },
              icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
              onClick: () => {
                this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
                this.passwordfieldOptions = this.passwordOptions();
              }
          }
      }],

    };
  }

  passwordOptions2() {
    return {
      mode: this.passwordMode2,
      buttons: [{
          name: 'password',
          location: 'after',
          options: {
              type: 'default',
              height: '37px',
              elementAttr: {
                class: 'password',
              },
              icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
              onClick: () => {
                this.passwordMode2 = this.passwordMode2 === 'text' ? 'password' : 'text';
                this.passwordfieldOptions2 = this.passwordOptions2();
              }
          }
      }],

    };
  }

  removeFilterValue(storageKey) {
    // console.log('removeFilterValue', storageKey);
    const storageItem = JSON.parse(localStorage.getItem(storageKey));
    if (storageItem != null) {
      for (const item of storageItem.columns) {
        if (item.hasOwnProperty('filterValues')) {
          delete item.filterValues;
        } else if (item.hasOwnProperty('filterValue')) {
          delete item.filterValue;
        }
      }
      if (storageItem.filterValue) {
        delete storageItem.filterValue;
      }
      if (storageItem.pageIndex) {
        // console.log('removeFilterValue pageIndex', storageItem.pageIndex);
        storageItem.pageIndex = 0;
      }
      localStorage.setItem(storageKey, JSON.stringify(storageItem));
    }
  }
  // removeFilterValues() {
  //   const grids = EnumValues.getNames(enuGridLayouts);
  //   for ( const g of grids) {
  //     this.removeFilterValue(g);
  //   }
  // }
  public onReset(e) {
    // console.log('onSignin ');
    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {
      this.loadingVisible = true;
      this.authService.passwordreset2(
        { id: this.route.snapshot.params.id, password: this.myForm['password']})
      .then((res) => {
        // console.log('onSignin 1');
        this.loadingVisible = false;
        alert( this.translate.instant('RESETPASSWORD2.PASSWORT_ZURUECKGESETZT'), this.translate.instant('RESETPASSWORD2.HINWEIS')).then(() => {
          if (this.authService.currentUser != null) {
            this.router.navigate(['/profil']);
          } else {
            this.router.navigate(['/login']);
          }
        });
  
        return;
  
      })
      .catch((error) => {
        console.log('onSignin 2', error);
        this.loadingVisible = false;
        if (error.statusText !== 'Unknown Error') {
          alert( funktionen.fixUmlaute(error.statusText), this.translate.instant('RESETPASSWORD2.FEHLER')).then(() => {
            // window.location.reload();
          });
        } else {
          alert(this.translate.instant('RESETPASSWORD2.FEHLER_AUFGETRETEN'), this.translate.instant('RESETPASSWORD2.FEHLER')).then(() => {
            // window.location.reload();
          });
        }
      });
    }
  }

  public onCancel() {
    if (this.authService.currentUser != null) {
      this.router.navigate(['/profil']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): any {
    // console.log(JSON.stringify(environment));

    console.log(this.route.snapshot);


    this.myForm = this.fb.group({
      password: ['', Validators.required],
    });
    this.resetButtonOptions = {
      icon: 'arrowright',
      text: this.translate.instant('RESETPASSWORD2.ZURUECKSETZEN'),
      type: 'success',
      useSubmitBehavior: false,
      onClick: (e) => {
        this.onReset(e);
      }
    };
    this.cancelButtonOptions = {
      icon: 'clear',
      text: this.translate.instant('RESETPASSWORD2.ABBRECHEN'),
      type: 'button',
      useSubmitBehavior: false,
      onClick: (e) => {
        this.onCancel();
      }
    };

  }

  passwordComparison = () => {
    return this.myForm['password'];
  }

}
