// import { DxValidationGroup } from 'devextreme/ui/validation_group';
import { Register } from './register.model';
import { enuAnrede, enuGeschlecht, enuMitgliedsPruefung, enuRegistrierungAls, enuSteuernummerPruefung, enuGeschlechtSteuernummer, enuDSGVOVertragTyp } from './../globals/enumerationen';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent} from 'devextreme-angular';
import { RegisterService } from './register.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { alert, custom } from 'devextreme/ui/dialog';
import * as moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { SteuernummerService } from '../services/steuernummer.service';
import { Kontakt } from './kontakt.model';
import * as funktionen from '../globals/functions';
import Editor from 'devextreme/ui/editor/editor';
import { GlobaleDatenService } from '../services/globaledaten.serice';
import ThreadedLock from 'threaded-lock';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRouteChangeService } from '../services/app-route-change.service';
import { enuEingabeMitgliedsnummer, enuKunde } from '../app-config.model';
import { AppConfig } from '../app.config';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-register',
  providers: [ RegisterService ],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild('formKontakt', { static: false }) formKontakt: DxFormComponent;
  // @ViewChild('formKontaktErzServer', { static: false }) formKontaktErzServer: DxFormComponent;
  @ViewChild('formKontaktErz', { static: false }) formKontaktErz: DxFormComponent;
  @ViewChild('formZugangsdaten', { static: false }) formZugangsdaten: DxFormComponent;

  mitgliedsnummerPattern: any = '^\\d+$';

  daten: Register;
  enuKunde = enuKunde;
  enuEingabeMitgliedsnummer = enuEingabeMitgliedsnummer;
  AppConfig = AppConfig; 
  web_nationen: any[];
  web_geburtsorte: any[];
  web_orte: any[];

  anrede: any[];
  anredeErz: any[];
  anredeSelectOptions: any;
  anredeErzSelectOptions: any;

  vornameOptions: any;
  vornameErzOptions: any;

  nachnameOptions: any;
  nachnameErzOptions: any;

  geburtsdatumOptions: any;
  geburtsdatumOptionsErz: any;
  minDate = new Date('1900-01-01');
  today = new Date();

  geburtsortSelectOptions: any;
  geburtsortErzSelectOptions: any;
  geburtsorteDataSource: any;
  geburtsorte: any;

  provinzSelectOptions: any;
  provinzen: any[];

  ortSelectOptions: any;

  steuernummerOptions: any;
  steuernummerErzOptions: any;

  mitgliedsnummerOptions: any; // Mit Sektion
  mitgliedsnummerOptions2: any; // Ohne Sektion
  mitgliedsnummerErzOptions: any;  // Mit Sektion
  mitgliedsnummerErzOptions2: any; // Ohne Sektion

  kartennummerOptions: any;
  kartennummerErzOptions: any;
  
  countries: any;
  landSelectOptions: any;
  land2SelectOptions: any;
  landErzSelectOptions: any;

  maxDate: Date = new Date();
  namePattern: any = /^[^0-9]+$/;
  kennwortPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\s\d])(?=.*[a-zA-Z]).{8,}$/;
  benutzerPattern: string = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ.-@_';

  buttonSubmitOptions: any;

  removErzButtonOptions: any;
  bezeichnungErZ: string;
  bezeichnungErZNummer: string;

  registrierungalsData: any[];
  registrierungalsErzData: any[];

  newsletterOptions: any;
  newsletterBestellen: boolean;
  datenschutzOptions: any;

  buttonCheckMitgliedOptions: any;
  buttonCheckSteuerOptions: any;
  buttonInfoMitgliedsnummer: any;
  buttonInfoKontaktnummer: any;
  buttonInfoKarte: any;
  calculatedSteuernummer: string;
  interessen: string[];

  registerAlsSelectOptions: any;
  registerAlsErzSelectOptions: any;
  istGeprueftesMitglied: boolean;
  istGeprueftesMitgliedErz: boolean;
  mitgliedsPruefungTxt: string;
  mitgliedsPruefungTxtErz: string;

  mitgliedsnummerPopupVisible = false;
  karteninfoPopupVisible = false;
  gesendetPopupVisible = false;
  registrierungals: enuRegistrierungAls;
  registrierungalsErz: enuRegistrierungAls;
  hatErzberechtigten = false;
  hatErzberechtigtenServer = false;
  regMitglied = false;
  regMitgliedErz = false;

  // pruefenErzButtonOptions: any;
  showRegisterMessage = false;
  buttonInfoDatenschutz: any;

  enuRegistrierungAls = enuRegistrierungAls;
  web_nationenStore: DataSource;
  web_nationenStore2: DataSource;
  web_geburtsorteStore: DataSource;
  web_orteStore: DataSource;
  enableSubmitButton = false;
  pruefenErzButtonOptions: { icon: string; text: string; type: string; useSubmitBehavior: boolean; validationGroup: string; onClick: (e: any) => void; };
  pruefenButtonOptions: { icon: string; text: string; type: string; useSubmitBehavior: boolean; validationGroup: string; onClick: (e: any) => void; };
  buttonWeiterOptions: { icon: string; text: string; type: string; useSubmitBehavior: boolean; validationGroup: string; onClick: (e: any) => void; };
  userfieldOptions: any;
  passwordMode: string;
  passwordMode2: string;
  passwordfieldOptions: any;
  passwordfieldOptions2: any;
  sendClicked: boolean = false;
  // limitierungNM: boolean;
  public loadingVisible = false;
  linkzuFamilie: any = false;

  newsletter = AppConfig.kunde_settings.newsletter;
  kontakte = AppConfig.kunde_settings.kontakte;
  kontaktnummer = AppConfig.kunde_settings.kontakte && AppConfig.kunde_settings.kontaktnummer;
  mitgliedsnummer = AppConfig.kunde_settings.mitgliedsnummer;
  kreditkarte = AppConfig.kunde_settings.kreditkarte;
  eingabeMitgliedsnummer = null;

  kontaktnummerPopupVisible = false;
  hatNummer: boolean = false;
  hatNummerOptions: any;
  hatNummerErz: boolean = false;
  hatNummerOptionsErz: any;

  mask= '###*##';
  maskErz= '###*##';
  maskfixed= false;
  maskfixedErz= false;

  dsgvoliste: any[];
  mgd_id: any;
  registerdaten: any[];
  setkartennummer: boolean;
  istBereitsKontakt: boolean;
  vereinsdatenSubscription: Subscription;
  emailsendto: string;


  constructor(
    public Service: RegisterService,
    public steuerService: SteuernummerService,
    public gdService: GlobaleDatenService,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public appRouteChangeService: AppRouteChangeService,
    private route: ActivatedRoute,

  ) {
    if(authService.loggedIn()) {
      this.router.navigate(['/profil']);
    }
    this.web_nationen = new Array();
    this.web_geburtsorte = new Array();

    this.registrierungals = enuRegistrierungAls.Nichtmitglied;
    this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;

    this.istGeprueftesMitglied = false;
    this.istGeprueftesMitgliedErz = false;
    this.sendClicked = false;

    // this.bezeichnungErZ = 'Erziehungsberechtigte/r';
    this.bezeichnungErZ = this.translate.instant('REGISTER.ERZIEHUNGSBERECHTIGTE_R');

    // this.bezeichnungErZNummer = 'Mitgliedsnummer der/des Erziehungsberechtigten';
    if (this.kontaktnummer) {
      this.bezeichnungErZNummer = this.translate.instant('REGISTER.KONTAKTNUMMERDESERZIEHUNGSBERECHTIGTEN');
    } else {
      this.bezeichnungErZNummer = this.translate.instant('REGISTER.MITGLIEDSNUMMERDESERZIEHUNGSBERECHTIGTEN');
    }

    this.mitgliedsPruefungTxt = enuMitgliedsPruefung.GetNameTranslated(enuMitgliedsPruefung.NichtGeprueft, this.translate);
    this.mitgliedsPruefungTxtErz = enuMitgliedsPruefung.GetNameTranslated(enuMitgliedsPruefung.NichtGeprueft, this.translate);

    this.anrede = this.Service.getAnrede();
    this.anredeErz = this.Service.getAnrede();

    this.provinzen = this.gdService.getProvinzen();

    this.passwordMode = 'password';
    this.passwordMode2 = 'password';

    this.passwordfieldOptions = this.passwordOptions();
    this.passwordfieldOptions2 = this.passwordOptions2();

    // this.registrierungals = null;
    this.registrierungalsData = Service.getRegistrierungals();
    this.registrierungalsErzData = Service.getRegistrierungals();
    this.maxDate = new Date(this.maxDate.setFullYear(this.maxDate.getFullYear() - 18));
    this.newsletterBestellen = false;

    this.validateGebdatum = this.validateGebdatum.bind(this);
    this.validateGebdatumErz = this.validateGebdatumErz.bind(this);
    this.validateSteuernummer = this.validateSteuernummer.bind(this);
    this.validateDatenschutz = this.validateDatenschutz.bind(this);
    this.validateSteuernummer2 = this.validateSteuernummer2.bind(this);

    this.benutzernameKontrolle = this.benutzernameKontrolle.bind(this);


    this.daten = new Register(this.gdService.getNewsletter());
    this.daten.newsletter = false;
    if(!this.kontakte && this.mitgliedsnummer) {
      this.registrierungals = enuRegistrierungAls.Mitglied;
      this.registrierungalsErz = enuRegistrierungAls.Mitglied;
      this.daten['registrierungals'] = enuRegistrierungAls.Mitglied;
      this.daten['registrierungalsErz'] = enuRegistrierungAls.Mitglied;
      // this.regMitglied = true;
      this.hatNummer = true;
    }
  }

  ngOnDestroy(): void {
    if(this.vereinsdatenSubscription) this.vereinsdatenSubscription.unsubscribe();
  }


  ngOnInit(): void {

    const datenpromises = [];
    // old register

    this.Service.authservice.getUrl('/portal/dsgvoliste').then((data: any[]) => {
      this.dsgvoliste = data;
    });

    // this.limitierungNM = false;
    // if (localStorage.getItem('locale') !== 'de') {
    //   this.limitierungNM = true;
    // }
    // this.daten.nachname = 'Zimmerhofer';
    // this.daten.vorname =  'Marcel';
    // this.daten.gebdatum =  new Date('08.15.1999');
    // this.daten.geburtsort = 'Bruneck';
    // this.daten.steuernummer =  null;
    // this.daten.steuernummer = 'ZMMMCL99M15B220W';
    // this.daten.email = 'uhuhu@okok.com';
    // this.daten.passwort = '123456Aa!';
    // this.daten.passwort2 = '123456Aa!';
    // this.daten.mitgliedsnr = '01003858';

    
    this.vereinsdatenSubscription = this.gdService.vereinsdatenAktualisiert.subscribe((data) => {
      if(this.gdService.eingabeMitgliedsnummer === enuEingabeMitgliedsnummer.MitSektion) {
        this.mask= '###*##';
        this.maskErz= '###*##';
      } else {
        this.mask= '#####';
        this.maskErz= '#####';
      }
      this.karteNnummerOptions();
    })
    if(this.gdService.eingabeMitgliedsnummer === enuEingabeMitgliedsnummer.MitSektion) {
      this.mask= '###*##';
      this.maskErz= '###*##';
    } else {
      this.mask= '#####';
      this.maskErz= '#####';
    }

    // Nationen laden

    this.userfieldOptions = {
      // onKeyPress: function(e){
      //   return this.gdService.onKeyDown(e);
      // },
      onKeyDown: (e) => {
        // if(this.gdService.istKunde(enuKunde.AVS)) {
          return this.gdService.onKeyDown(e);
        // }

      },
      onFocusIn: () => {
        // this.captchaVisible = true;
      }
    };
    datenpromises.push(this.gdService.getNationen().then((data) => {

      this.web_nationen = data;
      this.web_nationenStore = new DataSource({
        store: {
          data,
          type: 'array'
        },
        paginate: true,
        pageSize: 10,
      });
      this.web_nationenStore2 = new DataSource({
        store: {
          data,
          type: 'array'
        },
        paginate: true,
        pageSize: 10,
      });

      this.landSelectOptions = {
        dataSource: this.web_nationenStore,
        searchEnabled: true,
        displayExpr: 'name',
        valueExpr: 'id',
        placeholder: this.translate.instant('REGISTER.WAEHLEN'),
        acceptCustomValue: false,
        noDataText: this.translate.instant('REGISTER.NATIONALITAET_EINGEBEN'),
        onFocusIn: (e) => {
          e.event.target.setSelectionRange(0, -1);
        },
        onSelectionChanged: (e) => {
          if (e.selectedItem && this.daten.nationalitaet_kuerzel !==  e.selectedItem.iso3166) {
            // this.daten.nationalitaet_name = e.selectedItem.name;
            this.daten.nationalitaet_kuerzel = e.selectedItem.iso3166;

            this.daten.steuernummer = '';
            this.resetMitgliedsPruefung('mitgliedKontaktValidator');
          }

        }
      };
      this.land2SelectOptions = {
        dataSource: this.web_nationenStore2,
        searchEnabled: true,
        displayExpr: 'name',
        valueExpr: 'id',
        placeholder: this.translate.instant('REGISTER.WAEHLEN'),
        acceptCustomValue: false,
        noDataText: this.translate.instant('REGISTER.LAND_EINGEBEN'),
        onFocusIn: (e) => {
          e.event.target.setSelectionRange(0, -1);
        },
        onSelectionChanged: (e) => {
          // this.daten.nationalitaet_name = e.selectedItem.name;
          if(e.selectedItem) {
            this.daten.land = e.selectedItem.name;
            this.daten.nation = e.selectedItem.iso3166;
          }
          // this.daten.steuernummer = '';
          // this.resetMitgliedsPruefung('mitgliedKontaktValidator');
        }
      };

      this.landErzSelectOptions = {
        dataSource: this.web_nationenStore,
        searchEnabled: true,
        placeholder: this.translate.instant('REGISTER.WAEHLEN'),
        valueExpr: 'id',
        displayExpr: 'name',
        noDataText: this.translate.instant('REGISTER.NATIONALITAET_EINGEBEN'),
        acceptCustomValue: false,
        onFocusIn: (e) => {
          e.event.target.setSelectionRange(0, -1);
        },
        onSelectionChanged: (e) => {
          if(e.selectedItem && this.daten.erzberechtigte[0].nationalitaet_kuerzel !==  e.selectedItem.iso3166) {
            this.daten.erzberechtigte[0].nationalitaet_name = e.selectedItem.name;
            this.daten.erzberechtigte[0].nationalitaet_kuerzel = e.selectedItem.iso3166;
            this.daten.erzberechtigte[0].steuernummer = '';
            this.resetMitgliedsPruefung('erzberechtKontaktValidator');
          }
        }
      };
      // Standardwert: Italien
      this.daten.nationalitaet =  this.gdService.it_id;
      this.daten.nationalitaet_name = 'Italien';

      this.daten.land_id =  this.gdService.it_id;
      this.daten.land = 'Italien';
    })
    );

    //Geburtsorte Laden
    datenpromises.push(this.gdService.getGeburtsorte().then((data) => {

      this.web_geburtsorte = data;
      this.web_geburtsorteStore = new DataSource({
        store: {
          data,
          type: 'array'
        },
        paginate: true,
        pageSize: 10,
      });

      this.geburtsortSelectOptions = {
        dataSource: this.web_geburtsorteStore,
        searchEnabled: true,
        placeholder: this.translate.instant('REGISTER.WAEHLEN'),
        minSearchLength: 3,
        noDataText: this.translate.instant('REGISTER.GEBURTSORT_EINGEBEN'),
        valueExpr: 'name',
        displayExpr: 'name',
        acceptCustomValue: false,
        onFocusIn: (e) => {
          e.event.target.setSelectionRange(0, -1);
        },
        onSelectionChanged: (e) => {
          if (e.selectedItem && this.daten.gebort !== e.selectedItem.name) {
            this.daten.gebort = e.selectedItem.name;
            this.resetMitgliedsPruefung('mitgliedKontaktValidator');
          }
        }
      };

      this.geburtsortErzSelectOptions = {
          dataSource: this.web_geburtsorteStore,
          displayExpr: 'name',
          valueExpr: 'name',
          placeholder: this.translate.instant('REGISTER.WAEHLEN'),
          searchEnabled: true,
          minSearchLength: 3,
          noDataText: this.translate.instant('REGISTER.GEBURTSORT_EINGEBEN'),
          acceptCustomValue: false,
          onFocusIn: (e) => {
            e.event.target.setSelectionRange(0, -1);
          },
          onSelectionChanged: (e) => {
            if(e.selectedItem && this.daten.erzberechtigte[0].gebort !== e.selectedItem.name) {
              this.daten.erzberechtigte[0].gebort = e.selectedItem.name;
              this.resetMitgliedsPruefung('erzberechtKontaktValidator');
            }
          }
      };


    })
    );
    this.provinzSelectOptions = {
      dataSource: this.provinzen,
      searchEnabled: true,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      noDataText: this.translate.instant('REGISTER.PROVINZ_EINGEBEN'),
      acceptCustomValue: false,
      onFocusIn: (e) => {
        e.event.target.setSelectionRange(0, -1);
      },
      onSelectionChanged: (e) => {
        if (e.selectedItem === 'Bozen' && (!this.gdService['orte'] || this.gdService['orte'].length === 0)) {
          // this.Service.ShowLoadPanel();
          this.gdService.getOrte(this.daten.land_id).then(
            (data) => {
              this.web_orte = data;
              this.web_orteStore = new DataSource({
                store: {
                  data,
                  type: 'array'
                },
                paginate: true,
                pageSize: 10,
              });
              this.ortSelectOptions = {
                dataSource: this.web_orteStore,
                searchEnabled: true,
                placeholder: this.translate.instant('REGISTER.WAEHLEN'),
                minSearchLength: 3,
                noDataText: this.daten.land_id === this.gdService.it_id ? this.translate.instant('REGISTER.GEMEINDE_EINGEBEN') : this.translate.instant('REGISTER.ORT_EINGEBEN'),
                valueExpr: 'name',
                displayExpr: 'name',
                acceptCustomValue: false,
                onFocusIn: (e) => {
                  e.event.target.setSelectionRange(0, -1);
                },
                onSelectionChanged: (e) => {
                  if(e.selectedItem) {
                    this.daten.ort = e.selectedItem.name;
                    if (this.daten && this.daten.provinz === 'Bozen') { this.daten.plz = this.web_orte.find(el => el.id === e.selectedItem.id)['plz']; }
                  }
                  // const editor = this.formkontakt.instance.getEditor('mitglied.provinz');
                  // if (editor) {
                  //   editor.option('disabled', e.selectedItem !== 'Italien');
                  // }
                }
              };
            }
          ).finally(() => {
            // this.Service.HideLoadPanel();
            // setTimeout(() => {
            //   this.ort.instance.focus();
            // });
          });
        }
        // const editor = this.formkontakt.instance.getEditor('mitglied.provinz');
        // if (editor) {
        //   editor.option('disabled', e.selectedItem !== 'Italien');
        // }
      }
    };


    this.pruefenButtonOptions = {
      icon: 'arrowright',
      text: 'prüfen',
      type: 'success',
      useSubmitBehavior: false,
      validationGroup: 'mitgliedKontaktValidator',
      onClick: (e) => {
         this.onClickPruefen(e);
      }
    };

    this.pruefenErzButtonOptions = {
      icon: 'arrowright',
      text: 'prüfen',
      type: 'success',
      useSubmitBehavior: false,
      validationGroup: 'erzberechtKontaktValidator',
      onClick: (e) => {
         this.onClickPruefen(e);
      }
    };

    this.buttonWeiterOptions = {
      icon: 'arrowright',
      text: 'weiter',
      type: 'success',
      useSubmitBehavior: false,
      validationGroup: 'zugangsdatenValidator',
      onClick: (e) => {
         this.onSubmit();
      }
    };

    // if(this.limitierungNM) {
    //   this.daten['registrierungals'] = enuRegistrierungAls.Nichtmitglied;
    // }

    this.registerAlsSelectOptions = {
      dataSource: this.registrierungalsData,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
      disabled: !this.kontakte,
      onSelectionChanged: (e) => {
        if (e.selectedItem && e.selectedItem.value === enuRegistrierungAls.Mitglied && this.registrierungals !== e.selectedItem.value) {
          this.registrierungals = enuRegistrierungAls.Mitglied;
          this.regMitglied = true;
          this.hatNummer = true;
          this.hatErzberechtigten = false;
          // if (localStorage.getItem('locale') !== 'de') {
          //   this.onClickSprache('de');
          // }
        } else if(e.selectedItem && e.selectedItem.value === enuRegistrierungAls.Nichtmitglied && this.registrierungals !== e.selectedItem.value) {
          this.registrierungals = enuRegistrierungAls.Nichtmitglied;
          this.regMitglied = false;
          this.hatNummer = true;
          this.nummerOptions();

          if (!this.hatErzberechtigten && (this.daten.gebdatum > this.maxDate)) {
            // Erziehungseberechtigter erforderlich
            // if(this.limitierungNM) {
            //   this.daten['registrierungalsErz'] = enuRegistrierungAls.Nichtmitglied;
            // }
        
            this.daten.erzberechtigte.push(new Kontakt());
            this.hatErzberechtigten = true;
            this.karteNnummerOptions();
            funktionen.showPopupOK(this.translate.instant('REGISTER.MUSSTVOLLJAEHRIG'), this.translate.instant('REGISTER.GIBERZIEHUNGSBERECHTIGTENAN') + '<br>'+this.translate.instant('REGISTER.SPAETERBERECHTIGTEHINZUFUEGENLOESCHEN'));
          } else {
            this.hatErzberechtigten = false;
            this.daten.erzberechtigte = [];
          }
        }
      }
    };

    this.registerAlsErzSelectOptions = {
      dataSource: this.registrierungalsErzData,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
      disabled:!this.kontakte,
      onSelectionChanged: (e) => {
        if (e.selectedItem && e.selectedItem.value === enuRegistrierungAls.Mitglied) {
          this.registrierungalsErz = enuRegistrierungAls.Mitglied;
          this.regMitgliedErz = true;
          // if (localStorage.getItem('locale') !== 'de') {
          //   this.onClickSprache('de');
          // }
        } else if(e.selectedItem && e.selectedItem.value === enuRegistrierungAls.Nichtmitglied) {
          this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;
          this.regMitgliedErz = false;

        }
      }
    };

    this.anredeSelectOptions = {
      dataSource: this.anrede,
      searchEnabled: true,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
    };

    this.anredeErzSelectOptions = {
      dataSource: this.anredeErz,
      searchEnabled: true,
      valueExpr: 'value',
      displayExpr: 'text',
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      onSelectionChanged: (e) => {
        if (e.selectedItem && e.selectedItem.value === enuAnrede.Frau) {
          this.bezeichnungErZ = 'Deine Erziehungsberechtigte';
          this.bezeichnungErZNummer = 'Mitgliedsnummer der Erziehungsberechtigten';
        } else {
          this.bezeichnungErZ = 'Dein Erziehungsberechtigter';
          this.bezeichnungErZNummer = 'Mitgliedsnummer des Erziehungsberechtigten';
        }
      }
    };

    this.buttonInfoMitgliedsnummer = {
      icon: 'info',
      text: this.translate.instant('REGISTER.WOMITGLIEDSNUMMER'),
      hint: this.translate.instant('REGISTER.WOMITGLIEDSNUMMER'),
      type: 'success',
      stylingMode: 'text',
      useSubmitBehavior: false,
       onClick: (e) => {
        this.mitgliedsnummerPopupVisible = true;
      }
    };

    this.buttonInfoKontaktnummer = {
      icon: 'info',
      text: this.translate.instant('REGISTER.WOKONTAKTNUMMER'),
      hint: this.translate.instant('REGISTER.WOKONTAKTNUMMER'),
      type: 'success',
      stylingMode: 'text',
      useSubmitBehavior: false,
       onClick: (e) => {
        this.kontaktnummerPopupVisible = true;
      }
    };

    this.buttonInfoKarte = {
      icon: 'info',
      type: 'success',
      stylingMode: 'text',
      useSubmitBehavior: false,
       onClick: (e) => {
        this.karteninfoPopupVisible = true;
      }
    };


    this.removErzButtonOptions = {
      icon: 'remove',
      text: this.translate.instant('REGISTER.ERZIEHUNGSBERCHTIGTEN_ENTFERNEN'),
      onClick: (e) => {
        console.log('Erziehungsberchtigten entfernen');
        const myDialog = custom({
          title: this.translate.instant('REGISTER.ENTFERNEN'),
          messageHtml: this.translate.instant('REGISTER.ERZIEHUNGSBERCHTIGTEN_LOESCHEN'),
          buttons: [
              {
                text: 'OK',
                onClick: () => {
                  this.resetErziehungsberechtigten();
                  this.resetMitgliedsPruefung('mitgliedKontaktValidator');
                  this.resetMitgliedsPruefung('erzberechtKontaktValidator');
                  return true;
                }
              },
              {
                text: this.translate.instant('REGISTER.ABBRECHEN'),
                onClick: () => {
                  return false;
                }
              },
          ]
      });
        myDialog.show();
      }
    };


    this.steuernummerOptions = {
      inputAttr: {style: 'text-transform: uppercase'},
    };

    this.steuernummerErzOptions = {
      inputAttr: {style: 'text-transform: uppercase'},
    };

    this.mitgliedsnummerOptions = {
      value: this.daten.mitgliedsnr,
      mask: '000-00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN'),
      onEnterKey: (e) => {
        this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
      },
    };

    this.mitgliedsnummerOptions2 = {
      value: this.daten.mitgliedsnr,
      mask: '00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN'),
      onEnterKey: (e) => {
        this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
      },
    };

    this.mitgliedsnummerErzOptions = {
      mask: '000-00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN')
    };

    this.mitgliedsnummerErzOptions2 = {
      mask: '00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN')
    };

    this.geburtsdatumOptions = {
      dataSource: this.daten.gebdatum,
      invalidDateMessage: this.translate.instant('REGISTER.GEBURTSDATUM_FORMAT'),
      dateOutOfRangeMessage: this.translate.instant('REGISTER.GEBURTSDATUM_ZWISCHEN'),
      displayFormat: { year: 'numeric', month: '2-digit', day: '2-digit', parser: funktionen.DateParser},
      min: this.minDate,
      max: this.today,
    };

    this.geburtsdatumOptionsErz = {
      invalidDateMessage: this.translate.instant('REGISTER.GEBURTSDATUM_FORMAT'),
      dateOutOfRangeMessage: this.translate.instant('REGISTER.GEBURTSDATUM_ZWISCHEN'),
      displayFormat: { year: 'numeric', month: '2-digit', day: '2-digit', parser: funktionen.DateParser},
      min: this.minDate,
      max: this.today,
    };

    this.newsletterOptions = {
      dataSource: this.daten.newsletter,
      text: this.translate.instant('REGISTER.NEWSLETTERTEXT'),
    };

    this.buttonInfoDatenschutz  = (data) => {
      return {      
        icon: 'info',
        text: this.translate.instant('REGISTER.DATENSCHUTZBESTIMMUNGEN'),
        hint: this.translate.instant('REGISTER.DATENSCHUTZBESTIMMUNGEN_OEFFNEN'),
        type: 'success',
        stylingMode: 'text',
        useSubmitBehavior: false,
        onClick: async (e) => {
          if(this.hatErzberechtigten) {
            data.mgd_id = this.daten.erzberechtigte[0].id;
          } else {
            data.mgd_id = this.daten.id;
          }
          let dsgvoVorhanden = await funktionen.openDSGVO('_blank', this.Service.authservice, enuDSGVOVertragTyp.Website, data);
          if(!dsgvoVorhanden){
            let param = '';
            param += '?ohnedatei=' + true;
            if(data.mgd_id) param += '&mgd_id=' + data.mgd_id;

            this.Service.authservice.getUrl('/portal/dsgvoliste' + param).then((data: any[]) => {
              this.dsgvoliste = data;
            });
          }
        }
      }
    };

    this.nummerOptions();
    this.karteNnummerOptions();

    let params = '';
    if(this.route.snapshot.queryParams.personid) {
      this.mgd_id = this.route.snapshot.queryParams.personid;
      params = params + '?mgd_id=' + this.mgd_id;
    }
    if(params) {
    
      this.Service.authservice.getUrl('/portal/registerdaten' + params).then((data: any[]) => {
        Promise.all(datenpromises).then((d) => {
          this.registerdaten = data;
          this.loadingVisible =true;
          setTimeout(() => {
            this.daten.vorname = this.registerdaten['vorname'];
            this.daten.nachname = this.registerdaten['nachname'];
            this.daten.anrede = this.registerdaten['anrede'] === 'Herr' ||  this.registerdaten['anrede'] === 'Herrn' ? enuAnrede.Herr : enuAnrede.Frau;
            if(this.registerdaten['nationalitaet_id']) {
              this.daten.nationalitaet = this.registerdaten['nationalitaet_id'];
            }
            let gebortItem= this.web_geburtsorte.find(x => x.name === this.registerdaten['gebort']);
            if(gebortItem) {
              this.daten.gebort = gebortItem.id;
            }
            this.daten.gebort = this.registerdaten['gebort'];
  
            this.daten.steuernummer = this.registerdaten['steuernummer'];
            this.daten.email = this.registerdaten['email'];
            this.daten.telefon = this.registerdaten['telMobil'];
            if(this.registerdaten['kartennummer']) {
              this.hatNummer = true;
              if(this.registerdaten['gebdatum']) {
                this.daten.gebdatum = new Date(this.registerdaten['gebdatum']);
              }
              this.nummerOptions();
              if(this.registerdaten['kartennummer'].length === 9 && this.gdService.eingabeMitgliedsnummer === enuEingabeMitgliedsnummer.MitSektion) {
                this.registerdaten['kartennummer'] = this.registerdaten['kartennummer'].replace('-','');
                this.mask = '000-00000';
                this.karteNnummerOptions();
              }
              if(this.registerdaten['kartennummer'].length === 6 && this.gdService.eingabeMitgliedsnummer === enuEingabeMitgliedsnummer.OhneSektion) {
                this.registerdaten['kartennummer'] = this.registerdaten['kartennummer'].replace('-','');
                this.mask = '######';
                this.karteNnummerOptions();
              }
              if (this.mask === '000-00000' || this.mask === '#####') {
                this.registrierungals = enuRegistrierungAls.Mitglied;
              } else {
                this.registrierungals = enuRegistrierungAls.Nichtmitglied;
              }
              setTimeout(() => {
                this.setkartennummer = true;
                this.daten.nummer = this.registerdaten['kartennummer'];
                if(this.daten.nationalitaet) {
                  this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
                }
              }, 0);
    
    
            } else {
              if(this.registerdaten['gebdatum']) {
                this.daten.gebdatum = new Date(this.registerdaten['gebdatum']);
              }
              this.checkAlter();
              let landItem= this.web_nationen.find(x => x.iso3166 === this.registerdaten['nation']);
              if(landItem) {
                this.daten.land_id =landItem.id;
                this.daten.land = landItem.name;
                this.daten.provinz =this.registerdaten['provinz'];
                if(!this.daten.provinz && this.registerdaten['nation'] ==='IT') {
                  this.daten.provinz ='Bozen';
                }
              }
              if(this.daten.provinz != 'Bozen') {
                this.daten.ort =this.registerdaten['ort'];
              }
              
              this.daten.plz =this.registerdaten['plz'];
              this.daten.strasse =this.registerdaten['strasse'];
              this.daten.hausnr =this.registerdaten['hausnr'];
              this.daten.weiler =this.registerdaten['weiler'];
      
            }
            this.loadingVisible =false;
          }, 0);
        });

      }).catch((error) => {
        console.log("error: ", error);
        if(error.statusText === 'Kontakt wurde bereits angelegt'){
          this.showRegisterMessage = true;
          this.istBereitsKontakt = true;
        }
      });
    }

  }
  
  mgdnummerEingabe(value: enuEingabeMitgliedsnummer) {
    return this.gdService.mmgdnummerEingabe(value);
  }


  passwordOptions() {
    return {
      mode: this.passwordMode,
      buttons: [{
          name: 'password',
          location: 'after',
          options: {
              type: 'default',
              height: '37px',
              elementAttr: {
                class: 'password',
              },
              icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
              onClick: () => {
                this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
                this.passwordfieldOptions = this.passwordOptions();
              }
          }
      }],

    };
  }

  passwordOptions2() {
    return {
      mode: this.passwordMode2,
      buttons: [{
          name: 'password',
          location: 'after',
          options: {
              type: 'default',
              height: '37px',
              elementAttr: {
                class: 'password',
              },
              icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
              onClick: () => {
                this.passwordMode2 = this.passwordMode2 === 'text' ? 'password' : 'text';
                this.passwordfieldOptions2 = this.passwordOptions2();
              }
          }
      }],

    };
  }

  nummerOptions() {
    this.hatNummerOptions = {
      value: this.hatNummer, 
      text: this.kontaktnummer ? this.translate.instant('REGISTER.HABEKARTE') : this.translate.instant('REGISTER.HABEMITGLIEDKARTE'),
      onValueChanged: (e) => {
        if(e && e.event)  {
          this.hatNummer = e.value;
          if(this.hatNummer) {
            if (this.mask === '000-00000' || this.mask === '#####') {
              this.registrierungals = enuRegistrierungAls.Mitglied;
            } else {
              this.registrierungals = enuRegistrierungAls.Nichtmitglied;
            }
            this.hatErzberechtigten = false;
          } else {
            this.registrierungals = enuRegistrierungAls.Nichtmitglied;
            this.checkAlter();
          }
          this.nummerOptions();
          this.resetMitgliedsPruefung('mitgliedKontaktValidator');
        } 
      }
    }
    this.hatNummerOptionsErz = {
      value: this.hatNummerErz, 
      text: this.kontaktnummer ? this.translate.instant('REGISTER.ERZHABEKARTE') : this.translate.instant('REGISTER.ERZHABEMITGLIEDKARTE'),
      onValueChanged: (e) => {
        if(e && e.event)  {
          this.hatNummerErz = e.value;
          if(this.hatNummerErz) {
            if (this.maskErz === '000-00000' || this.maskErz === '#####') {
              this.registrierungalsErz = enuRegistrierungAls.Mitglied;
            } else {
              this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;
            }
          } else {
            this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;
          }
          this.nummerOptions();
          this.resetMitgliedsPruefung('erzberechtKontaktValidator');
        } 
      }
    }
  }

  karteNnummerOptions() {
    if(this.gdService.eingabeMitgliedsnummer === enuEingabeMitgliedsnummer.MitSektion) {
      this.kartennummerOptions = {
        value: this.daten.nummer,
        mask: this.mask,
        maskRules: { "#" : /[0-9a-zA-Z]/, "*" : /[0-9a-zA-Z\-]/},
        valueChangeEvent: 'change keyup',
        maskInvalidMessage: this.translate.instant('REGISTER.NUMMERUENGULTIG'),
        inputAttr: {'class': 'kartennummer', 'style':  'text-transform: uppercase'},
        onKeyUp: (e) => {
          if(this.daten.nummer && this.daten.nummer.length === 6 && this.mask !== '000-00000' && (/^\d+$/.test(this.daten.nummer))) {
            this.mask = '00000000';
            e.component.option('mask', this.mask);
          }
        },
        onOptionChanged: (e) => {
          console.log(e);
          e.component.option('mask', this.mask);
          if(this.istGeprueftesMitglied || this.setkartennummer) {
            this.setkartennummer = false;
            e.component.option('value', this.daten.nummer);
          }
        },
        onValueChanged: (e) => {
          if(e.value && e.value.length <= 4) {
            if(e.value.length === 4 && e.value.substring(3, 4) === '-') {
              this.mask = '000-00000';
              this.daten.nummer = e.previousValue;
              this.maskfixed =true;
            } else if(e.value.length < 3) {
              this.maskfixed =false;
              this.mask = '###*##';
            }
          } else if(!this.maskfixed && e.value.length >= 8 && this.mask !== '000-00000' && (/^\d+$/.test(e.value))) {
            this.mask = '000-00000';
            e.component.option('mask', this.mask);
            let el = document.getElementsByClassName('kartennummer');
            el[0]['selectionStart']++;
          }
          e.component.option('mask', this.mask);
          if (this.mask === '000-00000' ) {
            this.registrierungals = enuRegistrierungAls.Mitglied;
            if(this.istGeprueftesMitglied && e.value !== this.daten.mitgliedsnr) {
              this.resetMitgliedsPruefung('mitgliedKontaktValidator');
            }
          } else {
            this.registrierungals = enuRegistrierungAls.Nichtmitglied;
            if(this.istGeprueftesMitglied && e.value !== this.daten.kartennr) {
              this.resetMitgliedsPruefung('mitgliedKontaktValidator');
            }
          }
          
        },
        onEnterKey: (e) => {
          this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
        },
      };
  
      if(this.daten.erzberechtigte[0]) {
        this.kartennummerErzOptions = {
          value: this.daten.erzberechtigte[0].nummer,
          mask: this.maskErz,
          maskRules: { "#" : /[0-9a-zA-Z]/, "*" : /[0-9a-zA-Z\-]/},
          valueChangeEvent: 'change keyup',
          maskInvalidMessage: this.translate.instant('REGISTER.NUMMERUENGULTIG'),
          inputAttr: {'class': 'kartennummerErz', 'style':  'text-transform: uppercase'},
          onKeyPress: (e) => {
            if(this.daten.erzberechtigte[0].nummer && this.daten.erzberechtigte[0].nummer.length === 6 && this.maskErz !== '000-00000' 
                  && (/^\d+$/.test(this.daten.erzberechtigte[0].nummer) )) {
              this.maskErz = '00000000';
              e.component.option('mask', this.maskErz);
            }
          },
          onOptionChanged: (e) => {
            console.log(e);
            e.component.option('mask', this.maskErz);
            if(this.istGeprueftesMitgliedErz) {
              e.component.option('value', this.daten.erzberechtigte[0].nummer);
            }
    
          },
          onValueChanged: (e) => {
            if(e.value && e.value.length <= 4) {
              if(e.value.length === 4 && e.value.substring(3, 4) === '-' ) {
                this.maskErz = '000-00000';
                this.daten.erzberechtigte[0].nummer = e.previousValue;
                this.maskfixedErz =true;
              } else if(e.value.length < 3 ) {
                this.maskfixedErz =false;
                this.maskErz = '###*##';
              }
            } else if(!this.maskfixedErz && e.value.length >= 8 && this.maskErz !== '000-00000' 
                    && (/^\d+$/.test(e.value))) {
              this.maskErz = '000-00000';
              e.component.option('mask', this.maskErz);
              let el = document.getElementsByClassName('kartennummerErz');
              if(el[0]) el[0]['selectionStart']++;
            }
            e.component.option('mask', this.maskErz);
            if (this.maskErz === '000-00000' ) {
              this.registrierungalsErz = enuRegistrierungAls.Mitglied;
              if(this.istGeprueftesMitgliedErz && e.value !== this.daten.erzberechtigte[0].mitgliedsnr) {
                this.resetMitgliedsPruefung('erzberechtKontaktValidator');
              }
            } else {
              this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;
              if(this.istGeprueftesMitgliedErz && e.value !== this.daten.erzberechtigte[0].kartennr) {
                this.resetMitgliedsPruefung('erzberechtKontaktValidator');
              }
            }
          },
          onEnterKey: (e) => {
            this.onClickPruefen({validationGroup: {group: 'erzberechtKontaktValidator'}});
          },
        };
      }
    } else {
      this.kartennummerOptions = {
        value: this.daten.nummer,
        mask: this.mask,
        maskRules: { "#" : /[0-9a-zA-Z]/, "*" : /[0-9a-zA-Z\-]/},
        valueChangeEvent: 'change keyup',
        maskInvalidMessage: this.translate.instant('REGISTER.NUMMERUENGULTIG'),
        inputAttr: {'class': 'kartennummer', 'style':  'text-transform: uppercase'},
        onKeyUp: (e) => {
          if(this.daten.nummer && this.daten.nummer.length === 5 && !(/^\d+$/.test(this.daten.nummer))) {
            this.mask = '######';
            e.component.option('mask', this.mask);
          } else if(this.daten.nummer && this.daten.nummer.length === 5 && (/^\d+$/.test(this.daten.nummer)) 
            && ((e.event.keyCode >= 65 && e.event.keyCode <= 90) || (e.event.keyCode >= 97 && e.event.keyCode <= 122))) {
            this.mask = '######';
            console.log('key', e)
            this.daten.nummer += e.event.key;
            e.component.option('mask', this.mask);
            let el = document.getElementsByClassName('kartennummer');
            el[0]['selectionStart']++;
          }
        },
        onOptionChanged: (e) => {
          console.log(e);
          e.component.option('mask', this.mask);
          if(this.istGeprueftesMitglied || this.setkartennummer) {
            this.setkartennummer = false;
            e.component.option('value', this.daten.nummer);
          }
        },
        onValueChanged: (e) => {
          if(e.value && e.value.length <= 5) {
            this.maskfixed =false;
            this.mask = '#####';
          }
          e.component.option('mask', this.mask);
          if (this.mask === '#####') {
            this.registrierungals = enuRegistrierungAls.Mitglied;
            if(this.istGeprueftesMitglied && e.value !== this.daten.mitgliedsnr) {
              this.resetMitgliedsPruefung('mitgliedKontaktValidator');
            }
          } else {
            this.registrierungals = enuRegistrierungAls.Nichtmitglied;
            if(this.istGeprueftesMitglied && e.value !== this.daten.kartennr) {
              this.resetMitgliedsPruefung('mitgliedKontaktValidator');
            }
          }
          
        },
        onEnterKey: (e) => {
          this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
        },
      };
      if(this.daten.erzberechtigte[0]) {
        this.kartennummerErzOptions = {
          value: this.daten.erzberechtigte[0].nummer,
          mask: this.maskErz,
          maskRules: { "#" : /[0-9a-zA-Z]/, "*" : /[0-9a-zA-Z\-]/},
          valueChangeEvent: 'change keyup',
          maskInvalidMessage: this.translate.instant('REGISTER.NUMMERUENGULTIG'),
          inputAttr: {'class': 'kartennummerErz', 'style':  'text-transform: uppercase'},
          onKeyPress: (e) => {
            console.log('erz', this.daten.erzberechtigte[0].nummer, e)
            if(this.daten.erzberechtigte[0].nummer && this.daten.erzberechtigte[0].nummer.length === 5
                  && !(/^\d+$/.test(this.daten.erzberechtigte[0].nummer) )) {
              this.maskErz = '######';
              e.component.option('mask', this.maskErz);
            } else if(this.daten.erzberechtigte[0].nummer && this.daten.erzberechtigte[0].nummer.length === 5
                && (/^\d+$/.test(this.daten.erzberechtigte[0].nummer) ) 
                && ((e.event.keyCode >= 65 && e.event.keyCode <= 90) || (e.event.keyCode >= 97 && e.event.keyCode <= 122))) {
              this.maskErz = '######';
              this.daten.erzberechtigte[0].nummer += e.event.key;
              e.component.option('mask', this.maskErz);
              let el = document.getElementsByClassName('kartennummerErz');
              el[0]['selectionStart']++;
            }

          },
          onOptionChanged: (e) => {
            console.log(e);
            e.component.option('mask', this.maskErz);
            if(this.istGeprueftesMitgliedErz) {
              e.component.option('value', this.daten.erzberechtigte[0].nummer);
            }
    
          },
          onValueChanged: (e) => {
            if(e.value && e.value.length <= 5) {
              this.maskfixedErz =false;
              this.maskErz = '#####';
            }
            e.component.option('mask', this.maskErz);
            if (this.maskErz === '#####' ) {
              this.registrierungalsErz = enuRegistrierungAls.Mitglied;
              if(this.istGeprueftesMitgliedErz && e.value !== this.daten.erzberechtigte[0].mitgliedsnr) {
                this.resetMitgliedsPruefung('erzberechtKontaktValidator');
              }
            } else {
              this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;
              if(this.istGeprueftesMitgliedErz && e.value !== this.daten.erzberechtigte[0].kartennr) {
                this.resetMitgliedsPruefung('erzberechtKontaktValidator');
              }
            }
          },
          onEnterKey: (e) => {
            this.onClickPruefen({validationGroup: {group: 'erzberechtKontaktValidator'}});
          },
        };
      }
    }
  }


  onKontaktDataChanged(e){
    if(e.dataField != 'email' && e.dataField != 'telefon') this.resetMitgliedsPruefung('mitgliedKontaktValidator');
  }

  onErzberechtigterDataChanged(e){
    if(e.dataField != 'erzberechtigte[0].email' && e.dataField != 'erzberechtigte[0].telefon') this.resetMitgliedsPruefung('erzberechtKontaktValidator');
  }

  validateDatenschutz() {
      return true;
  }

  passwordComparison = () => {
      return this.daten.passwort;
  }

  validateGebdatum(e) {
    if (!this.hatNummer) {
          // if (!this.hatErzberechtigten) {
            if (e.value > this.maxDate) {
              if(!this.hatErzberechtigten) {

                this.hatErzberechtigten = true;
                // if(this.limitierungNM) {
                //   this.daten['registrierungalsErz'] = enuRegistrierungAls.Nichtmitglied;
                // }
                this.daten.erzberechtigte.push(new Kontakt());
                this.karteNnummerOptions();
                // Standardwert: Italien
                // this.landErzSelectOptions.value='f2e77ba1-f51c-4dda-a0dd-3f2e087d3394';
                // this.daten.erzberechtigte[0].nationalitaet='f2e77ba1-f51c-4dda-a0dd-3f2e087d3394';
                // this.daten.erzberechtigte[0].nationalitaet_name='Italien';
                funktionen.showPopupOK(this.translate.instant('REGISTER.MUSSTVOLLJAEHRIG'), this.translate.instant('REGISTER.GIBERZIEHUNGSBERECHTIGTENAN') + '<br>' +this.translate.instant('REGISTER.SPAETERBERECHTIGTEHINZUFUEGENLOESCHEN'));
                // this.checkErzberechtigtenOnServer();
              }
              return true;
            } else {
              this.hatErzberechtigten = false;
              this.daten.erzberechtigte = [];
              
              return true;
            }
        // } else {
        //   // Registrierung als Nichtmitglied mit Erziehungsberechtigten
        //   return true;
        // }
      } else {
        // Registrierung als Mitglied (Erziehungsberechtigter könnte hinterlegt sein)
        return true;
      }
  }


  validateGebdatumErz(e) {
    // Erziehungsberechtigter muß volljährig sein
    if (e.value > this.maxDate) {
      return false;
    } else {
      return true;
    }
  }

  validateSteuernummer(e){
    return this.daten.steuergeprueft;
  }

  validateSteuernummer2(e){
    return this.daten.erzberechtigte[0].steuergeprueft;
  }

  validateNummerFormat(e) {
    if(e.value && e.value.length !== 6 && e.value.length !== 8) {
      return false;
    }
    if(e.value && e.value.length === 8 && !(/^\d+$/.test(e.value))) {
      return false;
    }
    return true;
  }


  asyncMitgliedsnummerValidation(params) {
      return new Promise((resolve) => {
        // Überprüfe Mitgliedsnummmer auf Server
        console.log (params.value);
      });
  }

  asyncEmailValidation(params) {
      return new Promise((resolve) => {
        // Überprüfe Emailadresse auf Server
        console.log (params.value);
      });
  }

  async onClickPruefen(e) {
    try {
      this.loadingVisible = true;
      console.log('onClickPruefen', e);
      // Wird Hauptkontakt oder Beziehungsberechtigter überprüft?
      const pruefe = e.validationGroup.group;
      // Vorherige Überprüfung zurücksetzen
      this.resetMitgliedsPruefung(pruefe);

      let nationalitaet: any;
      let nationalitaet_name: string;
      let nationalitaet_id: string;
      let steuernummer: string;
      let anrede: enuAnrede;
      let geschlecht: enuGeschlecht;
      let vorname: string;
      let nachname: string;
      let verheirateterName: string;
      let geburtsdatum: Date;
      let geburtsort: any;
      let geburtsort_name: string;
      let provinz: string;
      let mitgliedsnummer: string;
      let kontaktnummer: string;

      switch (pruefe) {
          case 'mitgliedKontaktValidator':
            this.daten.steuergeprueft = true;
            nationalitaet = this.web_nationen.find(el => el.id === this.daten.nationalitaet);
            if (nationalitaet === undefined) { break; }
            this.daten.nationalitaet_name = nationalitaet['name'];
            nationalitaet_name = nationalitaet['name'];
            nationalitaet_id = nationalitaet['id'];
            steuernummer = this.daten.steuernummer != null ? this.daten.steuernummer.trim() : this.daten.steuernummer;
            anrede = this.daten.anrede;
            geschlecht = enuAnrede.GetGeschlecht( this.daten.anrede);
            vorname = this.daten.vorname != null ? this.daten.vorname.trim() : this.daten.vorname;
            nachname = this.daten.nachname != null ? this.daten.nachname.trim() : this.daten.nachname;
            verheirateterName = this.daten.verheirateterName;
            geburtsdatum = this.daten.gebdatum;
            if (nationalitaet_id === this.gdService.it_id) {
              geburtsort = this.web_geburtsorte.find(el => el.name === this.daten.gebort);
              if (geburtsort === undefined) { break; }
              this.daten.gebort = geburtsort['name'];
              geburtsort_name = geburtsort['name'];
              this.daten.provinz = geburtsort['kodex'];
              provinz = geburtsort['kodex'];
            } else {
              geburtsort_name = this.daten.gebort;
            }
            if(this.registrierungals === enuRegistrierungAls.Mitglied) {
              if(this.kontaktnummer) {
                this.daten.mitgliedsnr = this.daten.nummer;
              }
              mitgliedsnummer = this.daten.mitgliedsnr;
            } else {
              if(this.kontaktnummer) {
                this.daten.kartennr = this.daten.nummer.toLocaleUpperCase();
              }
              kontaktnummer = this.daten.kartennr;
            }
            break;
          case 'erzberechtKontaktValidator':
            this.daten.erzberechtigte[0].steuergeprueft = true;
            nationalitaet = this.web_nationen.find(el => el.id === this.daten.erzberechtigte[0].nationalitaet);
            if (nationalitaet === undefined) { break; }
            this.daten.erzberechtigte[0].nationalitaet_name = nationalitaet['name'];
            nationalitaet_name = nationalitaet['name'];
            nationalitaet_id = nationalitaet['id'];
            steuernummer = this.daten.erzberechtigte[0].steuernummer != null ? this.daten.erzberechtigte[0].steuernummer.trim() : this.daten.erzberechtigte[0].steuernummer;
            anrede = this.daten.erzberechtigte[0].anrede;
            geschlecht = enuAnrede.GetGeschlecht( this.daten.erzberechtigte[0].anrede);
            vorname = this.daten.erzberechtigte[0].vorname != null ? this.daten.erzberechtigte[0].vorname.trim() : this.daten.erzberechtigte[0].vorname;
            nachname = this.daten.erzberechtigte[0].nachname != null ? this.daten.erzberechtigte[0].nachname.trim() : this.daten.erzberechtigte[0].nachname;
            geburtsdatum = this.daten.erzberechtigte[0].gebdatum;
            if (nationalitaet_id === this.gdService.it_id) {
              geburtsort = this.web_geburtsorte.find(el => el.name === this.daten.erzberechtigte[0].gebort);
              if (geburtsort === undefined) { break; }
              this.daten.erzberechtigte[0].gebort = geburtsort['name'];
              geburtsort_name = geburtsort['name'];
              this.daten.erzberechtigte[0].provinz = geburtsort['kodex'];
              provinz = geburtsort['kodex'];
            } else {
              geburtsort_name = this.daten.erzberechtigte[0].gebort;
            }
            if(this.registrierungalsErz === enuRegistrierungAls.Mitglied) {
              this.daten.erzberechtigte[0].mitgliedsnr = this.daten.erzberechtigte[0].nummer;
              mitgliedsnummer = this.daten.erzberechtigte[0].mitgliedsnr;
            } else {
              this.daten.erzberechtigte[0].kartennr = this.daten.erzberechtigte[0].nummer.toLocaleUpperCase();
              kontaktnummer = this.daten.erzberechtigte[0].kartennr;
            }
            break;
          default:
            break;
      }

      // Sind für Überprüfung benötigte Daten vorhanden?
      const result = validationEngine.validateGroup(pruefe);
      if (result.isValid) {

        // Steuernummerprüfung nur wenn in Italien
        if (nationalitaet_id === this.gdService.it_id){
          const codiceCatasto = geburtsort['kodex'];
          const geschlechtSteuer = (geschlecht === enuGeschlecht.Maennlich) ? enuGeschlechtSteuernummer.Maennlich :  enuGeschlechtSteuernummer.Weiblich;

          const steuerpruef = await this.steuerService.checkSteuernummer(
            steuernummer, vorname, nachname, geschlechtSteuer, geburtsdatum, codiceCatasto);
          if (steuerpruef !== enuSteuernummerPruefung.Korrekt) {
            // Fehler in Steuernummer:
            const steuerfehler = enuSteuernummerPruefung.GetNameTranslated(steuerpruef, this.translate);
            funktionen.showPopupOK('Steuernummer', steuerfehler);
            if (pruefe === 'mitgliedKontaktValidator') {
              this.daten.steuergeprueft = false;
              this.daten.steuerfehlerTxt = steuerfehler;
              this.formKontakt.instance.getEditor('steuernummer').focus();
            } else {
              this.daten.erzberechtigte[0].steuergeprueft = false;
              this.daten.erzberechtigte[0].steuerfehlerTxt = steuerfehler;
              this.formKontaktErz.instance.getEditor('erzberechtigte[0].steuernummer').focus();
            }
            validationEngine.validateGroup(pruefe);
            this.loadingVisible = false;
            return;
          }

        }
        // Daten zum Server schicken
        console.log('checkMitgliedschaft', 1);
        this.checkMitgliedschaft(pruefe, nationalitaet_id, steuernummer, anrede, vorname
          , nachname, geburtsdatum, geburtsort_name, mitgliedsnummer, kontaktnummer).finally( () => {
            this.loadingVisible = false;
          });
      } else {
        const errors = funktionen.getBrokenRules(result);
        funktionen.showPopupOK(this.translate.instant('REGISTER.PFLICHTFELD'), errors);
        this.loadingVisible = false;
        return;
      }
    } catch(error) {
      this.loadingVisible = false;
    }
  }


  resetMitgliedsPruefung(pruefe: string) {
    switch (pruefe) {
      case 'mitgliedKontaktValidator':
        this.istGeprueftesMitglied = false;
        this.mitgliedsPruefungTxt = enuMitgliedsPruefung.GetNameTranslated(enuMitgliedsPruefung.NichtGeprueft, this.translate);
        break;
      case 'erzberechtKontaktValidator':
        this.istGeprueftesMitgliedErz = false;
        this.linkzuFamilie = false;
        this.mitgliedsPruefungTxtErz = enuMitgliedsPruefung.GetNameTranslated(enuMitgliedsPruefung.NichtGeprueft, this.translate);
        break;
      default:
        break;
    }
  }

  resetErziehungsberechtigten(): void{
    this.hatErzberechtigten = false;
    this.daten.gebdatum = null;
    this.daten.erzberechtigte = [];
    // this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;
    this.bezeichnungErZ = this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN');
    if (this.kontaktnummer) {
      this.bezeichnungErZNummer = this.translate.instant('REGISTER.KONTAKTNUMMERDESERZIEHUNGSBERECHTIGTEN');
    } else {
      this.bezeichnungErZNummer = this.translate.instant('REGISTER.MITGLIEDSNUMMERDESERZIEHUNGSBERECHTIGTEN');
    }
    // this.regMitgliedErz = false;
  }

  async checkMitgliedschaft(pruefe, nationalitaet, steuernummer, anrede, vorname, nachname, geburtsdatum, geburtsort, mitgliedsnummer, kontaktnummer) {

    let geschlecht = (anrede === enuAnrede.Herr) ? enuGeschlecht.Maennlich : enuGeschlecht.Weiblich;
    console.log('Check Mitgliedschaft auf Server...');
    let sek_nummer = '';
    let nummer = '';
    let eingabe = null;

    if(mitgliedsnummer) {
      if (this.gdService.mmgdnummerEingabe(enuEingabeMitgliedsnummer.MitSektion)) {
        sek_nummer = mitgliedsnummer.toString().substr(0, 3);
      } else {
        sek_nummer = '000';
      }
      eingabe =  this.gdService.eingabeMitgliedsnummer;
  
      if (this.gdService.mmgdnummerEingabe(enuEingabeMitgliedsnummer.MitSektion)) {
        nummer = mitgliedsnummer.toString().substr(3, 5);
      } else {
        nummer = mitgliedsnummer.toString();
      }
    } else if(kontaktnummer){
      sek_nummer = '000';
      nummer = kontaktnummer;
      eingabe =  enuEingabeMitgliedsnummer.Kontakt;
    }
    // let nurMitglied = '';
    // if (pruefe === 'erzberechtKontaktValidator') {
    //   nurMitglied = '?nurMitglied=true';
    // }
    let params = '';
    if (steuernummer) {
      params += '&';
      params += 'steuernummer=' + steuernummer;
    }
    if (vorname) {
      params += '&';
      params += 'vorname=' + vorname;
    }
    if (nachname) {
      params += '&';
      params += 'nachname=' + nachname;
    }
    if (geburtsdatum) {
      params += '&';
      params += 'geburtsdatum=' + moment(geburtsdatum).format('YYYY-MM-DDTHH:mm:ss');
    }
    this.Service.authservice.getUrl('/portal/checkMitglieschaft/' + sek_nummer
    + '/' + nummer + '?eingabeMitgliedsnummer=' + eingabe + params).then((data) => {
      console.log('Check Mitgliedschaft auf Server... data:', data);
      if (data['id'] && !data['error']) {

        const dataProvider = data as any;
        const id = dataProvider.id;
        this.daten.id = id;
        const name = dataProvider.vorname + ' ' + dataProvider.nachname;
        const verheirateterName = dataProvider.verheirateterName;
        geburtsort = dataProvider.gebort;
        geschlecht = dataProvider.geschlecht;
        const nation = dataProvider.nation;
        const gebdatum = dataProvider.gebdatum;
        const titel = dataProvider.titel;
        steuernummer = dataProvider.steuernummer;

        let error = false;

        let errorText = '';

        const d = moment(this.daten.gebdatum).format('YYYY-MM-DDTHH:mm:ss');


        switch (pruefe) {
          case 'mitgliedKontaktValidator':

            // Mitgliedsnummer existiert
            // Steuernummer überprüfen
            // if (this.daten.nationalitaet === this.gdService.it_id && steuernummer != null) {
            //     if (steuernummer !== this.daten.steuernummer) {
            //       error = true;
            //     }
            // }
            // Steuernummer stimmt nicht
            if (this.daten.steuernummer != null && dataProvider.steuernummer != null ) {
              if ( dataProvider.steuernummer !== this.daten.steuernummer.trim().toUpperCase()) {
                error = true;
                if (!funktionen.isEmptyString(errorText)) { errorText += ','; }
                errorText += this.translate.instant('REGISTER.STEUERNUMMER');
              }
            } else {
            // Geburtsdaten stimmen nicht
              if (dataProvider.gebdatum !== moment(this.daten.gebdatum).format('YYYY-MM-DDTHH:mm:ss') ) {
                error = true;
                if (!funktionen.isEmptyString(errorText)) { errorText += ', '; }
                errorText += this.translate.instant('REGISTER.GEBURTSDATUM');
              }
              // Vorname oder Nachname stimmen nicht
              if (dataProvider.vorname.toUpperCase() !== this.daten.vorname.trim().toUpperCase()) {
                error = true;
                if (!funktionen.isEmptyString(errorText)) { errorText += ', '; }
                errorText += this.translate.instant('REGISTER.VORNAME');
              }
              if (dataProvider.nachname.toUpperCase() !== this.daten.nachname.trim().toUpperCase()) {
                if (this.daten.verheirateterName == undefined || this.daten.verheirateterName == null  || dataProvider.nachname.toUpperCase() !== this.daten.verheirateterName.trim().toUpperCase()) {
                  error = true;
                  if (!funktionen.isEmptyString(errorText)) { errorText += ', '; }
                  errorText += this.translate.instant('REGISTER.NACHNAME');
                }
              }
            }




              if (error) {
                // Fehlerausgabe
                funktionen.showPopupOK(this.translate.instant('REGISTER.MITGLIEDSCHAFT'), '<p>' + this.translate.instant('REGISTER.EINGABEN_NICHT_MITGLIEDSDATEN')+' '+errorText + '.' +'<br>' + this.translate.instant('REGISTER.KONTAKTIERE_SEKTION') +'</p>');
                this.daten.email = null;
                this.daten.telefon = null;
              } else {
                // Alles korrekt
                this.istGeprueftesMitglied = true;
                this.mitgliedsPruefungTxt = enuMitgliedsPruefung.GetNameTranslated(enuMitgliedsPruefung.Ok, this.translate);
                this.daten.email = dataProvider.email;
                this.daten.telefon = dataProvider.telMobil;
                // Falls nötig  Daten auf Server ergänzen
                if (nation === null || titel === null || geburtsort === null || steuernummer === null) {
                  let params = '';
                  if (nation == null) {
                    if (params.length > 0) { params += '&'; }
                    params += 'nation_id=' + this.daten.nationalitaet;
                  }
                  if (titel == null && this.daten.titel ) {
                    if (params.length > 0) { params += '&'; }
                    params += 'titel=' + this.daten.titel;
                  }
                  if (geburtsort == null && this.daten.gebort) {
                    if (params.length > 0) { params += '&'; }
                    params += 'gebort=' + this.daten.gebort;
                  }
                  if (steuernummer == null && this.daten.steuernummer) {
                    if (params.length > 0) { params += '&'; }
                    params += 'steuernr=' + this.daten.steuernummer.toUpperCase();
                  }
                  if (params.length > 0) {
                    params = '?id=' + id + '&' + params;
                    this.updateMitgliedOnServer(params);
                  }
                }
				        this.dsgvoliste = dataProvider.dsgvo;
              }
              break;
          case 'erzberechtKontaktValidator':

            // Mitgliedsnummer existiert
            // Steuernummer überprüfen
            // if (this.daten.erzberechtigte[0].nationalitaet === this.gdService.it_id && steuernummer != null) {
            //     if (steuernummer !== this.daten.erzberechtigte[0].steuernummer) {
            //       error = true;
            //     }
            // }

            if (this.daten.erzberechtigte && this.daten.erzberechtigte.length > 0 
                && this.daten.erzberechtigte[0].steuernummer != null && dataProvider.steuernummer != null ) {
              if ( dataProvider.steuernummer !== this.daten.erzberechtigte[0].steuernummer.trim().toUpperCase()) {
                error = true;
                if (!funktionen.isEmptyString(errorText)) { errorText += ','; }
                errorText += this.translate.instant('REGISTER.STEUERNUMMER');
              }
            } else {
            // Geburtsdaten stimmen nicht
              if (dataProvider.gebdatum !== moment(this.daten.erzberechtigte[0].gebdatum).format('YYYY-MM-DDTHH:mm:ss') ) {
                error = true;
                if (!funktionen.isEmptyString(errorText)) { errorText += ', '; }
                errorText += this.translate.instant('REGISTER.GEBURTSDATUM');
              }
              // Vorname oder Nachname stimmen nicht
              if (dataProvider.vorname.toUpperCase() !== this.daten.erzberechtigte[0].vorname.trim().toUpperCase()) {
                error = true;
                if (!funktionen.isEmptyString(errorText)) { errorText += ', '; }
                errorText += this.translate.instant('REGISTER.VORNAME');
              }
              if (dataProvider.nachname.toUpperCase() !== this.daten.erzberechtigte[0].nachname.trim().toUpperCase()) {
                if (!funktionen.isEmptyString(this.daten.erzberechtigte[0].verheirateterName) && dataProvider.nachname.toUpperCase() !== this.daten.erzberechtigte[0].verheirateterName.trim().toUpperCase()) {
                  error = true;
                  if (!funktionen.isEmptyString(errorText)) { errorText += ', '; }
                  errorText += this.translate.instant('REGISTER.NACHNAME');
                }
              }
            }

            // Anrede stimmt nicht (dataProvider.anrede kann 'Herr' oder 'Herrn' beinhalten)
            // if (dataProvider.anrede !== enuAnrede.GetNameOffice(this.daten.erzberechtigte[0].anrede) && dataProvider.anrede !== enuAnrede.GetName(this.daten.erzberechtigte[0].anrede)) {
            //   error = true;
            //   if (!funktionen.isEmptyString(errorText)) { errorText += ', '; }
            //   errorText += 'Anrede';
            // }

            if (error) {
              // Fehlerausgabe
             funktionen.showPopupOK(this.translate.instant('REGISTER.MITGLIEDSCHAFT'), '<p>' + this.translate.instant('REGISTER.EINGABEN_STIMMEN_NICHT_ERZIEHUNGSBERECHTIGTEN')+ ' ' + errorText + '.</p>');
             this.daten.erzberechtigte[0].email = null;
             this.daten.erzberechtigte[0].telefon = null;
            } else {
              // Alles korrekt
              this.istGeprueftesMitgliedErz = true;
              this.daten.erzberechtigte[0]['istgeprueft'] = this.istGeprueftesMitgliedErz;
              this.mitgliedsPruefungTxtErz = enuMitgliedsPruefung.GetNameTranslated(enuMitgliedsPruefung.Ok, this.translate);
              this.daten.erzberechtigte[0].email = dataProvider.email;
              this.daten.erzberechtigte[0].telefon = dataProvider.telMobil;
              // Falls nötig  Daten auf Server ergänzen
              if (nation === null || titel === null || geburtsort === null || steuernummer === null) {
                let params = '';
                if (nation == null) {
                  if (params.length > 0) { params += '&'; }
                  params += 'nation_id=' + this.daten.erzberechtigte[0].nationalitaet;
                }
                if (titel == null && this.daten.titel) {
                  if (params.length > 0) { params += '&'; }
                  params += 'titel=' + this.daten.erzberechtigte[0].titel;
                }
                if (geburtsort == null && this.daten.erzberechtigte[0].gebort) {
                  if (params.length > 0) { params += '&'; }
                  params += 'gebort=' + this.daten.gebort;
                }
                if (steuernummer == null && this.daten.erzberechtigte[0].steuernummer) {
                  if (params.length > 0) { params += '&'; }
                  params += 'steuernr=' + this.daten.erzberechtigte[0].steuernummer.toUpperCase();
                }
                if (params.length > 0) {
                  params = '?id=' + id + '&' + params;
                  this.updateMitgliedOnServer(params);
                }
				        this.dsgvoliste = dataProvider.dsgvo;
              }
           }

            break;
          default:
            break;
        }


      } else {
        switch (pruefe) {
          case 'mitgliedKontaktValidator':
            this.daten.email = null;
            this.daten.telefon = null;    
            if(data['error']) {
              funktionen.showPopupOK(this.translate.instant('REGISTER.MITGLIEDSCHAFT'), '<p>' + this.translate.instant('REGISTER.EINGABEN_NICHT_MITGLIEDSDATEN')+' '+ data['errorText'] + '.' +'<br>' + this.translate.instant('REGISTER.KONTAKTIERE_SEKTION') +'</p>');
            } else {
              //Mitgliedsnummer nicht vorhanden
              funktionen.showPopupOK(this.translate.instant('REGISTER.MITGLIEDSCHAFT'), '<p>' + this.translate.instant('REGISTER.MITGLIEDSCHAFT_NICHT_UEBERPRUEFT') +'</p>');
            }
            break;
          case 'erzberechtKontaktValidator':
            this.daten.erzberechtigte[0].email = null;
            this.daten.erzberechtigte[0].telefon = null;
            if(data['error']) {
              funktionen.showPopupOK(this.translate.instant('REGISTER.MITGLIEDSCHAFT'), '<p>' + this.translate.instant('REGISTER.EINGABEN_STIMMEN_NICHT_ERZIEHUNGSBERECHTIGTEN')+ ' ' + data['errorText'] + '.</p>');
            } else {
              //Mitgliedsnummer nicht vorhanden
              funktionen.showPopupOK(this.translate.instant('REGISTER.MITGLIEDSCHAFT'), '<p>' + this.translate.instant('REGISTER.MITGLIEDSCHAFT_NICHT_UEBERPRUEFT') +'</p>');
            }
            break;
        }
      }

    }).catch((error) => {
      console.log('Check Mitgliedschaft auf Server... error:', error);
      if (error && error.error && error.error.text === 'Spalte \'sektionsnr\' ist ungültig.') {
        alert(this.translate.instant('REGISTER.UNBEKANNTESEKTION') + mitgliedsnummer.toString().substr(0, 3), this.translate.instant('REGISTER.MITTEILUNG'));
      } else {
        if (error.statusText=='Du hast schon einen bestehenden Account!') {
          switch (pruefe) {
            case 'mitgliedKontaktValidator':
              alert(this.translate.instant('REGISTER.SCHONBESTEHENDENACCOUNT'), this.translate.instant('REGISTER.MITTEILUNG'));
              this.linkzuFamilie = false;
              break;
            case 'erzberechtKontaktValidator':
              alert(this.translate.instant('REGISTER.SCHONBESTEHENDENACCOUNT'), this.translate.instant('REGISTER.MITTEILUNG'));
              this.linkzuFamilie = true;
              break;
          }
        } else {
          alert(error.statusText, this.translate.instant('REGISTER.MITTEILUNG'));

        }
      }
    });


  }

  async onSubmit() {
    if (!this.sendClicked) {
  	try {
      this.loadingVisible = true;

      this.sendClicked = true;
      let fehlermeldungen = '';
      let focusToField: Editor;
      this.daten.steuergeprueft = true; // custom validator zurücksetzen
      if (this.hatErzberechtigten) { this.daten.erzberechtigte[0].steuergeprueft = true; }

      // Daten Hauptkontakt vorhanden?
      const result1 = validationEngine.validateGroup('mitgliedKontaktValidator');
      if (!result1.isValid) {
        this.sendClicked = false;
        fehlermeldungen = funktionen.getBrokenRules(result1);
        focusToField = this.formKontakt.instance.getEditor('registrierungals');
      }
      const nationalitaet = this.web_nationen.find(el => el.id === this.daten.nationalitaet);
      this.daten.nationalitaet_name = nationalitaet['name'];

      if (this.daten.nationalitaet === this.gdService.it_id) {
        const geburtsort = this.web_geburtsorte.find(el => el.name === this.daten.gebort);
        if (geburtsort) {
          this.daten.gebort = geburtsort['name'];
          // this.daten.provinz = geburtsort['kodex'];
        }
      }


      // Steuernummer Hauptkontakt korrekt?
      if (this.daten.nationalitaet === this.gdService.it_id && this.daten.steuernummer) {

        const geburtsort = this.web_geburtsorte.find(el => el.name === this.daten.gebort);
        const codiceCatasto = geburtsort['kodex'];
        const geschlecht = enuAnrede.GetGeschlecht( this.daten.anrede);

        const geschlechtSteuer = (geschlecht === enuGeschlecht.Maennlich) ? enuGeschlechtSteuernummer.Maennlich :  enuGeschlechtSteuernummer.Weiblich;

        const steuer1 = await this.steuerService.checkSteuernummer(this.daten.steuernummer, this.daten.vorname, this.daten.nachname,
          geschlechtSteuer, this.daten.gebdatum, codiceCatasto);
        if (steuer1 !== enuSteuernummerPruefung.Korrekt) {
          this.sendClicked = false;
          // Fehler in Steuernummer
          const steuerfehler = enuSteuernummerPruefung.GetNameTranslated(steuer1, this.translate);
          this.formKontakt.instance.getEditor('steuernummer');
          this.daten.steuergeprueft = false;
          this.daten.steuerfehlerTxt = steuerfehler;
          fehlermeldungen += '&#8226; ' + steuerfehler + '<br>';
          if (focusToField === undefined) {
            focusToField = this.formKontakt.instance.getEditor('steuernummer');
          }
          validationEngine.validateGroup('mitgliedKontaktValidator');
        }

      }

      // Mitgliedschaft Hauptkontakt überprüft?
      if (this.registrierungals === enuRegistrierungAls.Mitglied) {
        if (!this.istGeprueftesMitglied) {
          this.sendClicked = false;
          fehlermeldungen += '&#8226; '+this.translate.instant('REGISTER.FEHLER') +'<br>';
          if (focusToField === undefined) {
            focusToField = this.formKontakt.instance.getEditor('daten.mitgliedsnummer');
          }
        }
      }

      // Daten Erziehungsberechtigter vorhanden?
      if (this.hatErzberechtigten) {
        const result2 = validationEngine.validateGroup('erzberechtKontaktValidator');
        if (!result2.isValid) {
          this.sendClicked = false;
          const errors = funktionen.getBrokenRules(result2);
          fehlermeldungen += errors;
          if (focusToField === undefined) {
            focusToField = this.formKontaktErz.instance.getEditor('registrierungalsErz');
          }
        }
        const nationalitaetErz = this.web_nationen.find(el => el.id === this.daten.erzberechtigte[0].nationalitaet);
        this.daten.erzberechtigte[0].nationalitaet_name = nationalitaetErz['name'];

        if (this.daten.erzberechtigte[0].nationalitaet === this.gdService.it_id) {
          const geburtsort = this.web_geburtsorte.find(el => el.name === this.daten.erzberechtigte[0].gebort);
          if (geburtsort) {
            this.daten.erzberechtigte[0].gebort = geburtsort['name'];
            this.daten.erzberechtigte[0].provinz = geburtsort['kodex'];
          }
        }
        this.daten.erzberechtigte[0]['registrierungals'] = this.registrierungalsErz;
        this.daten.erzberechtigte[0]['gebdatumErz'] = moment(this.daten.erzberechtigte[0].gebdatum).format('YYYY-MM-DD');
        // Steuernummer des Erzberechtigten überprüfen

        if (this.daten.erzberechtigte[0].nationalitaet === this.gdService.it_id && this.daten.erzberechtigte[0].steuernummer) {
            // eslint-disable-next-line max-len
            const geburtsort = this.web_geburtsorte.find(el => el.name === this.daten.erzberechtigte[0].gebort);
            const codiceCatasto = geburtsort['kodex'];
            const geschlecht = enuAnrede.GetGeschlecht( this.daten.erzberechtigte[0].anrede);

            const geschlechtSteuer = (geschlecht === enuGeschlecht.Maennlich) ? enuGeschlechtSteuernummer.Maennlich :  enuGeschlechtSteuernummer.Weiblich;
            const steuer2 = await this.steuerService.checkSteuernummer(this.daten.erzberechtigte[0].steuernummer, this.daten.erzberechtigte[0].vorname, this.daten.erzberechtigte[0].nachname,
              geschlechtSteuer, this.daten.erzberechtigte[0].gebdatum
            , codiceCatasto);
            if (steuer2 !== enuSteuernummerPruefung.Korrekt) {
              // Fehler in Steuernummer
              this.sendClicked = false;
              const steuerfehler = enuSteuernummerPruefung.GetNameTranslatedErz(steuer2, this.translate);
              fehlermeldungen += '&#8226; ' + steuerfehler + '<br>';
              if (focusToField === undefined) {
                focusToField = this.formKontaktErz.instance.getEditor('registrierungalsErz');
              }
              this.daten.erzberechtigte[0].steuergeprueft = false;
              this.daten.erzberechtigte[0].steuerfehlerTxt = steuerfehler;
              validationEngine.validateGroup('erzberechtKontaktValidator');
            }
        }
        // Mitgliedschaft des Erziehungsberechtigten überprüft?
        if (this.registrierungalsErz === enuRegistrierungAls.Mitglied) {
          if (!this.istGeprueftesMitgliedErz) {
            this.sendClicked = false;
            fehlermeldungen += '&#8226; '+this.translate.instant('REGISTER.ERZMITGLIEDSCHAFTNICHTUEBERPRUEFT')+'<br>';
            if (focusToField === undefined) {
              focusToField = this.formKontaktErz.instance.getEditor('erzberechtigte[0].mitgliedsnummer');
            }
          }
        }

      }

      // Zugangsdaten vorhanden?
      const result3 = validationEngine.validateGroup('zugangsdatenValidator');
      if (!result3.isValid) {
        this.sendClicked = false;
        const errors = funktionen.getBrokenRules(result3);
        fehlermeldungen += errors;
        if (focusToField === undefined) {
          focusToField = this.formZugangsdaten.instance.getEditor('email');
        }
      }

      // Fehlermeldungen ausgeben
      if (fehlermeldungen !== '') {
        this.sendClicked = false;
        funktionen.showPopupOK(this.translate.instant('REGISTER.FEHLER'), fehlermeldungen);
        focusToField.focus();
        this.loadingVisible = false;
        return;
      }

      // Daten OK
      let params = '';
      if (this.daten.benutzername) {
        if (params.length > 0) { params = params + '&'; }
        params = params + 'benutzername=' + this.daten.benutzername;
      }
      if (params.length > 0) { params = '?' + params; }
      this.Service.authservice.getUrl('/portal/allowbenutzer' + params).then((data) => {
        if (data === 'Der Benutzername ist bereits in Verwendung.' ) {
          this.sendClicked = false;
          if (this.registrierungals === enuRegistrierungAls.Mitglied) {
            funktionen.showPopupOK(this.translate.instant('REGISTER.NACHRICHT'),'<p>' + this.translate.instant('REGISTER.BENUTZERNAME_BEREITS_VERWENDUNG') +' '+ this.translate.instant('REGISTER.MELDEN_SICHAN_MITGLIED_WERDEN') +'</p>');
          } else {
            funktionen.showPopupOK(this.translate.instant('REGISTER.NACHRICHT'), '<p>' + this.translate.instant('REGISTER.BENUTZERNAME_BEREITS_VERWENDUNG') +'</p>');
          }
          this.loadingVisible = false;
        } else {
          if (this.registrierungals === enuRegistrierungAls.Nichtmitglied) {
            // Nicht Mitglied
            params = '';
            if (this.daten.benutzername) {
              if (params.length > 0) { params = params + '&'; }
              params = params + 'benutzername=' + this.daten.benutzername;
            }
            if (params.length > 0) { params = '?' + params; }
            const send = {
              id: this.daten.id,
              geschlecht : (this.daten.anrede === enuAnrede.Herr) ? enuGeschlecht.Maennlich : enuGeschlecht.Weiblich,
              nationalitaet_id : this.daten.nationalitaet,
              vorname : this.daten.vorname.trim(),
              nachname : this.daten.nachname.trim(),
              gebdatum : moment(this.daten.gebdatum).format('YYYY-MM-DD'),
              gebort : this.daten.gebort ? this.daten.gebort.trim() : null,
              steuernummer : this.daten.steuernummer ? this.daten.steuernummer.trim() : null,
              email : this.daten.email,
              telefon : this.daten.telefon,
              benutzername: this.daten.benutzername,
              passwort: this.daten.passwort,
              newsletter: this.daten.newsletter,
              anrede: enuAnrede.GetName(this.daten.anrede),
              titel: this.daten.titel,
              nation: this.daten.nation,
              provinz: this.daten.provinz,
              ort: this.daten.ort,
              plz: this.daten.plz,
              strasse: this.daten.strasse,
              hausnr: this.daten.hausnr,
              weiler: this.daten.weiler,
              erzberechtigte : this.daten.erzberechtigte,
              istgeprueft : this.istGeprueftesMitglied,
              registrierungals: enuRegistrierungAls.GetName(this.registrierungals),
              deleteNewsletter: data,
              dsgvoliste : this.dsgvoliste
            };
            this.Service.authservice.register({send}).then( (data2) => {
              this.emailsendto = data2['email'];

              this.daten.steuergeprueft = true;
              if (this.hatErzberechtigten) { this.daten.erzberechtigte[0].steuergeprueft = true; }
              this.istBereitsKontakt = false;
              this.showRegisterMessage = true;
              this.loadingVisible = false;
            }).catch((error) => {
              this.sendClicked = false;
              alert(error.statusText, this.translate.instant('REGISTER.FEHLER'));
              this.loadingVisible = false;
            });
          } else {
            // Mitglied
            // const geschlecht = (this.daten.anrede === enuAnrede.Herr) ? enuGeschlecht.Maennlich : enuGeschlecht.Weiblich;
            // this.Service.authservice.getUrl('/portal/mitglied_nr/' + this.daten.mitgliedsnummer.toString().substr(0, 3)
              // + '/' + this.daten.mitgliedsnummer.toString().substr(3, 5)).then((data) => {
            if (this.daten.id !== undefined) {
              const send = {
                id: this.daten.id,
                geschlecht : (this.daten.anrede === enuAnrede.Herr) ? enuGeschlecht.Maennlich : enuGeschlecht.Weiblich,
                nationalitaet_id : this.daten.nationalitaet,
                vorname : this.daten.vorname,
                nachname : this.daten.nachname,
                verheirateterName : this.daten.verheirateterName,
                gebdatum : moment(this.daten.gebdatum).format('YYYY-MM-DD'),
                gebort : this.daten.gebort ? this.daten.gebort.trim() : null,
                steuernummer : this.daten.steuernummer,
                email : this.daten.email,
                telefon : this.daten.telefon,
                benutzername: this.daten.benutzername,
                passwort: this.daten.passwort,
                newsletter: this.daten.newsletter,
                anrede: enuAnrede.GetName(this.daten.anrede),
                titel: this.daten.titel,
                istgeprueft : this.istGeprueftesMitglied,
                registrierungals: enuRegistrierungAls.GetName(this.registrierungals),
                deleteNewsletter: data,
                dsgvoliste : this.dsgvoliste
              };
              this.Service.authservice.register({send}).then( (data2) => {
                this.daten.steuergeprueft = true;
                if (this.hatErzberechtigten) { this.daten.erzberechtigte[0].steuergeprueft = true; }
                this.istBereitsKontakt = false;
                this.showRegisterMessage = true;
                this.loadingVisible = false;
              }).catch((error) => {
                this.sendClicked = false;
                alert(error.statusText, this.translate.instant('REGISTER.FEHLER'));
                this.loadingVisible = false;
              });
            } else {
              funktionen.showPopupOK(this.translate.instant('REGISTER.FEHLER'), '<p>' + this.translate.instant('REGISTER.MITGLIED_NICHT_GEFUNDEN') +'</p>');

            }
            // }).catch((error) => {
            //   alert(error.error.text, 'Fehler');
            // });
          }
        }
      }).catch((error) => {
        this.sendClicked = false;
        alert(error.statusText, this.translate.instant('REGISTER.FEHLER'));
      });
	  } catch (error) {
        this.loadingVisible = false;
      }
    }
  }

  benutzernameKontrolle(e) {
    if (e) {
      for (const val of e.value) {
        if (this.benutzerPattern.indexOf(val) === -1) {
          return false;
        }
      }
      return true;
    }
  }
  updateMitgliedOnServer(params: string) {
    // TODO --fehende Daten auf Serer schicken
    console.log('Daten zu ergänzen: ' + params);
    // this.Service.authservice.putUrl('/portal/???', params).then( (data) => {
    //   console.log('Daten ergänzt', data);

    // });
  }

  async onClickSprache(langToSet: string) {
    let sp = localStorage.getItem('locale');
    if(sp !== langToSet) {
      this.loadingVisible = true;
      localStorage.setItem('locale', langToSet);
      this.translate.resetLang(langToSet);
      await this.translate.use(langToSet).toPromise().finally(() => {
        this.loadingVisible = false;
      });
  
      switch(langToSet) {
        case 'de':
          var de = document.getElementById("de");
          if(de) de.classList.remove('other-button-class');
          var it = document.getElementById("it");
          if(it) it.classList.add('other-button-class');
          var en = document.getElementById("en");
          if(en) en.classList.add('other-button-class');
          // this.limitierungNM = false;
          break;
        case 'it':
          var de = document.getElementById("de");
          if(de) de.classList.add('other-button-class');
          var it = document.getElementById("it");
          if(it) it.classList.remove('other-button-class');
          var en = document.getElementById("en");
          if(en) en.classList.add('other-button-class');
          // this.limitierungNM = true;
          break;
        case 'en':
          var de = document.getElementById("de");
          if(de) de.classList.add('other-button-class');
          var it = document.getElementById("it");
          if(it) it.classList.add('other-button-class');
          var en = document.getElementById("en");
          if(en) en.classList.remove('other-button-class');
          // this.limitierungNM = true;
          break;
      }
  
      this.reloadData();
    }
    // window.location.reload();
  }

  reloadData() {
    // if(this.limitierungNM) {
    //   this.daten['registrierungals'] = enuRegistrierungAls.Nichtmitglied;
    //   if(this.daten['registrierungalsErz'] === enuRegistrierungAls.Mitglied ) {
    //     this.daten['registrierungalsErz'] = enuRegistrierungAls.Nichtmitglied;
    //   }
    // }
    this.bezeichnungErZ = this.translate.instant('REGISTER.ERZIEHUNGSBERECHTIGTE_R');

    this.appRouteChangeService.reloadData();

    this.registrierungalsData = this.Service.getRegistrierungals();
    this.registrierungalsErzData = this.Service.getRegistrierungals();
    this.anrede = this.Service.getAnrede();
    this.anredeErz = this.Service.getAnrede();

    this.buttonInfoMitgliedsnummer = {
      icon: 'info',
      text: this.translate.instant('REGISTER.WOMITGLIEDSNUMMER'),
      hint: this.translate.instant('REGISTER.WOMITGLIEDSNUMMER'),
      type: 'success',
      stylingMode: 'text',
      useSubmitBehavior: false,
       onClick: (e) => {
        this.mitgliedsnummerPopupVisible = true;
      }
    };

    this.buttonInfoKontaktnummer = {
      icon: 'info',
      text: this.translate.instant('REGISTER.WOKONTAKTNUMMER'),
      hint: this.translate.instant('REGISTER.WOKONTAKTNUMMER'),
      type: 'success',
      stylingMode: 'text',
      useSubmitBehavior: false,
       onClick: (e) => {
        this.kontaktnummerPopupVisible = true;
      }
    };


    this.registerAlsSelectOptions = {
      dataSource: this.registrierungalsData,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
      disabled:!this.kontakte,
      onSelectionChanged:  (e) => {
        if (e.selectedItem && e.selectedItem.value === enuRegistrierungAls.Mitglied && this.registrierungals !== e.selectedItem.value) {
          this.registrierungals = enuRegistrierungAls.Mitglied;
          this.regMitglied = true;
          this.hatErzberechtigten = false;
          // if (localStorage.getItem('locale') !== 'de') {
          //   this.onClickSprache('de');
          // }
        } else if(this.registrierungals !== e.selectedItem.value) {
          this.registrierungals = enuRegistrierungAls.Nichtmitglied;
          this.regMitglied = false;
          if (!this.hatErzberechtigten && (this.daten.gebdatum > this.maxDate)) {
            // Erziehungseberechtigter erforderlich
            // if(this.limitierungNM) {
            //   this.daten['registrierungalsErz'] = enuRegistrierungAls.Nichtmitglied;
            // }
        
            this.daten.erzberechtigte.push(new Kontakt());
            this.hatErzberechtigten = true;
            this.karteNnummerOptions();
            funktionen.showPopupOK(this.translate.instant('REGISTER.MUSSTVOLLJAEHRIG'), this.translate.instant('REGISTER.GIBERZIEHUNGSBERECHTIGTENAN') + '<br>' +this.translate.instant('REGISTER.SPAETERBERECHTIGTEHINZUFUEGENLOESCHEN'));
          } else {
            this.hatErzberechtigten = false;
            this.daten.erzberechtigte = [];
          }
        }
      }
    };
    this.registerAlsErzSelectOptions = {
      dataSource: this.registrierungalsErzData,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
      disabled:!this.kontakte,
      onSelectionChanged: (e) => {
        if (e.selectedItem && e.selectedItem.value === enuRegistrierungAls.Mitglied) {
          this.registrierungalsErz = enuRegistrierungAls.Mitglied;
          this.regMitgliedErz = true;
          // if (localStorage.getItem('locale') !== 'de') {
          //   this.onClickSprache('de');
          // }
        } else {
          this.registrierungalsErz = enuRegistrierungAls.Nichtmitglied;
          this.regMitgliedErz = false;

        }
      }
    };
    this.removErzButtonOptions = {ource: this.anrede,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
    };

    this.anredeSelectOptions = {
      dataSource: this.anrede,
      searchEnabled: true,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
    };


    this.anredeErzSelectOptions = {
      dataSource: this.anredeErz,
      searchEnabled: true,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'value',
      displayExpr: 'text',
    };
    this.newsletterOptions = {
      dataSource: this.daten.newsletter,
      text: this.translate.instant('REGISTER.NEWSLETTERTEXT'),
    };

    this.buttonInfoDatenschutz = (data) => {
      return {      
        icon: 'info',
        text: this.translate.instant('REGISTER.DATENSCHUTZBESTIMMUNGEN'),
        hint: this.translate.instant('REGISTER.DATENSCHUTZBESTIMMUNGEN_OEFFNEN'),
        type: 'success',
        stylingMode: 'text',
        useSubmitBehavior: false,
        onClick: async (e) => {
          if(this.hatErzberechtigten) {
            data.mgd_id = this.daten.erzberechtigte[0].id;
          } else {
            data.mgd_id = this.daten.id;
          }
          let dsgvoVorhanden = await funktionen.openDSGVO('_blank', this.Service.authservice, enuDSGVOVertragTyp.Website, data);
          if(!dsgvoVorhanden){

            let param = '';
            param += '?ohnedatei=' + true;
            if(data.mgd_id) param += '&mgd_id=' + data.mgd_id;
            this.Service.authservice.getUrl('/portal/dsgvoliste' + param).then((data: any[]) => {
              this.dsgvoliste = data;
            });
          }        
        }
      }
    };

    this.geburtsdatumOptionsErz = {
      invalidDateMessage: this.translate.instant('REGISTER.GEBURTSDATUM_FORMAT'),
      dateOutOfRangeMessage: this.translate.instant('REGISTER.GEBURTSDATUM_ZWISCHEN'),
      displayFormat: { year: 'numeric', month: '2-digit', day: '2-digit', parser: funktionen.DateParser},
      min: this.minDate,
      max: this.today,
    };

    this.geburtsdatumOptions = {
      dataSource: this.daten.gebdatum,
      invalidDateMessage: this.translate.instant('REGISTER.GEBURTSDATUM_FORMAT'),
      dateOutOfRangeMessage: this.translate.instant('REGISTER.GEBURTSDATUM_ZWISCHEN'),
      displayFormat: { year: 'numeric', month: '2-digit', day: '2-digit', parser: funktionen.DateParser},
      min: this.minDate,
      max: this.today,
    };

    this.mitgliedsnummerErzOptions = {
      mask: '000-00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN')
    };

    this.mitgliedsnummerErzOptions2 = {
      mask: '00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN')
    };

    this.mitgliedsnummerOptions = {
      value: this.daten.mitgliedsnr,
      mask: '000-00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN'),
      onEnterKey: (e) => {
        this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
      },

    };

    this.mitgliedsnummerOptions2 = {
      value: this.daten.mitgliedsnr,
      mask: '000-00000',
      maskInvalidMessage: this.translate.instant('REGISTER.MITGLIEDSNUMMEREINGEBEN'),
      onEnterKey: (e) => {
        this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
      },

    };

    this.nummerOptions();
    this.karteNnummerOptions();

    
    // this.kartennummerOptions = {
    //   value: this.daten.nummer,
    //   mask: '######',
    //   maskInvalidMessage: this.translate.instant('REGISTER.KONTAKTNUMMEREINGEBEN'),
    //   onEnterKey: (e) => {
    //     this.onClickPruefen({validationGroup: {group: 'mitgliedKontaktValidator'}});
    //   },
    // };

    // this.kartennummerErzOptions = {
    //   mask: '00000',
    //   maskInvalidMessage: this.translate.instant('REGISTER.KONTAKTNUMMEREINGEBEN')
    // };

    this.anrede = this.Service.getAnrede();

    this.landSelectOptions = {
      dataSource: this.web_nationenStore,
      searchEnabled: true,
      displayExpr: 'name',
      valueExpr: 'id',
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      acceptCustomValue: false,
      noDataText: this.translate.instant('REGISTER.NATIONALITAETEINGEBEN'),
      onFocusIn: (e) => {
        e.event.target.setSelectionRange(0, -1);
      },
      onSelectionChanged: (e) => {
        if (e.selectedItem && this.daten.nationalitaet_kuerzel !==  e.selectedItem.iso3166) {
          // this.daten.nationalitaet_name = e.selectedItem.name;
          this.daten.nationalitaet_kuerzel = e.selectedItem.iso3166;

          this.daten.steuernummer = '';
          this.resetMitgliedsPruefung('mitgliedKontaktValidator');
        }

      }
    };

    this.land2SelectOptions = {
      dataSource: this.web_nationenStore2,
      searchEnabled: true,
      displayExpr: 'name',
      valueExpr: 'id',
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      acceptCustomValue: false,
      noDataText: this.translate.instant('REGISTER.LAND_EINGEBEN'),
      onFocusIn: (e) => {
        e.event.target.setSelectionRange(0, -1);
      },
      onSelectionChanged: (e) => {
        // this.daten.nationalitaet_name = e.selectedItem.name;
        if(e.selectedItem) {
          this.daten.land = e.selectedItem.name;
          this.daten.nation = e.selectedItem.iso3166;
        }
        // this.daten.steuernummer = '';
        // this.resetMitgliedsPruefung('mitgliedKontaktValidator');
      }
    };

    this.landErzSelectOptions = {
      dataSource: this.web_nationenStore,
      searchEnabled: true,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      valueExpr: 'id',
      displayExpr: 'name',
      noDataText: this.translate.instant('REGISTER.NATIONALITAET_EINGEBEN'),
      acceptCustomValue: false,
      onFocusIn: (e) => {
        e.event.target.setSelectionRange(0, -1);
      },
      onSelectionChanged: (e) => {
        if(e.selectedItem && this.daten.erzberechtigte[0].nationalitaet_kuerzel !==  e.selectedItem.iso3166) {
          this.daten.erzberechtigte[0].nationalitaet_name = e.selectedItem.name;
          this.daten.erzberechtigte[0].nationalitaet_kuerzel = e.selectedItem.iso3166;
          this.daten.erzberechtigte[0].steuernummer = '';
          this.resetMitgliedsPruefung('erzberechtKontaktValidator');
        }
      }
    };
    this.geburtsortSelectOptions = {
      dataSource: this.web_geburtsorteStore,
      searchEnabled: true,
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      minSearchLength: 3,
      noDataText: this.translate.instant('REGISTER.GEBURTSORT_EINGEBEN'),
      valueExpr: 'name',
      displayExpr: 'name',
      acceptCustomValue: false,
      onFocusIn: (e) => {
        e.event.target.setSelectionRange(0, -1);
      },
      onSelectionChanged: (e) => {
        if (e.selectedItem && this.daten.gebort !== e.selectedItem.name) {
          this.daten.gebort = e.selectedItem.name;
          this.resetMitgliedsPruefung('mitgliedKontaktValidator');
        }
      }
    };

    this.geburtsortErzSelectOptions = {
      dataSource: this.web_geburtsorteStore,
      displayExpr: 'name',
      valueExpr: 'name',
      placeholder: this.translate.instant('REGISTER.WAEHLEN'),
      searchEnabled: true,
      minSearchLength: 3,
      noDataText: this.translate.instant('REGISTER.GEBURTSORT_EINGEBEN'),
      acceptCustomValue: false,
      onFocusIn: (e) => {
        e.event.target.setSelectionRange(0, -1);
      },
      onSelectionChanged: (e) => {
        if(e.selectedItem && this.daten.erzberechtigte[0].gebort !== e.selectedItem.name) {
          this.daten.erzberechtigte[0].gebort = e.selectedItem.name;
          this.resetMitgliedsPruefung('erzberechtKontaktValidator');
        }
      }
  };
  this.provinzSelectOptions = {
    dataSource: this.provinzen,
    searchEnabled: true,
    placeholder: this.translate.instant('REGISTER.WAEHLEN'),
    noDataText: this.translate.instant('REGISTER.PROVINZ_EINGEBEN'),
    acceptCustomValue: false,
    onFocusIn: (e) => {
      e.event.target.setSelectionRange(0, -1);
    },
    onSelectionChanged: (e) => {
      if (e.selectedItem === 'Bozen' && (!this.gdService['orte'] || this.gdService['orte'].length === 0)) {
        // this.Service.ShowLoadPanel();
        this.gdService.getOrte(this.daten.land_id).then(
          (data) => {
            this.web_orte = data;
            this.web_orteStore = new DataSource({
              store: {
                data,
                type: 'array'
              },
              paginate: true,
              pageSize: 10,
            });
            this.ortSelectOptions = {
              dataSource: this.web_orteStore,
              searchEnabled: true,
              placeholder: this.translate.instant('REGISTER.WAEHLEN'),
              minSearchLength: 3,
              noDataText: this.daten.nation === 'IT' ? this.translate.instant('REGISTER.GEMEINDE_EINGEBEN') : this.translate.instant('REGISTER.ORT_EINGEBEN'),
              valueExpr: 'name',
              displayExpr: 'name',
              acceptCustomValue: false,
              onFocusIn: (e) => {
                e.event.target.setSelectionRange(0, -1);
              },
              onSelectionChanged: (e) => {
                if(e.selectedItem) {
                  this.daten.ort = e.selectedItem.name;
                  if (this.daten && this.daten.provinz === 'Bozen') { this.daten.plz = this.web_orte.find(el => el.id === e.selectedItem.id)['plz']; }
                }
                // const editor = this.formkontakt.instance.getEditor('mitglied.provinz');
                // if (editor) {
                //   editor.option('disabled', e.selectedItem !== 'Italien');
                // }
              }
            };
          }
        ).finally(() => {
          // this.Service.HideLoadPanel();
          // setTimeout(() => {
          //   this.ort.instance.focus();
          // });
        });
      }
      // const editor = this.formkontakt.instance.getEditor('mitglied.provinz');
      // if (editor) {
      //   editor.option('disabled', e.selectedItem !== 'Italien');
      // }
    }
  };
  }

  getMarginOuter() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
      case enuKunde.KFS: 
        return '0px';
      case enuKunde.Standard: 
      case enuKunde.Template: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '25px';
        }
    }

  }

  checkAlter() {
    if (!this.hatErzberechtigten && (this.daten.gebdatum > this.maxDate)) {
      // Erziehungseberechtigter erforderlich
      // if(this.limitierungNM) {
      //   this.daten['registrierungalsErz'] = enuRegistrierungAls.Nichtmitglied;
      // }
  
      this.daten.erzberechtigte.push(new Kontakt());
      this.hatErzberechtigten = true;
      this.karteNnummerOptions();
      funktionen.showPopupOK(this.translate.instant('REGISTER.MUSSTVOLLJAEHRIG'), this.translate.instant('REGISTER.GIBERZIEHUNGSBERECHTIGTENAN') + '<br>' +this.translate.instant('REGISTER.SPAETERBERECHTIGTEHINZUFUEGENLOESCHEN'));
    } else {
      this.hatErzberechtigten = false;
      this.daten.erzberechtigte = [];
    }
  }



}
