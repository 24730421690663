import * as funktionen from '../globals/functions';

export abstract class BaseWrapperFunctionsComponent {

  constructor(
  ) { }

  public DateTimeParser(value: any) {
    // Damit Funktion in html-Dateien aufrufbar ist
    return funktionen.DateTimeParser(value);
  }
  public DateParser(value: any) {
    // Damit Funktion in html-Dateien aufrufbar ist
    return funktionen.DateParser(value);
  }
  isEmptyDate(value): boolean {
    return funktionen.isEmptyDate(value);
  }
  isEmptyString(value): boolean {
    return funktionen.isEmptyString(value);
  }
  isEmptyBoolean(value): boolean {
    return funktionen.isEmptyBoolean(value);
  }
  isEmptyNumber(value): boolean {
    return funktionen.isEmptyNumber(value);
  }

  AdjustDropDownWindow(popupInstance: any, width: number, optionChangedHandler: any) {
    // const popupInstance = editorContainer.find(".dx-popup").dxPopup("instance");
    popupInstance.option('width', width);
    popupInstance.off('optionChanged', optionChangedHandler);
    popupInstance.on('optionChanged', optionChangedHandler);
  }

  onOpenedSBWith250(e) {
    this.AdjustDropDownWindow(e.component._popup, 250, this.optionChangedHandlerWidth250);
  }
  optionChangedHandlerWidth250(args) {
    if (args.name === 'width' && args.value < 250) {
        args.component.option('width', 250);
    }
  }

  onOpenedSBWith300(e) {
    this.AdjustDropDownWindow(e.component._popup, 300, this.optionChangedHandlerWidth300);
  }
  optionChangedHandlerWidth300(args) {
    if (args.name === 'width' && args.value < 300) {
        args.component.option('width', 300);
    }
  }
  onOpenedSBWith350(e) {
    this.AdjustDropDownWindow(e.component._popup, 350, this.optionChangedHandlerWidth350);
  }
  optionChangedHandlerWidth350(args) {
    if (args.name === 'width' && args.value < 350) {
        args.component.option('width', 350);
    }
  }

}
