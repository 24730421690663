import { GlobaleDatenService } from './../services/globaledaten.serice';
import { NewsletterService } from './../newsletter/newsletter.service';
import { enuAnrede, enuGeschlecht, enuMitgliedsPruefung, enuRegistrierungAls, enuSteuernummerPruefung, enuMitgliedstyp, enuZahlungsweise } from './../globals/enumerationen';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent} from 'devextreme-angular';
import validationEngine from 'devextreme/ui/validation_engine';
import { alert, custom } from 'devextreme/ui/dialog';
import CodiceFiscale from 'codice-fiscale-js';
import * as moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { SteuernummerService } from '../services/steuernummer.service';
import * as funktionen from '../globals/functions';
import Editor from 'devextreme/ui/editor/editor';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'typescript-collections/dist/lib/arrays';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-newsletter-abmelden',
  providers: [ NewsletterService ],
  templateUrl: './newsletterabmelden.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterAbmeldenComponent implements OnInit {
  public loadingVisible = false;
  public showAbmeldungMessage = false;
  public showErrorMessage = false;
  public errorMessage = '';


  constructor(
    public Service: NewsletterService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private gdService: GlobaleDatenService,
  ) {
    this.loadingVisible = true;
  }


  ngOnInit(): void {
    this.loadingVisible = true;

    this.authService.getUrl('/portal/servergesperrt').then((servergesperrt) => {
      if (!servergesperrt) {
        this.authService.deleteUrl('/portal/deletenewsletter?abmeldeid=' + this.route.snapshot.queryParams.abmeldeid).then( (data) => {
          if (data === '') {
            this.loadingVisible = false;
            this.showAbmeldungMessage = true;
            this.showErrorMessage = false;
          } else {
            this.showErrorMessage = true;
            this.showAbmeldungMessage = false;
            this.errorMessage += data['Fehlermeldung'] + '\n';
          }
        }).catch((error) => {
          this.showErrorMessage = true;
          this.showAbmeldungMessage = false;
          this.errorMessage += error.statusText + '\n';
          this.errorMessage = funktionen.fixUmlaute(this.errorMessage);
        });
      } else {
        this.showErrorMessage = true;
        this.showAbmeldungMessage = false;
        this.errorMessage += this.gdService.meldungServerGesperrt + '\n';
        this.errorMessage += ' Versuche es später noch einmal.' + '\n';
      }
    }).catch((error) => {
      console.log('Fehler', error);
      this.showErrorMessage = true;
      this.showAbmeldungMessage = false;
      this.errorMessage += this.gdService.meldungServerGesperrt + '\n';
      this.errorMessage += ' Versuche es später noch einmal.' + '\n';
    });

  }

}
