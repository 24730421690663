  <div class="formcontainer-iframe">
    <div class="white-border-iframe">
       
        <div class="wpb_wrapper">
          <h3 >{{'SPERRUNG.TEXT' | translate}}</h3>
          <div class="divider-wrap" data-alignment="default">
              <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%" data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
          </div>
          <div >
            <h5 >{{'SPERRUNG.BESCHREIBUNG' | translate}}</h5>
  
          </div>
          <dx-button width="100%" text="{{'JETZTNEULADEN' | translate }}" style="margin-top: 10px; margin-bottom: 10px; color: black;" width="200px" stylingMode="outlined" (onClick)="reload($event)"></dx-button>

        </div>

      </div>


  </div>



  
<dx-load-panel 
    #loadPanel 
    shadingColor="rgba(0,0,0,0.4)" 
    [(visible)]="this.loadingVisible" 
    [showIndicator]="true" 
    [showPane]="true" 
    [shading]="true" 
    [hideOnOutsideClick]="false" 
    [message]="this.translate.instant('DETAILSLADEN')">
</dx-load-panel>

