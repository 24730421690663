import { Injectable, NgModule } from '@angular/core';
import { BaseService } from '../base/base.service';
import { AuthService } from '../services/auth.service';
import { DataUrl } from '../services/dataurls';
import { GlobaleDatenService } from '../services/globaledaten.serice';
import { enuZahlungsweise, enuAnrede, enuRegistrierungAls } from './../globals/enumerationen';
import { Mitglied} from './mitglied.model';
import { AppConfig } from '../app.config';
import { TranslateService } from '@ngx-translate/core';

const anrede = new Array();
anrede.push({text: enuAnrede.GetName(enuAnrede.Herr), value: enuAnrede.Herr});
anrede.push({text: enuAnrede.GetName(enuAnrede.Frau), value: enuAnrede.Frau});

let anredeTranslated = new Array();


const registrierungals = new Array();
registrierungals.push({text: enuRegistrierungAls.GetName(enuRegistrierungAls.Mitglied), value: enuRegistrierungAls.Mitglied});
registrierungals.push({text: enuRegistrierungAls.GetName(enuRegistrierungAls.Nichtmitglied), value: enuRegistrierungAls.Nichtmitglied});


export class DataUrlMitglied extends DataUrl {
  url: string;
  constructor() {
    super();
    this.url = '/portal/mitglied';
  }
}
@Injectable()
export class MitgliederService extends BaseService {
  public mitglied: Mitglied;
  public geburtsorte: any;
  public countries: any;
  public provinzen: any;
  public sektionen: any;

  zahlschein = AppConfig.kunde_settings.zahlschein;
  lastschrift = AppConfig.kunde_settings.lastschrift;
  kreditkarte = AppConfig.kunde_settings.kreditkarte;

  constructor(
    public authservice: AuthService,
    public gdService: GlobaleDatenService,
    public dataUrl: DataUrlMitglied,
    public translate: TranslateService

  ) {
    super(authservice, dataUrl);
    this.mitglied = new Mitglied(gdService.getNewsletter());


  }

    // get item(): Mitglied {
    //   return this.mitglied as Mitglied;
    // }

    createMitglied(): Mitglied {
      return new Mitglied(this.gdService.getNewsletter());
    }

    getMitglied() {
        return this.mitglied;
    }

    getGeschlecht() {
        return anrede;
    }

    getGeschlechtTranslated() {
      anredeTranslated = new Array();
      anredeTranslated.push({text: enuAnrede.GetNameTranslated(enuAnrede.Herr, this.translate), value: enuAnrede.Herr});
      anredeTranslated.push({text: enuAnrede.GetNameTranslated(enuAnrede.Frau, this.translate), value: enuAnrede.Frau});
      return anredeTranslated;
  }


    getZahlungsweiseTranslated() {
      const zahlungsweiseTranslated = new Array();
      if (this.zahlschein) { zahlungsweiseTranslated.push({text: enuZahlungsweise.GetNameTranslated(enuZahlungsweise.Zahlschein, this.translate), value: enuZahlungsweise.Zahlschein}); }
      if (this.zahlschein) { zahlungsweiseTranslated.push({text: enuZahlungsweise.GetNameTranslated(enuZahlungsweise.EUZahlschein, this.translate), value: enuZahlungsweise.EUZahlschein}); }
      if (this.lastschrift) { zahlungsweiseTranslated.push({text: enuZahlungsweise.GetNameTranslated(enuZahlungsweise.Lastschrift, this.translate), value: enuZahlungsweise.Lastschrift}); }
      if (this.kreditkarte) { zahlungsweiseTranslated.push({text: enuZahlungsweise.GetNameTranslated(enuZahlungsweise.Kreditkarte, this.translate), value: enuZahlungsweise.Kreditkarte}); }

      return zahlungsweiseTranslated;
  }

    getRegistrierungals() {
      return registrierungals;
    }

    getRegistrierungalsTranslated() {
      const registrierungalsT = new Array();
      registrierungalsT.push({text: enuRegistrierungAls.GetNameTranslated(enuRegistrierungAls.Mitglied, this.translate), value: enuRegistrierungAls.Mitglied});
      registrierungalsT.push({text: enuRegistrierungAls.GetNameTranslated(enuRegistrierungAls.Nichtmitglied, this.translate), value: enuRegistrierungAls.Nichtmitglied});
      return registrierungalsT;
    }

    checkIBAN(iban: string) {
      console.log('iban');
      return false;
      // return IBAN.isValid(iban);
    }

    async getErzberechtigteServer(): Promise <any> {
      let data: any[];
      data = [];
      return data;
    }


}
