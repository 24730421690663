<div class="inner-wrap" style="background-color:#f4f4f4; padding:40px;">
  <!-- <div *ngIf="this.successMessage" class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
      <h3>Der Vorgang ist fast abgeschlossen</h3>
      <div class="divider-wrap" data-alignment="default">
        <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%"
          data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
      </div>
      <p>Der Vorgang ist fast abgeschlossen<br>Du erhältst in Kürze eine Email in der du das neue Passwort noch bestätigen musst.</p>
    </div>
  </div> -->
  
  <div >
      <div class="wpb_text_column wpb_content_element ">
      <div class="wpb_wrapper">
        <h3>{{'RESETPASSWORD2.PASSWORT_ZURUECKSETZEN' |translate }}</h3>
        <div class="divider-wrap" data-alignment="default">
          <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%"
            data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
        </div>
        <p></p>
      </div>
    </div>

    <div class="card-block">
      <form [formGroup]="myForm" style="max-width: 450px;"> 
        <dx-form [(formData)]="myForm" [colCount]="1" [labelLocation]="'top'"[showValidationSummary]="true" validationGroup="loginData">
        <br>
        <dxi-item itemType="simple"><p>{{ 'RESETPASSWORD2.PASSWORT_ANFORDERUNGEN' |translate }}</p>
        </dxi-item>
          <dxi-item itemType="group">
            <dxi-item dataField="password" type="required" [editorOptions]="passwordfieldOptions">
              <dxo-label text="{{'RESETPASSWORD2.PASSWORD' | translate}}"></dxo-label>
              <dxi-validation-rule type="required" message="{{'RESETPASSWORD2.PASSWORT_EINGEBEN' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [min]="8" message="{{'RESETPASSWORD2.PASSWORT_MIN_8' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="pattern" [pattern]="kennwortPattern"
              message="{{'RESETPASSWORD2.PASSWORT_ANFORDERUNGEN' | translate}}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="" type="required" [editorOptions]="passwordfieldOptions2">
              <dxo-label text="{{'RESETPASSWORD2.PASSWORT_BESTAETIGUNG' | translate}}"></dxo-label>
              <dxi-validation-rule type="required" message="{{'RESETPASSWORD2.PASSWORT_EINGEBEN' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"  message="{{'RESETPASSWORD2.PASSWOERTER_STIMMEN_NICHT' | translate}}">
              </dxi-validation-rule>
            </dxi-item>

          </dxi-item> 
          <dxi-item itemType="group" [colCount]="2" horizontalAlignment="right" >
            <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="cancelButtonOptions"></dxi-item>
            <dxi-item itemType="button"[colSpan]="2" horizontalAlignment="right" [buttonOptions]="resetButtonOptions"></dxi-item>
          </dxi-item>
        </dx-form>
      </form>
      <br />
    </div>
    <!-- <div *ngIf="iexplorer" class="card-block">
      <p class="text-muted">Ihr Browser wird nicht unterstützt.<br><br>
        Bitte verwenden Sie <a href="https://www.google.com/chrome/">Google Chrome</a> oder <a
          href="https://www.mozilla.org/de/firefox/new/">Mozilla Firefox</a> als Browser.</p>
    </div> -->
  </div>
</div>