import { CommonModule } from '@angular/common';
import { Component, NgModule, NgZone } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  DxButtonModule, DxLoadPanelModule } from 'devextreme-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { GlobaleDatenService } from '../services/globaledaten.serice';
import { AppConfig } from '../app.config';


@Component({
  selector: 'app-sperrung',
  templateUrl: './sperrung.component.html',
  styleUrls: ['./sperrung.component.scss']
})
export class SperrungComponent {

  loadingVisible: boolean;
  constructor(
    private authService: AuthService, 
    private router: Router,
    public gdService: GlobaleDatenService,
    public route: ActivatedRoute, 
    private ngZone: NgZone,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,

  ) { 

    this.checkSperrung()
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface, @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {

  }

  checkSperrung() {
    if(!AppConfig.settings.sperrung) {
      window.location.replace(window.location.origin + '/login');
    }
  }
  reload(e) {
    if(e && e.event) {
      window.location.reload();
    }
  }


}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    DxLoadPanelModule,
    DxButtonModule,
    TranslateModule,
  ],
  declarations: [ SperrungComponent],
  exports: [ SperrungComponent ]
})
export class SperrungModule { }
