import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig, IKundeConfig } from './app-config.model';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    static kunde_settings: IKundeConfig;
    constructor(private http: HttpClient) {}
    load() {

        const jsonFile = `assets/config.json`;
        return new Promise<void>((resolve, reject) => {
            let source =  this.http.get(jsonFile).pipe();
            firstValueFrom(source).then(async (response: IAppConfig) => {
                AppConfig.settings =  response as IAppConfig;
                const kunde_jsonFile = `assets/config/kunde_config.json`;
                await new Promise<void>((resolve, reject) => {
                    let kunde_source = this.http.get(kunde_jsonFile).pipe();
                    firstValueFrom(kunde_source).then((response: IKundeConfig) => {
                        AppConfig.kunde_settings = new IKundeConfig();
                        AppConfig.kunde_settings.setData(response as IKundeConfig);
                        resolve();
                    }).catch((response: any) => {
                       reject(`Could not load file '${kunde_jsonFile}': ${JSON.stringify(response)}`);
                    });
                });
                resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
