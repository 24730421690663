import { Component } from '@angular/core';
import { AppRouteChangeService } from './services/app-route-change.service';
import { GlobaleDatenService } from './services/globaledaten.serice';
import { GlobalVariable } from './globals/globals';
import { Router } from '@angular/router';
import { enuKunde } from './app-config.model';
import { AppConfig } from './app.config';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // selectedPage = 3;
  title: string;

  updateLiveReloadTimer: any;
  updateLiveReloadToast: boolean = false;
  updateLiveReloadMessage: string = '';

  constructor(
    public gdService: GlobaleDatenService,
    public appRouteChangeService: AppRouteChangeService,
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService
  ) {
    gdService.getSektionenArray().then(
      (data) => {
        // this.sektionen = data;
      }
    );
    if(GlobalVariable && GlobalVariable['version']) localStorage.setItem('Version', GlobalVariable['version']);

    if(this.gdService.istKunde(enuKunde.Standard) || this.gdService.istKunde(enuKunde.Template)) {
      let element1: HTMLElement = document.getElementById('indexmitgliedwerden') as HTMLElement;
      if(element1) element1.onclick = () => { this.router.navigateByUrl('/mitgliedneu') };

      let element2: HTMLElement = document.getElementById('indexkurse') as HTMLElement;
      if(element2) element2.onclick = () => { this.router.navigateByUrl('/veranstaltungen') };
    }

    if(AppConfig.settings.updateLiveReload && !this.updateLiveReloadTimer && AppConfig.settings.secondsBeforeReload) {
      let versionunterschiedlich = false;
      this.updateLiveReloadTimer = setInterval(()=> {
        this.authService.getUrl('/daten/version').then((version) => {
          if(version !== GlobalVariable['version'] && !versionunterschiedlich) {
            versionunterschiedlich = true;
            let timeLeft = Number(AppConfig.settings.secondsBeforeReload);
            setInterval(() => {
              this.updateLiveReloadToast = true;
              if(timeLeft > 0) {
                timeLeft--;
                this.updateLiveReloadMessage = this.translate.instant('LIVERELOAD').replace('__time__', timeLeft);
              } else {
                window.location.reload();
              }
            },1000);
            console.log('Versionen unterschiedlich Lokal: ' + GlobalVariable['version'] + ' Online: ' + version);
          }
        });
      }, 60000);
    }
  }

  onClickReload(e) {
    window.location.reload();
  }
}
