import * as moment from 'moment';
import 'moment/locale/de';
// import * as _ from 'micro-dash';
import { alert, custom } from 'devextreme/ui/dialog';
import { AppConfig } from '../app.config';
import dxValidator from 'devextreme/ui/validator';
import { AuthService } from '../services/auth.service';

import { saveAs } from 'file-saver';
import { enuKunde } from '../app-config.model';
import { GlobaleDatenService } from '../services/globaledaten.serice';

export const StringFormat = (str: string, ...args: any[]) =>
  str.replace(/{(\d+)}/g, (match, index) => args[index] || '');

export function isEmptyString(value): boolean {
  if (typeof value !== 'undefined' && value) {
    return false;
  }
  return true;
}
export function isEmptyNumber(value): boolean {
  if (typeof value !== 'undefined' && (value || value === 0)) {
    return false;
  }
  return true;
}
export function isEmptyNumberEx(value): boolean {
  // Diese Funktion funktioniert gleich wie isEmptyNumber, mit der Ausnahme, dass Zahlen mit Datentyp = string nicht erkannt werden.
  return (typeof value !== 'number' || Number.isNaN(value));
}
export function isEmptyBoolean(value): boolean {
  if (typeof value !== 'undefined') {
    return false;
  }
  return true;
}
export function isEmptyBooleanEx(value): boolean {
  // Mark: Ich denke das sollte die richtige Abfrage sein. Ob das jetzt aber überall richtig eingesetzt wird, bin ich mir nicht sicher.
  return (typeof value !== 'boolean');
}
export function isEmptyDate(value): boolean {
  if (typeof value !== 'undefined' && value) {
    return false;
  }
  return true;
}

export function CoalesceNumber(...args: number[]): number {
  for ( const arg of args) {
    if (!isEmptyNumber(arg)) { return arg; }
  }
  return null;
}

export function GetDate(value): Date {
  if (typeof value === 'object') {
    return value;
  }
  return new Date(value);
}

export function GetCurrentDate(): Date {
  const d = moment(new Date());
  return d.hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
}
export function GetCurrentDateTime(): Date {
  return new Date();
}
export function DateTimeParser(value) {
  // console.log('DateTimeParser', value);
  const d = moment(value, 'DD.MM.YYYY, HH:mm');
  return d.toDate();
}
export function DateTimeParserMinutes(value) {
  // console.log('DateTimeParser', value);
  const d = moment(value, 'DD.MM.YYYY, HH:mm');
  return d.seconds(0).milliseconds(0).toDate();
}
export function DateParser(value) {
  // console.log('myDateParser', value);
  const d = moment(value, 'DD.MM.YYYY');
  return d.toDate();
}
export function DateParserWithoutTime(value) {
  // console.log('myDateParser', value);
  const d = moment(value, 'DD.MM.YYYY').hours(0).minutes(0).seconds(0).milliseconds(0);
  return d.toDate();
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export function Round(zahl: number, n_stelle: number): number {
  n_stelle = Math.pow(10, n_stelle);
  return (Math.round(zahl * n_stelle) / n_stelle);
}

export function pad(num: number, size: number): string {
  const s = '000000000' + num;
  return s.substr(s.length - size);
}

// export function cloneDeep(data: any) {
//   return _.cloneDeep(data);
// }

// export function clone(data: any) {
//   // Arrays kann man auch so kopieren.
//   // return Object.assign([], data);
//   return _.clone(data);
// }

export function RaiseAlert(error: any): Promise<any> {
  return new Promise<void>((resolve) => {
    if (AppConfig.settings.debug) { console.log('Fehler ', error); }
    if (error.status && error.statusText) {
      alert(error.statusText, 'Fehler');
    } else {
      alert(error.message, 'Fehler');
    }
    resolve();
  });
}

export function CopyTextToClipboard(text: string, showalert: string= null): void {
  const listener = (e: ClipboardEvent) => {
    e.clipboardData.setData('text/plain', (text));
    e.preventDefault();
  };
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
  if (showalert != null) { alert(showalert, 'Zwischenablage'); }
}

export function scrollToElementID(id: string): void {
  const el = document.getElementById(id);
  el.scrollIntoView();
}

export function showPopupOK(title: string, htmlmessage: string): void {
  const myDialog = custom({
      title,
      messageHtml: htmlmessage,
      buttons: [
          {
            text: 'OK',
            onClick: () => {
              return;
            }
          }
      ]
    });
  myDialog.show();
  return;
}
export function showPopupOKCancel(title: string, htmlmessage: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
      const myDialog = custom({
        title,
        messageHtml: htmlmessage,
        buttons: [
            {
              text: 'OK',
              onClick: () => {
                resolve(true);
              }
            },
            {
              text: 'abbrechen',
              onClick: () => {
                resolve(false);
              }
            }
        ]
      });
      myDialog.show();
  });
}

export function getBrokenRules(result: any): string{
  let error = '';
  for (const rule of result.brokenRules) {
    error += '&#8226; ' + rule.message + '<br>';
  }
  return error;
}

export function getDatumDeutsch(da:string):string {
  const kalender= {
    firstDayOfWeek: 1,
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    today: 'Heute',
    clear: 'Clear'
  };
  const d=GetDate(da);
  const dayName = kalender.dayNames[d.getDay()];
  const monthName = kalender.monthNames[d.getMonth()];
  const datum=dayName+', '+d.getDate()+'. '+monthName+' '+d.getFullYear();
  return datum;  
}

export function openDSVG(target, authService: AuthService, typ, TranslateService = null) {
  authService.getBlobUrl('/portal/dsgvovertrag?typ=' + typ).then( (data) => {
    if(data.size > 0) {
      saveAs(data, 'Information-der-Datenschutzverordnung.pdf');
    } else {
      alert('DSGVO nicht angegeben', 'Fehler');
    }
    // window.open('/assets/Information-der-Datenschutzverordnung.pdf', target);
  });
}

 export async function openDSGVO(target, authService: AuthService, typ, data = null, benutzername: string = null) {
  let dsgvoVorhanden;

  if (data && !data.datei) {
    let params = '';
    if (benutzername) {
      params += '&benutzername=' + benutzername;
    }

    if (!data.datei) {
      params += '&vertragdokument_id=' + data.vertragdokument_id;

      if (data.mgd_id) params += '&mgd_id=' + data.mgd_id;
    }

    dsgvoVorhanden = await authService.getBlobUrl('/portal/dsgvovertrag?typ=' + typ + params).then((ret) => {
      if (ret.size > 0) {
        saveAs(ret, 'Information-der-Datenschutzverordnung.pdf');
        return true;
      } else {
        return false;
      }
      // window.open('/assets/Information-der-Datenschutzverordnung.pdf', target);
    });
  } else {
    const blob = base64toBlob(data.datei);
    saveAs(blob, 'Information-der-Datenschutzverordnung.pdf');
    dsgvoVorhanden = true;
  }
  return dsgvoVorhanden;
}

export function openAGB(gdService: GlobaleDatenService, target) {
  if (gdService.istKunde(enuKunde.AVS)) {
    window.open('https://alpenverein.it/veranstaltungen-und-kurse/agb/', target);
  } else {
    window.open('/assets/AGB.pdf', target);
  }
}

export function fixUmlaute(value) {
  value = value.replace(/&auml;/, 'ä');
  value = value.replace(/&ouml;/, 'ö');
  value = value.replace(/&uuml;/, 'ü');
  value = value.replace(/&szlig;/, 'ß;');
  value = value.replace(/&Auml;/, 'Ä');
  value = value.replace(/&Ouml;/, 'Ö;');
  value = value.replace(/&Uuml;/, 'Ü');
  value = value.replace(/&agrave;/, 'à');
  return value;
}

export function argbToHex(color: any) {
  return '#'+ ('000000' + (color & 0xFFFFFF).toString(16)).slice(-6);
}

export function addOpacitytoHex(color, opacity) {
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function base64toBlob(dataURI) {
  // convert base64 data component to raw binary data held in a string
  let byteString;
  byteString = atob(dataURI);
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia]);
}

export function filetoBase64(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export function testGuuid(guid:string) {
  return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(guid);
}

export function indexedDBSelectAll(objectstore: any) { 
  return new Promise<any>((resolve, reject) => {
    const openDBrequest = window.indexedDB.open("WebcomponentSoftware");
    let db: IDBDatabase;
    openDBrequest.onerror = () => {
      reject(openDBrequest.error);
    };
    openDBrequest.onsuccess = (event: any) => {
      if(!event) return;
      db = event.target['result'];
      const transaction: IDBTransaction = db.transaction(objectstore);
      const objectStore: IDBObjectStore = transaction.objectStore(objectstore);
      let request: IDBRequest;
      request = objectStore.getAll();
      request.onerror = () => {
        reject(request.error);
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
    };
    openDBrequest.onupgradeneeded = (event: any) => {
      //Sollte die Datenbank nicht existieren dann wird nichts zurückgegeben
      if(!event) return;
      db = event.target['result'];
      const objectStore = db.createObjectStore(objectstore);
      objectStore.transaction.oncomplete = () => {
        resolve(undefined);
      };
    };
  });
}

export function indexedDBDelete(objectstore: any, key: any) {
  return new Promise<any>((resolve, reject) => {
    const openDBrequest = window.indexedDB.open("WebcomponentSoftware");
    let db: IDBDatabase;
    openDBrequest.onerror = () => {
      reject(openDBrequest.error);
    };
    openDBrequest.onsuccess = (event: any) => {
      if(!event) return;
      db = event.target['result'];
      const transaction = db.transaction(objectstore, "readwrite");
      const objectStore = transaction.objectStore(objectstore);
      const request = objectStore.delete(key);
      request.onerror = () => {
        reject(request.error);
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
    };
    openDBrequest.onupgradeneeded = () => {
      //Sollte die Datenbank nicht existieren
      reject();
    };
  });
}

export function indexedDBUpdate(objectstore: any, key: any, value: any) {
  //Updated oder erstellt die entsprechende Zeile in der Datenbank
  return new Promise<any>((resolve, reject) => {
    const openDBrequest = window.indexedDB.open("WebcomponentSoftware");
    let db: IDBDatabase;
    openDBrequest.onerror = () => {
      reject(openDBrequest.error);
    };
    openDBrequest.onsuccess = (event: any) => {
      if(!event) return;
      db = event.target['result'];
      const transaction: IDBTransaction = db.transaction(objectstore, "readwrite");
      const objectStore: IDBObjectStore = transaction.objectStore(objectstore);
      const request: IDBRequest = objectStore.put(value, key);
      request.onerror = () => {
        reject(request.error);
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
    };
    openDBrequest.onupgradeneeded = (event: any) => {
      //Sollte die Datenbank nicht existieren so wird diese erstellt
      if(!event) return;
      db = event.target['result'];
      const objectStore = db.createObjectStore(objectstore);
      objectStore.transaction.oncomplete = () => {
        const transaction: IDBTransaction = db.transaction(objectstore, "readwrite");
        const objectStore: IDBObjectStore = transaction.objectStore(objectstore);
        const request: IDBRequest = objectStore.put(value, key);
        request.onerror = () => {
          reject(request.error);
        };
        request.onsuccess = () => {
          resolve(request.result);
        };
      };
    };
  });
}

export function indexedDBSelect(objectstore: any, key: any) { 
  return new Promise<any>((resolve, reject) => {
    const openDBrequest = window.indexedDB.open("WebcomponentSoftware");
    let db: IDBDatabase;
    openDBrequest.onerror = () => {
      reject(openDBrequest.error);
    };
    openDBrequest.onsuccess = (event: any) => {
      if(!event) return
      db = event.target['result'];
      const transaction: IDBTransaction = db.transaction(objectstore);
      const objectStore: IDBObjectStore = transaction.objectStore(objectstore);
      let request: IDBRequest;
      request = objectStore.get(key);
      request.onerror = () => {
        reject(request.error);
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
    };
    openDBrequest.onupgradeneeded = (event: any) => {
      //Sollte die Datenbank nicht existieren dann wird nichts zurückgegeben
      if(!event) return;
      db = event.target['result'];
      const objectStore = db.createObjectStore(objectstore);
      objectStore.transaction.oncomplete = () => {
        resolve(undefined);
      };
    };
  });
}
