import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logout-component',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    // private deviceService: DeviceDetectorService,
    private router: Router,
    public route: ActivatedRoute,
  ) {
  
  }

  ngOnInit() {
    this.authService.logout(false);
  }

}
