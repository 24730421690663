import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
// import * as cloneDeep from 'lodash/cloneDeep';
// import * as _ from 'lodash';
import * as _ from 'micro-dash';

export enum enuRowState {
  empty = 1,
  new = 2,
  unchanged = 3,
  modified = 4,
  deleted = 5,
}

export class BaseItemData {
  public id: string;

  constructor() {
  }

  public SetData(data: any[]) {
    this.SetDataIntern(data);
    this.AfterSetData();
  }
  protected SetDataIntern(data: any[]) {
    // console.log((<any>this).constructor.name, 'SetDataIntern', data['id']);
    for (const ind in data) {
      this[ind] = data[ind];
    }
  }
  // Hier sollen die Datentypen konvertiert werden.
  public AfterSetData() {
    // Nichts zu tun
  }

  public GetItemAsArray(): Object {
    const a = new Object;
    return a;
  }
}

export class BaseItem extends BaseItemData {
  public orgData: Array<any>;
  public state: enuRowState;
  public rechte: Array<boolean>;
  public hatRegion: boolean;
  public hatSektion: boolean;
  protected setorgdata: boolean = true;

  constructor() {
    super();
    this.state = enuRowState.empty;
    this.rechte = new Array();
    this.hatRegion = false;
    this.hatSektion = false;
    this.orgData = new Array();
  }

  GetTitle(): string {
    if (this.state === enuRowState.empty) return '[Leer]';
    return this.id;
  }

  public SetData(data: any[]) {
    // Eigentlich wird folgender Code bereits über die Funktion SetDataIntern gesetzt. Deshalb wurde er hier auskommentiert.
    // this.state = enuRowState.unchanged;
    super.SetData(data);
    if (this.setorgdata) this.SetOrgData(data);
  }

  protected SetDataIntern(data: any[]) {
    super.SetDataIntern(data);
    this.state = enuRowState.unchanged;
  }

  public SetDataFromRow(data: any[]) {
    super.SetData(data);
  }

  public SetDataFromItem( item: BaseItem) {
    this.SetDataFromItemItern(item);
    this.AfterSetData();
  }
  protected SetDataFromItemItern( item: BaseItem) {
    this.id = item.id;
    this.state = item.state;
  }

  public SetDate(identifier: string) {
    if (this[identifier]) {
      this[identifier] = moment(this[identifier]).toDate();
    }
  }
  public SetNumber(identifier: string) {
    if (this[identifier]) {
      this[identifier] = Number(this[identifier]);
    }
  }

  protected SetOrgData(data) {
    // console.log((<any>this).constructor.name, 'SetOrgData');

    // const t0 = performance.now();
    for (const ind in data) {
      if (this[ind] instanceof Array) {
        this.orgData[ind] = _.cloneDeep(this[ind]); // this[ind].slice(0);
      } else {
        this.orgData[ind] = this[ind];
      }
    }
    // const t1 = performance.now();
    // if ((t1 - t0) > 0.1) {
    //   console.log('SetOrgData ' + (t1 - t0) + ' milliseconds.', t1, t0, data)
    // }
  }

  public GetChangesArray(orgdata, data) {
    if (orgdata && data) {
      if (orgdata instanceof Array || orgdata instanceof Object) {
        for (const ind1 in orgdata) {
          if (!_.isEqual(orgdata[ind1], data[ind1])) {
            console.log((<any>this).constructor.name, 'HasChanges', ind1, 'orgData', orgdata[ind1], 'this', data[ind1]);
            this.GetChangesArray(orgdata[ind1], data[ind1]);
          }
        }
      }
    }
  }
  public HasChanges(): boolean {
    if (this.state === enuRowState.new) {
      return true;
    }
    for (const ind in this.orgData ) {
      // console.log(_.isEqual(this.orgData[ind], this[ind]));
      if (this.orgData[ind] instanceof Date && this[ind] instanceof Date) {
        if (this.orgData[ind] !== this[ind]) {
          console.log((<any>this).constructor.name, 'HasChanges', ind, 'orgData', this.orgData[ind], 'this', this[ind]);
          this.GetChangesArray(this.orgData[ind], this[ind]);
          return true;
        }
      } else if (!_.isEqual(this.orgData[ind], this[ind])) {
        console.log((<any>this).constructor.name, 'HasChanges', ind, 'orgData', this.orgData[ind], 'this', this[ind]);
        this.GetChangesArray(this.orgData[ind], this[ind]);
        return true;
      }
    }
    return false;
  }

  public ResetChanges() {
    for (const ind in this.orgData ) {
      this[ind] = this.orgData[ind];
    }
    if (this.orgData && this.orgData['id']) {  // Objekt mit Daten befüllt
      this.state = enuRowState.unchanged;
    } else {  // Objekt Leer
      this.state = enuRowState.empty;
    }
  }

}
