
<div class="container-wrap">
  <div class="container main-content">
		<div class="row">
			
<div class="inner-wrap" style="background-color:#f4f4f4; padding:40px;">
  <div *ngIf="this.successMessage" class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
      <h3>{{'RESETPASSWORD.VORGANG_ABGESCHLOSSEN' |translate }}</h3>
      <div class="divider-wrap" data-alignment="default">
        <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%"
          data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
      </div>
      <p>{{'RESETPASSWORD.VORGANG_ABGESCHLOSSEN' |translate }}<br>{{'RESETPASSWORD.EMAIL_PASSWORT_BESTAETIGEN' |translate }}</p>
    </div>
  </div>
  <div   *ngIf="!this.successMessage">
    <div *ngIf="vergessentyp === 1" class="wpb_text_column wpb_content_element ">
      <div class="wpb_wrapper">
        
        <!-- sprachauswahl -->
        <app-sprachauswahl (onReloadData)="this.reloadData()" ></app-sprachauswahl>

        <h3 *ngIf="this.gdService.mitglied_id">{{'RESETPASSWORD.PASSWORT_VERGESSEN' |translate }}</h3>
        <h3 *ngIf="!this.gdService.mitglied_id">{{'RESETPASSWORD.BENUTZERNAME_VERGESSEN' |translate }}</h3>
        <div class="divider-wrap" data-alignment="default">
          <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%"
            data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
        </div>
        <p></p>
      </div>
    </div>
    <div *ngIf="!iexplorer && vergessentyp === 1" class="card-block">
      <form [formGroup]="myForm2" style="max-width: 450px;"> 
        <dx-form [(formData)]="myForm2"  [colCount]="1" [labelLocation]="'top'"[showValidationSummary]="true" validationGroup="loginData2">
          <dxi-item itemType="group">
            <dxi-item dataField="email" [editorOptions]="userfieldOptions">
              <dxo-label text="{{'RESETPASSWORD.EMAIL' |translate }}"></dxo-label>
              <dxi-validation-rule type="email" message="{{'RESETPASSWORD.EMAIL_UENGUELTIG' |translate }}"></dxi-validation-rule>
              <dxi-validation-rule type="required" message="{{'RESETPASSWORD.EMAIL_EINGEBEN' |translate }}">  </dxi-validation-rule>

              <!-- <dxi-validation-rule type="email" message="Die E-Mail Adresse ist ungültig"></dxi-validation-rule> -->
            </dxi-item>
          </dxi-item>
        <br>
          <dxi-item itemType="group" [colCount]="2" horizontalAlignment="right" >
            <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="cancelButtonOptions"></dxi-item>

            <dxi-item itemType="simple" [colSpan]="2" horizontalAlignment="right">
              <div *dxTemplate style="text-align: right;">
                  <dx-button [text] text="{{'RESETPASSWORD.ANFORDERN' |translate }}" (onClick)="okRequest($event)" [template]="'buttonTemplate'" horizontalAlignment="right" validationGroup="loginData2">
                      <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                        <app-button [buttonData]="buttonData"></app-button>
                      </div>
                  </dx-button>
              </div>
           </dxi-item>
          </dxi-item>
          <dxi-item *ngIf="!this.isloggedIn" itemType="simple" [colSpan]="1" horizontalAlignment="right" style="padding-top: 10px;">
            <a  href="javascript: void(0);"  class="link_text" (click)="wechsel()">{{'RESETPASSWORD.PASSWORT_VERGESSEN2' |translate }}</a>
          </dxi-item>
        </dx-form>
      </form>
      <br />
    </div>
    <div *ngIf="vergessentyp === 2" class="wpb_text_column wpb_content_element ">
      <div class="wpb_wrapper">
        <!-- sprachauswahl -->
        <app-sprachauswahl (onReloadData)="this.reloadData()" ></app-sprachauswahl>

        <h3 *ngIf="!this.isloggedIn" >{{'RESETPASSWORD.PASSWORT_VERGESSEN' |translate }}</h3>
        <h3 *ngIf="this.isloggedIn" >{{'RESETPASSWORD.PASSWORT_AENDERN' |translate }}</h3>
        <div class="divider-wrap" data-alignment="default">
          <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%"
            data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
        </div>
        <p></p>
      </div>
    </div>

    <div *ngIf="!iexplorer && vergessentyp === 2" class="card-block">
      <form [formGroup]="myForm" style="max-width: 450px;"> 
        <dx-form [(formData)]="myForm"  [colCount]="1" [labelLocation]="'top'"[showValidationSummary]="true" validationGroup="loginData">
          <dxi-item itemType="group">
            <dxi-item dataField="benutzername" [editorOptions]="userfieldOptions">
              <dxo-label text="{{'RESETPASSWORD.BENUTZERNAME' |translate }}"></dxo-label>
              <dxi-validation-rule type="custom"  message="{{'RESETPASSWORD.BENUTZERNAME_UNGUELTIGE_ZEICHEN' |translate }}" [validationCallback]="benutzernameKontrolle"></dxi-validation-rule>
              <dxi-validation-rule type="required" message="{{'RESETPASSWORD.BENUTZERNAME_EINGEBEN' |translate }}">  </dxi-validation-rule>
              <!-- <dxi-validation-rule type="email" message="Die E-Mail Adresse ist ungültig"></dxi-validation-rule> -->
            </dxi-item>
          </dxi-item>
        <br>
          <dxi-item itemType="group" [colCount]="2" horizontalAlignment="right" >
            <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="cancelButtonOptions"></dxi-item>

            <dxi-item itemType="simple" [colSpan]="2" horizontalAlignment="right">
              <div *dxTemplate style="text-align: right;">
                  <dx-button [text] text="{{'RESETPASSWORD.ZURUECKSETZEN' |translate }}" (onClick)="okReset($event)" [template]="'buttonTemplate'" horizontalAlignment="right" validationGroup="loginData">
                      <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                        <app-button [buttonData]="buttonData"></app-button>
                      </div>
                  </dx-button>
              </div>
           </dxi-item>
          </dxi-item>
          <dxi-item *ngIf="!this.isloggedIn" itemType="simple" [colSpan]="1" horizontalAlignment="right" style="padding-top: 10px;">
            <a  href="javascript: void(0);"  class="link_text" (click)="wechsel()">{{'RESETPASSWORD.BENUTZERNAME_VERGESSEN2' |translate }}</a>
          </dxi-item>
        </dx-form>
      </form>
      <br />
    </div>
    <div *ngIf="iexplorer" class="card-block">
      <p class="text-muted">Ihr Browser wird nicht unterstützt.<br><br>
        Bitte verwenden Sie <a href="https://www.google.com/chrome/">Google Chrome</a> oder <a
          href="https://www.mozilla.org/de/firefox/new/">Mozilla Firefox</a> als Browser.</p>
    </div>

  </div>

</div>
  
    </div>
  </div>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" [message]="this.translate.instant('LOGIN.DETAILS_LADEN')">
</dx-load-panel>