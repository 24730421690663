    <!-- sprachauswahl -->
    <dx-form class="langauge-selection" [style.padding]="getPadding()" *ngIf="sprachauswahl" >
        <dxi-item 
            location="after"
            locateInMenu="auto"
            menuItemTemplate="menuItem">
            <div *dxTemplate="let data of 'item'">
                <div class="applanguage">
                    <dx-button
                        #de
                        id="de"
                        style="background-color: #f7f7f7 !important; color: black; margin-right: 1px;"
                        text="DE"
                        (onClick)="this.onClickSprache('de')"
                        (onInitialized)="this.gdService.onInitializedDe($event)"
                    ></dx-button>
                    <dx-button
                        #it
                        id="it"
                        style="background-color: #f7f7f7 !important; color: black; margin-right: 1px;"
                        text="IT"
                        (onClick)="this.onClickSprache('it')"
                        (onInitialized)="this.gdService.onInitializedIt($event)"
                    ></dx-button>
                    <dx-button
                        #en
                        id="en"
                        style="background-color: #f7f7f7 !important; color: black; margin-right: 1px;"
                        text="EN"
                        (onClick)="this.onClickSprache('en')"
                        (onInitialized)="this.gdService.onInitializedEn($event)"
                    ></dx-button>
                    <!-- <dx-button
                        #ld
                        id="ld"
                        type="default"
                        style="background-color: #f7f7f7; color: black; margin-right: 1px;"
                        text="LD"
                        (onClick)="this.onClickSprache('ld')"
                        (onInitialized)="this.gdService.onInitializedLd($event)"
                    ></dx-button> -->
                </div>

                <!-- <dx-select-box  
                    #lngselection
                    [(items)]="this.sprachen" 
                    [(value)]="this.selectedLanguage" 
                    valueExpr="value" 
                    displayExpr="text" 
                    (onValueChanged)="onLanguageSelected($event)">
                </dx-select-box> -->

            </div>
        </dxi-item>
    </dx-form>

    <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" message="{{'DETAILSLADEN' | translate}}">
    </dx-load-panel>