import { enuAnrede } from '../globals/enumerationen';

export class Newsletter {
  public email: string;
  public anrede: enuAnrede;
  public titel: string;
  public vorname: string;
  public nachname: string;
  public interessen: string[];
  public datenschutz: boolean;

  constructor(
    // public service: Service,
  ) {
    this.datenschutz = false;
  }
}


