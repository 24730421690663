<div *ngIf="gdService.istKunde(enuKunde.AVS)" class="nectar-cta loaded" style=" position: relative; text-align: right;" data-color="accent-color" data-using-bg="false" data-style="arrow-animation" data-display="block" data-alignment="right" data-text-color="custom">
    <h5 style="color: #ffffff; background-color: var(--primary) !important; display: inline-block; margin-bottom: 0; padding-top: 10px; padding-right: 15px; padding-bottom: 10px; padding-left: 10px; ">
        <span class="link_wrap" style="vertical-align: bottom;">
            <span class="link_text">
                <span class="text"  >{{buttonData.text}}</span>
                <svg class="next-arrow" width="20px" height="25px" viewBox="0 0 50 80" xml:space="preserve">
                    <polyline stroke="#ffffff" stroke-width="9" fill="none" stroke-linecap="round" stroke-linejoin="round" points="0, 0 45, 40 0, 80"></polyline>
                </svg>
                <span class="line" style="background-color: #ffffff;"></span>
            </span>
        </span>
    </h5>
</div>
<div  *ngIf="gdService.istKunde(enuKunde.KFS) "  style="display: flex">
    <h5 class="tugalogin_button" style="color: #ffffff;  margin-bottom: 0; margin-top: 0; padding-top: 10px; padding-right: 15px; padding-bottom: 10px; padding-left: 10px; ">
        <span  >
            <span >
                <span >{{buttonData.text}}</span>
                <!-- <svg class="next-arrow" width="20px" height="25px" viewBox="0 0 50 80" xml:space="preserve">
                    <polyline stroke="#ffffff" stroke-width="9" fill="none" stroke-linecap="round" stroke-linejoin="round" points="0, 0 45, 40 0, 80"></polyline>
                </svg> -->
                <span style="background-color: #ffffff;"></span>
            </span>
        </span>
    </h5>
</div>
<div  *ngIf="gdService.istKunde(enuKunde.Standard) || gdService.istKunde(enuKunde.Template)" style="display: flex">
    <h5 class="tugalogin_button" style="color: #ffffff;  margin-bottom: 0; margin-top: 0;">
        <span  >
            <span >
                <span >{{buttonData.text}}</span>
                <!-- <svg class="next-arrow" width="20px" height="25px" viewBox="0 0 50 80" xml:space="preserve">
                    <polyline stroke="#ffffff" stroke-width="9" fill="none" stroke-linecap="round" stroke-linejoin="round" points="0, 0 45, 40 0, 80"></polyline>
                </svg> -->
                <span style="background-color: #ffffff;"></span>
            </span>
        </span>
    </h5>
</div>
