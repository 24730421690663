import { Injectable } from '@angular/core';
import { enuAnrede } from '../globals/enumerationen';
import { Newsletter } from './newsletter.model';


const anrede = new Array();
anrede.push({text: enuAnrede.GetName(enuAnrede.Frau), value: enuAnrede.Frau});
anrede.push({text: enuAnrede.GetName(enuAnrede.Herr), value: enuAnrede.Herr});

const interessen: string[] = [
  'Wandern & Bergsteigen', 'Hochtouren ', 'Sportklettern', 'Skitouren', 'Mountainbiken', 'Alpinklettern', 'Klettersteig',
  'Hallenklettern ', 'Bouldern', 'Schneeschuhwandern', 'Natur- & Umweltschutz', 'Gemeinschaftsabende', 'Kultur', 'Kinder- und Familien'
];


@Injectable()
export class NewsletterService {
  public newsletter: Newsletter;
  constructor(

  ) {
    this.newsletter = new Newsletter();
  }

    get item(): Newsletter {
      return this.newsletter as Newsletter;
    }

    createNewsletter(): Newsletter {
      return new Newsletter();
    }

    getNewsletter() {
        return this.newsletter;
    }

    getAnrede() {
        return anrede;
    }

    getInteressen() {
        return interessen;
    }

}
