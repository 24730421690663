import { Injectable } from '@angular/core';
import { enuRegistrierungAls, enuAnrede } from '../globals/enumerationen';
import { Register} from './register.model';
import { AuthService } from '../services/auth.service';
import { GlobaleDatenService } from '../services/globaledaten.serice';
import { TranslateService } from "@ngx-translate/core";


// anrede = EnumValues.getTextAndValuesTranslated(enuBescheid1, this.translate);


@Injectable()
export class RegisterService {
  public register: Register;
  public geburtsorte: any;
  public countries: any;
  anrede: any[];
  registrierungals: any[];

  constructor(
    public authservice: AuthService,
    public gdService: GlobaleDatenService,
    public translate: TranslateService
  ) {
    this.register = new Register(gdService.getNewsletter());

    this.anrede = new Array();
    this.anrede.push({text: enuAnrede.GetNameTranslated(enuAnrede.Frau, this.translate), value: enuAnrede.Frau});
    this.anrede.push({text: enuAnrede.GetNameTranslated(enuAnrede.Herr, this.translate), value: enuAnrede.Herr});

    this.registrierungals = new Array();
    this.registrierungals.push({text: enuRegistrierungAls.GetNameTranslated(enuRegistrierungAls.Mitglied, this.translate), value: enuRegistrierungAls.Mitglied});
    this.registrierungals.push({text: enuRegistrierungAls.GetNameTranslated(enuRegistrierungAls.Nichtmitglied, this.translate), value: enuRegistrierungAls.Nichtmitglied});
  }

    get item(): Register {
      return this.register as Register;
    }

    createRegister(): Register {
      return new Register(this.gdService.getNewsletter());
    }

    getRegister() {
      return this.register;
    }

    getAnrede() {
      this.anrede = new Array();
      this.anrede.push({text: enuAnrede.GetNameTranslated(enuAnrede.Frau, this.translate), value: enuAnrede.Frau});
      this.anrede.push({text: enuAnrede.GetNameTranslated(enuAnrede.Herr, this.translate), value: enuAnrede.Herr});
      return this.anrede;
    }

    getRegistrierungals() {
      this.registrierungals = new Array();
      this.registrierungals.push({text: enuRegistrierungAls.GetNameTranslated(enuRegistrierungAls.Mitglied, this.translate), value: enuRegistrierungAls.Mitglied});
      this.registrierungals.push({text: enuRegistrierungAls.GetNameTranslated(enuRegistrierungAls.Nichtmitglied, this.translate), value: enuRegistrierungAls.Nichtmitglied});
      return this.registrierungals;
    }

    // getGemeinden() {
    //   return this.geburtsorte;
    // }



}
